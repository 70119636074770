<h1 mat-dialog-title>Scenario #{{ data.row?.id }}</h1>

<mat-dialog-content class="mb-3">
  <div class="row mb-4">
    <div class="col-12 col-md-4 text-center">
      <strong class="pr-2">Cumulated likelihood:</strong><br>{{ data.row?.cumulatedChance.toExponential(3) }}
    </div> <!-- /.col -->
    <div class="col-12 col-md-4 text-center">
      <strong class="pr-2">Cumulated damage:</strong><br>{{ data.row?.cumulatedDamage.toExponential(3) }}
    </div> <!-- /.col -->
    <div class="col-12 col-md-4 text-center">
      <strong class="pr-2">Cumulated risk:</strong><br>{{ data.row?.cumulatedRisk.toExponential(3) }}
    </div> <!-- /.col -->
  </div> <!-- /.row -->
  <div class="table-responsive">
    <table class="mat-table">
      <thead class="rowgroup">
        <tr class="mat-header-row">
          <td class="mat-header-cell">Question</td>
          <td class="mat-header-cell">Answers n.</td>
          <td class="mat-header-cell">Answer</td>
          <td class="mat-header-cell">Effect</td>
          <td class="mat-header-cell">Damage</td>
          <td class="mat-header-cell">Progressive likelihood</td>
        </tr>
      </thead>
      <tbody class="rowgroup">
        <tr class="mat-row" *ngFor="let item of items">
          <td class="mat-cell">{{ item.question?.text }}</td>
          <td class="mat-cell">{{ item.question?.answersNumber }}</td>
          <td class="mat-cell">{{ item.text }}</td>
          <td class="mat-cell">{{ item.effect }}</td>
          <td class="mat-cell">{{ item?.damage?.toExponential(3) }}</td>
          <td class="mat-cell">{{ getExponentialChance(item.chance) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content> <!-- mat-dialog-content -->

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onClose()" class="close-btn">
    <mat-icon fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
    Close
  </button>
</mat-dialog-actions> <!-- /.mat-dialog-actions -->
