// Core packages
import { Component, OnInit, Input, Inject } from "@angular/core";

// Third party packages
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

// Custom packages

@Component({
  selector: "app-event-detail-dialog",
  templateUrl: "./event-detail-dialog.component.html",
  styleUrls: ["./event-detail-dialog.component.scss"],
})
export class EventDetailDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  items: any[] = [];

  constructor(public dialogRef: MatDialogRef<EventDetailDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    console.log("data", this.data);
    const questions = this.data.data.filter((obj) => obj.type === "question");
    console.log("questions", questions);
    for (const itemId of this.data.row.items) {
      const item = this.data.data.find((obj) => obj.id === itemId);
      if (item.type === "answer") {
        const parentNode = questions.find((obj) => obj.id === item.parentId);
        item.question = parentNode;
        this.items.push(item);
      }
    }
    console.log("items", this.items);
  }

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onClose(): void {
    this.dialogRef.close(false);
  }

  /**
   * Handle click on confirmation button
   *
   * @since 1.0.0
   */
  onConfirmation(): void {
    this.dialogRef.close(true);
  }

  getExponentialChance(chance: number | string | undefined): string {
    if (typeof chance === "undefined") {
      return "";
    }

    try {
      return parseFloat(chance as string).toExponential(3);
    } catch (error) {
      return chance.toString();
    }
  }
}
