<footer>
  <div class="container py-5">
    <div class="row">
      <div class="col-md-6">
        <p class="h3 mb-3">About us</p>
        <p class="m-0">
          We are a young yet skilled company based in Italy with a focus on the risk management and risk analysis. <br>
          Our team have more than 20 years of experience in risk handling and safety strategy.
        </p>
      </div> <!-- /.col -->

      <div class="col-md-4">
        <p class="h3 mb-3 mt-3 mt-md-0">Sales</p>
        <p class="m-0">
          Wanna try or buy a SimuRisk license? We are here to help, drop us a line at <a
            href="mailto:{{ owner.email }}">{{ owner.email }}</a>
        </p>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->

  <div class="socket">
    <div class="container">
      <div class="row">
        <div class="col-12">
          &copy; 2019-{{ year }} {{ owner.name }} | VAT N: {{ owner.vatNumber }} | Email: <a
            href="mailto:{{ owner.email }}">{{ owner.email }}</a> | <a routerLink="/privacy-policy">Privacy Policy</a> |
          Developed by <a href="https://sitisrl.it/web-agency-milano?utm_source=vlogik" target="_blank"
            title="Web Agency Milano">Web
            Agency Milano</a>
        </div> <!-- /.col-12 -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div>
</footer>
