// Core packages
import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';

// Third party packages
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

// Custom packages
import { User } from 'app/models/user.model';
import { UsersService } from 'app/services/users.service';
import { HelperService } from 'app/services/helper.service';
import { ListApiResponse } from 'app/interfaces/_base/list-api-response.interface';

/**
 * @see https://blog.angular-university.io/angular-material-data-table/
 *
 * NB
 * If you need to change this to make a new DataSource
 * just change the following:
 *
 * - Change The class name in the first line
 * - Change DataSource<T> (use your model type) in first line
 * - Change BehaviourSubject<T> in the second line
 * - Change the service inside the class constructor
 * - Change the Observable<T> used in the connect() line
 * - Change the service name inside loadItems()
 */
export class UsersDataSource implements DataSource<User> {
  private itemsSubject$ = new BehaviorSubject<User[]>([]);
  private totalCountSubject$ = new BehaviorSubject<number>(0);
  private loadingSubject$ = new BehaviorSubject<boolean>(true);

  public loading$ = this.loadingSubject$.asObservable();
  public totalCount$ = this.totalCountSubject$.asObservable();

  /**
   * Class constructor
   */
  constructor(private usersService: UsersService, private helperService: HelperService) {}

  /**
   * Connect data table to itemsSubject$
   *
   * @since 1.0.0
   *
   * @param collectionViewer collection viewer object
   * @returns Observable
   */
  connect(collectionViewer: CollectionViewer): Observable<User[]> {
    return this.itemsSubject$.asObservable();
  }

  /**
   * Disconnect data table from subjects
   *
   * @since 1.0.0
   *
   * @param collectionViewer collection viewer object
   * @returns undefined
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.itemsSubject$.complete();
    this.totalCountSubject$.complete();
    this.loadingSubject$.complete();
  }

  /**
   * Load items from back-end using service injected in the constructor
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering search string
   * @returns undefined
   */
  loadItems(start?: number, limit?: number, sort?: string, sortVersus?: string, search?: string): void {
    this.loadingSubject$.next(true); // Start loading
    this.usersService
      .getList(start, limit, sort, sortVersus, search)
      .pipe(
        catchError((err) => {
          // If errors, return empty array
          this.helperService.handleError(err);
          return of([]);
        }),
        finalize(() => this.loadingSubject$.next(false)) // Stop loading on finalize
      )
      .subscribe((result: ListApiResponse) => {
        this.itemsSubject$.next(result.items);
        this.totalCountSubject$.next(result.totalCount);
      });
  }
}
