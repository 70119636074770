// Core packages
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

// Third party packages
import moment from "moment";
import { of, Observable, EMPTY, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

// Custom packages
import { EnvironmentService } from "./environment.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private endpoint: string;
  private httpOptions: any;

  /**
   * Class constructor
   */
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    this.endpoint = this.environmentService.getApiUrl();
    this.httpOptions = {};
  }

  /**
   * Get current values of session stored in localStorage
   *
   * @since 1.0.0
   */
  getLocalStorageItems(): any {
    const sid = localStorage.getItem("s.id");
    const sexpiresAt = localStorage.getItem("s.expiresAt");
    return { sid, sexpiresAt };
  }

  /**
   * This method is a wrapper to call back-end APIs
   *
   * @since 1.0.0
   *
   * @param method HTTP Verbs to use. Can be 'GET', 'POST', 'PUT', 'DELETE'
   * @param path The endpoint relative path
   * @param [params] Optional api call params that needs to be attached to the call
   * @param [extraOptions] Optional extra option attached to the call
   * @param [autoRefresh] Optional autoRefreshToken value used to determinate if
   * refreshToken have to be used in case authToken is expired
   * @returns Observable<any>
   */
  apiCall(method: string, path: string, params?: any, extraOptions?: any, autoRefresh?: boolean): Observable<any> {
    const autoRefreshToken = typeof autoRefresh === "undefined" ? true : autoRefresh;
    if (typeof method === "undefined") {
      return of("Missing method");
    }

    const functionName = "apiCall" + method.toUpperCase();
    const functionToCall = this[functionName];
    const self = this;
    const thePath = path.endsWith("/") ? path.substring(-1) : path;

    // Get session data
    // If user is already logged in, just add his token
    // to the request header
    const sessData = this.getLocalStorageItems();
    const sid = sessData.sid; // localStorage.getItem('s.id');
    const sexpiresAt = sessData.sexpiresAt; // localStorage.getItem('s.expiresAt');

    // Init headers
    const acceptLang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    let headers = new HttpHeaders().set("Accept-Language", acceptLang);
    if (extraOptions && extraOptions.contentType) {
      if (extraOptions.contentType !== "auto") {
        headers = headers.append("Content-Type", extraOptions.contentType);
      } else {
        // headers = headers.set('Content-Type', '');
        headers = headers.delete("Content-Type");
      }
      delete extraOptions.contentType; // Remove extra option after this line
    } else {
      headers = headers.append("Content-Type", "application/json");
    }
    if (sid && moment.now() < parseInt(sexpiresAt, 10)) {
      headers = headers.append("Authorization", `Bearer ${sid}`);
    } else {
      // Session is expired: force user logout
      // DISABLED JUST FOR A TEST
      // localStorage.removeItem('s.id');
      // localStorage.removeItem('s.expiresAt');
      // localStorage.removeItem('s.refreshToken');
      // localStorage.removeItem('s.refreshTokenExpiresAt');
    }
    if (extraOptions && extraOptions["X-Refresh-Token"]) {
      headers = headers.append("X-Refresh-Token", `Bearer ${extraOptions["X-Refresh-Token"]}`);
    }

    // Set httpOptions
    this.httpOptions = {
      headers,
    };

    if (typeof functionToCall === "function") {
      return new Observable<any>((obs) => {
        const theParams = params || {};

        // Remove property with empty, undefined or null value
        // from params since they are not useful and - also -
        // since back-end could not accept undefined|null values
        // @see https://stackoverflow.com/questions/25421233/javascript-removing-undefined-fields-from-an-object
        // if (params !instanceof FormData) {
        //   console.log('PARSO');
        // }
        Object.keys(theParams).forEach(
          (key) => (typeof theParams[key] === "undefined" || theParams[key] === null) && delete theParams[key]
        );

        const obs1 = self[functionName](thePath, theParams, extraOptions);
        obs1.subscribe(
          (res) => {
            return obs.next(res);
          },
          (err) => {
            const refreshTokenExp = +localStorage.getItem("s.refreshTokenExpiresAt");
            // If error status is 401 means that we are not logged in
            // But before logging out, let's check if a not-expired refresh-token
            // is saved in our localStorage.
            // If so, let's try to re-authenticate with that refreshToken
            if (
              autoRefreshToken &&
              err.status === 401 &&
              localStorage.getItem("s.refreshToken") &&
              !(moment.unix(moment.now()) > moment.unix(refreshTokenExp))
            ) {
              // console.log('Use refresh token before making the requested call', err);

              const refreshToken = localStorage.getItem("s.refreshToken");
              let headers2 = new HttpHeaders().set("Accept-Language", localStorage.getItem("lang"));
              headers2 = headers2.append("Content-Type", "application/json");
              headers2 = headers2.append("X-Refresh-Token", `Bearer ${refreshToken}`);

              return this.http.post<any>(this.endpoint + "/auth/refreshtoken", {}, { headers: headers2 }).subscribe(
                async (result) => {
                  // console.log('REFRESH_TOKE_RESULT', result);
                  this.setSession(result);

                  return this.apiCall(method, path, params, extraOptions, false).subscribe(
                    (res2) => {
                      obs.next(res2);
                    },
                    (err2) => {
                      obs.error(err2);
                    },
                    () => {
                      return obs.complete();
                    }
                  );
                },
                () => {
                  obs.error(err);
                }
              );
            }
            // else if (err.status == 401) {
            //   console.log('Refresh token is expired or invalid. Logout');

            //   // Log user out
            //   localStorage.removeItem('s.id');
            //   localStorage.removeItem('s.expiresAt');
            //   localStorage.removeItem('s.refreshToken');
            //   localStorage.removeItem('s.refreshTokenExpiresAt');

            //   this.router.navigate(['auth', 'login']);
            // }
            return obs.error(err);
          },
          () => {
            return obs.complete();
          }
        );
      });
    } else {
      console.warn("return EMPTY");
      return EMPTY;
    }
  }

  /**
   * Perform a POST API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallPOST(path: string, params?: object, extraOptions?: object): Observable<any> {
    const options = this.httpOptions;
    if (typeof extraOptions !== "undefined") {
      Object.assign(options, extraOptions);
    }
    return this.http.post(this.endpoint + "/" + path, params, options).pipe(catchError(this.handleError));
  }

  /**
   * Perform a PUT API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallPUT(path: string, params?: object, extraOptions?: object): Observable<any> {
    const options = this.httpOptions;
    if (typeof extraOptions !== "undefined") {
      Object.assign(options, extraOptions);
    }
    return this.http.put(this.endpoint + "/" + path, params, options).pipe(catchError(this.handleError));
  }

  /**
   * Perform a GET API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallGET(path: string, params?: any, extraOptions?: object): Observable<any> {
    const options = this.httpOptions;
    if (typeof params !== "undefined") {
      options.params = params;
    }
    if (typeof extraOptions !== "undefined") {
      Object.assign(options, extraOptions);
    }
    return this.http.get(this.endpoint + "/" + path, options).pipe(catchError(this.handleError));
  }

  /**
   * Perform a DELETE API call with given path and
   * given params
   *
   * @since 1.0.0
   */
  private apiCallDELETE(path: string, params?: object, extraOptions?: object): Observable<any> {
    const options = this.httpOptions;
    if (typeof params !== "undefined") {
      options.params = params;
    }
    if (typeof extraOptions !== "undefined") {
      Object.assign(options, extraOptions);
    }
    return this.http.delete<any>(this.endpoint + "/" + path, options).pipe(catchError(this.handleError));
  }

  /**
   * Since we are not using a type checker,
   * the response should be extracted.
   *
   * @since 1.0.0
   */
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  /**
   * Handle http errors
   *
   * @since 1.0.0
   */
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 0) {
      return of(new Error("Unexpected error. Please reload the page and try again"));
    }
    return throwError(error);
  }

  /**
   * Set given login result data as current session
   *
   * @since 1.0.0
   *
   * @param data Session data coming from back-end
   * @returns boolean True if session was setted, false otherwhise
   */
  private setSession(data: any): boolean {
    const expiresAt = moment().add(data.expiresIn, "second");

    if (!this.checkLocalStorage()) {
      const title = "LocalStorage not available, please update your browser";
      const message = "Warning";
      this.toastrService.error(message, title);
      console.error("LOCAL STORAGE IS NOT AVAILABLE");
      return false;
    }

    localStorage.setItem("s.id", data.token);
    localStorage.setItem("s.expiresAt", JSON.stringify(expiresAt.valueOf()));
    if (data.refreshToken) {
      localStorage.setItem("s.refreshToken", data.refreshToken);
    }
    if (data.refreshExpiresIn) {
      const refreshExpiresAt = moment().add(data.refreshExpiresIn, "second");
      localStorage.setItem("s.refreshTokenExpiresAt", JSON.stringify(refreshExpiresAt.valueOf()));
    }

    return true;
  }

  /**
   * Check if client localStorage is available
   *
   * @since 1.0.0
   *
   * @returns boolean true True if available, false otherwhise
   */
  private checkLocalStorage(): boolean {
    const test = "test";
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
