// Core packages
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Validators } from "@angular/forms";

// Third party packages
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

// Custom packages
import { DynamicForm } from "app/models/dynamic-form.model";
import { Country } from "app/models/country.model";
import countriesData from "assets/json/countries.json";
import { UsersService } from "app/services/users.service";
import { HelperService } from "app/services/helper.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  formSettings: DynamicForm;
  stopFormLoading = false;
  resetForm = false;
  countries: Country[] = countriesData;

  constructor(
    private usersService: UsersService,
    private toastrService: ToastrService,
    private router: Router,
    private helperService: HelperService
  ) {}

  /**
   * Init component
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Init form
   *
   * @since 1.0.0
   */
  initForm(): void {
    const countriesOptions = this.countries.map((country) => {
      return {
        value: country.code,
        label: country.name,
      };
    });

    this.formSettings = {
      options: {
        formSubmitBtnOptions: {
          active: false,
          text: "Save",
          spinnerSize: 19,
          raised: true,
          stroked: true,
          flat: false,
          fab: false,
          buttonColor: "primary",
          spinnerColor: "primary",
          fullWidth: false,
          disabled: false,
          mode: "indeterminate",
        },
        title: "",
        description: "",
        formWrapperClasses: ["p-0"],
      },
      controls: [
        {
          type: "select",
          inputAttributes: {},
          name: "role",
          options: [
            {
              value: "admin",
              label: "Admin",
            },
            {
              value: "user",
              label: "User",
            },
          ],
          value: "user",
          placeholder: "Choose role",
          label: "Role",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "email",
          },
          name: "email",
          value: "",
          placeholder: "Insert email",
          label: "Email",
          validators: [Validators.required, Validators.email],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "password",
          value: "",
          placeholder: "Insert password",
          label: "Password",
          validators: [Validators.required, Validators.minLength(6), Validators.pattern(/\d/)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "firstName",
          value: "",
          placeholder: "Insert first name",
          label: "First name",
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "lastName",
          value: "",
          placeholder: "Insert last name",
          label: "Last name",
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "datepicker",
          inputAttributes: {
            startAt: new Date(),
          },
          name: "expiresAt",
          value: null,
          placeholder: "Expires at",
          label: "Expires at",
          validators: [Validators.required, Validators.min(Date.now())],
          order: 1,
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "active",
          options: [
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ],
          value: true,
          placeholder: "Active",
          label: "Active",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyName",
          value: "",
          placeholder: "Insert company name",
          label: "Company name",
          validators: [Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "companyLegalAddressCountryCodeTwo",
          options: countriesOptions,
          value: "IT",
          placeholder: "Company address country",
          label: "Company address country",
          validators: [Validators.minLength(2), Validators.maxLength(2)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyLegalAddressZip",
          value: "",
          placeholder: "Insert company address zip",
          label: "Company address zip",
          validators: [Validators.minLength(2), Validators.maxLength(10)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyLegalAddressCity",
          value: "",
          placeholder: "Insert company address city",
          label: "Company address city",
          validators: [Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyLegalAddressLine1",
          value: "",
          placeholder: "Insert company address street and number",
          label: "Company address street and number",
          validators: [Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyTaxCode",
          value: "",
          placeholder: "Insert company tax code",
          label: "Company tax code",
          validators: [Validators.minLength(10), Validators.maxLength(13)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyVatNumber",
          value: "",
          placeholder: "Insert company VAT number",
          label: "Company VAT number",
          validators: [Validators.minLength(10), Validators.maxLength(13)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "email",
          },
          name: "companyPec",
          value: "",
          placeholder: "Insert company PEC",
          label: "Company PEC",
          validators: [Validators.email],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companySdi",
          value: "",
          placeholder: "Insert company SDI code",
          label: "Company SDI code",
          validators: [Validators.minLength(5), Validators.minLength(10)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "maxDamage",
          options: [
            {
              value: 10000000000,
              label: "10e9",
            },
            {
              value: 1000000000,
              label: "10e8",
            },
            {
              value: 100000000,
              label: "10e7",
            },
            {
              value: 10000000,
              label: "10e6",
            },
            {
              value: 1000000,
              label: "10e5",
            },
            {
              value: 100000,
              label: "10e4",
            },
            {
              value: 10000,
              label: "10e3",
            },
            {
              value: 1000,
              label: "10e2",
            },
          ],
          value: null,
          placeholder: "Insert max damage for risk matrix",
          label: "Max damage",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "maxChance",
          options: [
            {
              value: 1e-3,
              label: "1e-3",
            },
            {
              value: 1e-4,
              label: "1e-4",
            },
            {
              value: 1e-5,
              label: "1e-5",
            },
            {
              value: 1e-6,
              label: "1e-6",
            },
            {
              value: 1e-7,
              label: "1e-7",
            },
            {
              value: 1e-8,
              label: "1e-8",
            },
            {
              value: 1e-9,
              label: "1e-9",
            },
            {
              value: 1e-10,
              label: "1e-10",
            },
          ],
          value: null,
          placeholder: "Insert max likelihood for risk matrix",
          label: "Max likelihood",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "chanceFilter",
          options: [
            {
              value: "",
              label: "None",
            },
            {
              value: 1e-3,
              label: "1e-3",
            },
            {
              value: 1e-4,
              label: "1e-4",
            },
            {
              value: 1e-5,
              label: "1e-5",
            },
            {
              value: 1e-6,
              label: "1e-6",
            },
            {
              value: 1e-7,
              label: "1e-7",
            },
            {
              value: 1e-8,
              label: "1e-8",
            },
            {
              value: 1e-9,
              label: "1e-9",
            },
            {
              value: 1e-10,
              label: "1e-10",
            },
            {
              value: 1e-11,
              label: "1e-11",
            },
            {
              value: 1e-12,
              label: "1e-12",
            },
          ],
          value: null,
          placeholder: "Insert chance filter for analysis",
          label: "Default min chance",
          validators: [],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
      ],
    };
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onSubmit(form: any): void {
    // console.log('onSubmit()', form);

    const data = form.value;

    this.subscriptions.push(
      this.usersService
        .create(data)
        .pipe(finalize(() => (this.stopFormLoading = !this.stopFormLoading)))
        .subscribe(
          (res) => {
            if (res.status) {
              this.toastrService.success(res.message);
              this.resetForm = !this.resetForm;

              setTimeout(() => {
                this.router.navigate(["admin", "users", "detail", res.user.id]);
              }, 3000);
            }
          },
          (err) => {
            // console.log('err', err);
            // console.log('form', form);

            // Choose one of the following error handling
            // method. The first one show a message right
            // under the form fields (if the form is properly
            // setted), the second one show toastr
            // notifications for each error
            this.helperService.handleFormError(form, err);
            // this.helperService.handleError(err);
          }
        )
    );
  }
}
