<main>
  <section class="section py-3">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5">
          <h1>Users list</h1>
          <p class="lead">
            Check out all registered users!
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
      <div class="row">
        <div class="col-12">
          <div class="table-toolbar">
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field>
                  <input matInput placeholder="Search" #input>
                </mat-form-field>
              </div> <!-- /.col-12.col-md-4 -->
            </div> <!-- /.row -->
          </div> <!-- /.table-toolbar -->

          <div class="spinner-container" *ngIf="dataSource.loading$ | async">
            <mat-spinner strokeWidth="3" [diameter]="50" mode="indeterminate"></mat-spinner>
          </div> <!-- /.spinner-container -->

          <div class="table-responsive">
            <table mat-table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="createdAt"
              matSortDirection="desc" matSortDisableClear class="mat-elevation-z0">
              <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
                <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                <td mat-cell *matCellDef="let element">
                  <span class="small badge badge-info mat-badge-small" *ngIf="element.role === 'admin'">
                    {{ element.role.toUpperCase() }}
                  </span>
                  <span class="small badge badge-success mat-badge-small" *ngIf="element.role !== 'admin'">
                    {{ element.role.toUpperCase() }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Created at</th>
                <td mat-cell *matCellDef="let element">{{ element.createdAt | date:'short' }}</td>
              </ng-container>
              <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Company</th>
                <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
              </ng-container>
              <ng-container matColumnDef="expiresAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires at</th>
                <td mat-cell *matCellDef="let element">{{ element.expiresAt | date:'short' }}</td>
              </ng-container>
              <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                <td mat-cell *matCellDef="let element">{{ element.active ? 'Yes' : 'No' }}</td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                  <a [routerLink]="'../detail/' + element.id" mat-raised-button color="primary" matTooltip="Open">
                    Open
                    <mat-icon fontSet="fal" fontIcon="fa-long-arrow-right"></mat-icon>
                  </a>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="dataSource.totalCount$ | async" [pageSize]="10" [pageSizeOptions]="[10, 15, 20]"
              showFirstLastButtons></mat-paginator>
          </div> <!-- /.table-responsive -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>
