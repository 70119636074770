<main>
    <section class="hero-section">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-12 col-md-6 offset-md-3 py-4 py-md-0 text-center">
            <h1>Page not found</h1>
            <span class="h1 title">Error 404</span>
            <h2 class="lead">Seems like we lost this page. Use our menu to surf our website</h2>
            <button mat-raised-button class="py-2 px-4 mt-5" routerLink="/">
              Back to Home
              <mat-icon fontSet="fal" fontIcon="fa-sign-in"></mat-icon>
            </button>
          </div> <!-- /.col -->
        </div> <!-- /.row -->
      </div> <!-- /.container -->
    </section> <!-- /.banner-section -->
  </main>
