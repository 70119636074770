<main>
  <section class="section">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-8">
          <h1>SimuRisk Analyzer</h1>
          <p class="lead">
            Pick a SIM file from your device to analyze it
          </p>
          <input #file type="file" id="fileInput" accept=".json,.sim" (change)="handleFileUpload(file.files)"
            class="visually-hidden">
          <mat-spinner-button (btnClick)="onLoadFromFile()" [options]="loadFromFileBtnOptions"
            title="Load data from file"></mat-spinner-button>
          <button mat-raised-button color="primary" class="ml-3 lh-30" *ngIf="riskChartData.length"
            (click)="onResetAll()">Reset</button>
          <button mat-raised-button color="primary" class="ml-3 lh-30" *ngIf="loggedUser && loggedUser.role === 'admin'"
            (click)="onDownloadAnalyzes()">Download analyzes</button>

          <mat-form-field class="ml-3">
            <mat-label>Minimum chance</mat-label>
            <mat-select [(ngModel)]="chanceFilter" placeholder="Select a value to filter"
              [disabled]="analyzes.length > 0 ? true : false">
              <mat-option [value]="">None</mat-option>
              <mat-option [value]="1e-2">1e-2</mat-option>
              <mat-option [value]="1e-3">1e-3</mat-option>
              <mat-option [value]="1e-4">1e-4</mat-option>
              <mat-option [value]="1e-5">1e-5</mat-option>
              <mat-option [value]="1e-6">1e-6</mat-option>
              <mat-option [value]="1e-7">1e-7</mat-option>
              <mat-option [value]="1e-8">1e-8</mat-option>
              <mat-option [value]="1e-9">1e-9</mat-option>
              <mat-option [value]="1e-10">1e-10</mat-option>
              <mat-option [value]="1e-11">1e-11</mat-option>
              <mat-option [value]="1e-12">1e-12</mat-option>
            </mat-select>
          </mat-form-field>

        </div> <!-- /.col -->
        <div class="col-12 col-md-4 d-none d-md-block">
          <img src="../../../../../assets/img/analyze-page-image.svg" alt="SimuRisk analyzer" class="img-fluid">
        </div> <!-- /.col -->
      </div> <!-- /.row -->
      <div class="row" [ngClass]="{'d-none': analyzes.length < 1}">
        <div class="col-12">
          <strong>Simulations' list</strong><br>
          <ul class="mb-0">
            <li *ngFor="let analysis of analyzes; let i = index;">
              #{{ i+1 }}: {{ analysis.title }} <span
                class="badge badge-warning py-0 mr-2 mt-2">{{analysis.scenarios.length}}/{{analysis.scenarios.length +
                analysis.filteredScenarios}} (
                Filtered {{analysis.filteredScenarios/(analysis.scenarios.length +
                analysis.filteredScenarios) | percent}})</span>
              <span class="badge badge-info py-0" *ngIf="i === 0">Main simulation</span>
            </li>
          </ul>
        </div>
      </div>
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{'d-none': !analysisComparisonChartActive}">
    <div class="container">
      <div class="row align-items-center" *ngIf="analysisComparisonChartActive">
        <div class="col-12 text-center">
          <h2 class="h3">Data comparison between simulations</h2>
          <p>
            Allow to immediately visualize the deltas between max likelihood, max damage and max risk.
          </p>
        </div> <!-- /.col -->
        <div class="col-12 col-md-4">
          <div class="chart-container" #analysisComparisonChartContainer>
            <canvas baseChart #analysisComparisonChart width="600" height="400" [datasets]="analysisComparisonChartData"
              [options]="analysisComparisonChartOptions" [legend]="analysisComparisonChartLegend"
              [chartType]="analysisComparisonChartType" [plugins]="analysisComparisonChartPlugins"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
        <div class="col-12 col-md-4">
          <div class="chart-container" #analysisComparisonChart2Container>
            <canvas baseChart #analysisComparisonChart2 width="600" height="400"
              [datasets]="analysisComparisonChart2Data" [options]="analysisComparisonChart2Options"
              [legend]="analysisComparisonChart2Legend" [chartType]="analysisComparisonChart2Type"
              [plugins]="analysisComparisonChart2Plugins"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
        <div class="col-12 col-md-4">
          <div class="chart-container" #analysisComparisonChart3Container>
            <canvas baseChart #analysisComparisonChart3 width="600" height="400"
              [datasets]="analysisComparisonChart3Data" [options]="analysisComparisonChart3Options"
              [legend]="analysisComparisonChart3Legend" [chartType]="analysisComparisonChart3Type"
              [plugins]="analysisComparisonChart3Plugins"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{'d-none': !lineChart2Active}">
    <div class="container">
      <div class="row align-items-center" *ngIf="lineChart2Active">
        <div class="col-12 col-md-4">
          <h2 class="h3">Likelihood per scenarios</h2>
          <p>
            Allow to immediately visualize the most likelihood scenarios to analyse with the Scenarios' list
          </p>
        </div> <!-- /.col -->
        <div class="col-12 col-md-8">
          <!--
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
            [labels]="lineChartLabels"
          -->
          <div class="chart-container" #lineChart2Container>
            <button mat-raised-button color="primary" (click)="onMakeSmaller('lineChart2')"
              class="mb-1 btn-sm make-smaller-button">
              Make smaller
              <mat-icon fontSet="fal" fontIcon="fa-minus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onMakeBigger('lineChart2')"
              class="mb-1 btn-sm make-bigger-button">
              Make bigger
              <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onSaveChart('lineChart2')" class="mb-1 ml-2 btn-sm">
              Save as image
              <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onResetZoom('lineChart2')" class="mb-1 ml-2 btn-sm">
              Reset zoom
              <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
            </button>

            <canvas baseChart #lineChart2 #lineChart2BCD width="600" height="400" [datasets]="lineChart2Data"
              [options]="lineChart2Options" [legend]="lineChart2Legend" [chartType]="lineChart2Type"
              [plugins]="lineChart2Plugins" (chartClick)="onChartClick($event, 'chancePerScenarios')"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{'d-none': !lineChart3Active}">
    <div class="container">
      <div class="row align-items-center" *ngIf="lineChart3Active">
        <div class="col-12 col-md-8">
          <!--
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
            [labels]="lineChartLabels"
          -->
          <div class="chart-container" #lineChart3Container>
            <button mat-raised-button color="primary" (click)="onMakeSmaller('lineChart3')"
              class="mb-1 btn-sm make-smaller-button">
              Make smaller
              <mat-icon fontSet="fal" fontIcon="fa-minus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onMakeBigger('lineChart3')"
              class="mb-1 btn-sm make-bigger-button">
              Make bigger
              <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onSaveChart('lineChart3')" class="mb-1 ml-2 btn-sm">
              Save as image
              <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onResetZoom('lineChart3')" class="mb-1 ml-2 btn-sm">
              Reset zoom
              <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
            </button>

            <canvas baseChart #lineChart3 #lineChart3BCD width="600" height="400" [datasets]="lineChart3Data"
              [options]="lineChart3Options" [legend]="lineChart3Legend" [chartType]="lineChart3Type"
              [plugins]="lineChart3Plugins" (chartClick)="onChartClick($event, 'damagePerScenarios')"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
        <div class="col-12 col-md-4">
          <h2 class="h3">Damage per scenarios</h2>
          <p>
            Scenarios' damage distribution. <br>
            Allow to immediately visualize the most critical scenarios to analyse with the Scenarios' list
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{'d-none': !lineChart4Active}">
    <div class="container">
      <div class="row align-items-center" *ngIf="lineChart4Active">
        <div class="col-12 col-md-4">
          <h2 class="h3">Risk per scenarios</h2>
          <p>
            Allow to immediately visualize the most risks scenarios to analyse with the Scenarios' list
          </p>
        </div> <!-- /.col -->
        <div class="col-12 col-md-8">
          <!--
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
            [labels]="lineChartLabels"
          -->
          <div class="chart-container" #lineChart4Container>
            <button mat-raised-button color="primary" (click)="onMakeSmaller('lineChart4')"
              class="mb-1 btn-sm make-smaller-button">
              Make smaller
              <mat-icon fontSet="fal" fontIcon="fa-minus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onMakeBigger('lineChart4')"
              class="mb-1 btn-sm make-bigger-button">
              Make bigger
              <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onSaveChart('lineChart4')" class="mb-1 ml-2 btn-sm">
              Save as image
              <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onResetZoom('lineChart4')" class="mb-1 ml-2 btn-sm">
              Reset zoom
              <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
            </button>

            <canvas baseChart #lineChart4 #lineChart4BCD width="600" height="400" [datasets]="lineChart4Data"
              [options]="lineChart4Options" [legend]="lineChart4Legend" [chartType]="lineChart4Type"
              [plugins]="lineChart4Plugins" (chartClick)="onChartClick($event, 'riskPerScenarios')"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{'d-none': !lineChart5Active}">
    <div class="container">
      <div class="row align-items-center" *ngIf="lineChart5Active">
        <div class="col-12 col-md-8">
          <div class="chart-container" #lineChart5Container>
            <button mat-raised-button color="primary" (click)="onMakeSmaller('lineChart5')"
              class="mb-1 btn-sm make-smaller-button">
              Make smaller
              <mat-icon fontSet="fal" fontIcon="fa-minus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onMakeBigger('lineChart5')"
              class="mb-1 btn-sm make-bigger-button">
              Make bigger
              <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onSaveChart('lineChart5')" class="mb-1 ml-2 btn-sm">
              Save as image
              <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
            </button>
            <button mat-raised-button color="primary" (click)="onResetZoom('lineChart5')" class="mb-1 ml-2 btn-sm">
              Reset zoom
              <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
            </button>

            <canvas baseChart #lineChart5 #lineChart5BCD width="600" height="400" [datasets]="lineChart5Data"
              [options]="lineChart5Options" [legend]="lineChart5Legend" [chartType]="lineChart5Type"
              [plugins]="lineChart5Plugins" (chartClick)="onChartClick($event, 'riskDistribution')"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
        <div class="col-12 col-md-4">
          <h2 class="h3">Cumulated risk distribution</h2>
          <p>
            Cumulated risk Distribution chart is the core computational tool of quantitative risk management, risk
            evaluation and
            risk solutions' comparison
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{'d-none': !riskChartActive}">
    <div class="container">
      <div class="row align-items-center" *ngIf="riskChartActive">
        <div class="col-12 col-md-4">
          <h2 class="h3">Risk matrix</h2>
          <p>
            Scenarios' distribution level of risk by considering the probability against the consequence severity.
            This increase visibility of risks and assist to justify the management decision.
            It is useful for change management.
          </p>
        </div> <!-- /.col -->
        <div class="col-12 col-md-8">
          <!--
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
            [labels]="lineChartLabels"
          -->
          <div class="chart-container" #riskChartContainer>
            <!-- <button mat-raised-button color="primary" (click)="onMakeSmaller('risk')"
              class="mb-1 btn-sm make-smaller-button">
              Make smaller
              <mat-icon fontSet="fal" fontIcon="fa-minus"></mat-icon>
            </button> -->
            <!-- <button mat-raised-button color="primary" (click)="onMakeBigger('risk')"
              class="mb-1 btn-sm make-bigger-button">
              Make bigger
              <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
            </button> -->
            <!-- <button mat-raised-button color="primary" (click)="onSaveChart('risk')" class="mb-1 ml-2 btn-sm">
              Save as image
              <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
            </button> -->

            <canvas baseChart #riskChart #riskChartBCD width="600" height="600" [datasets]="riskChartData"
              [options]="riskChartOptions" [legend]="riskChartLegend" [chartType]="riskChartType"
              [plugins]="riskChartPlugins" class="risk-matrix-canvas"
              (chartClick)="onChartClick($event, 'riskMatrix')"></canvas>
          </div> <!-- /.chart-container -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" id="scenariosList" [ngClass]="{'d-none': !analyzes.length}">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-4">
          <h2 class="h2 title">Scenarios' list</h2>
          <p class="lead">
            <strong>Pro tip:</strong> Click on a row to expand it and check the events list!
          </p>
        </div> <!-- /.col -->
        <div class="col-12">
          <div class="table-toolbar mb-3">
            <div class="row">
              <div class="col-12">
                <mat-form-field>
                  <input type="number" matInput placeholder="From (ID)" #fromInput>
                </mat-form-field>
                <mat-form-field class="ml-3">
                  <input type="number" matInput placeholder="To (ID)" #toInput>
                </mat-form-field>
                <mat-form-field class="ml-3">
                  <mat-select #effectSelect value="" (selectionChange)="onEffectSelectChange($event)">
                    <mat-option value="" selected>-- Effects --</mat-option>
                    <mat-option value="P">All positive</mat-option>
                    <mat-option value="N">All negative</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="ml-3">
                  <input type="string" matInput placeholder="Search..." #searchInput>
                </mat-form-field>
              </div> <!-- /.col -->
            </div> <!-- /.row -->
          </div> <!-- /.table-toolbar -->
          <div class="table-responsive mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort #sort1="matSort" matSortActive="id"
              matSortDirection="asc" matSortDisableClear>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                <td mat-cell *matCellDef="let element">{{element.id}} </td>
              </ng-container>

              <ng-container matColumnDef="items.length">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Events</th>
                <td mat-cell *matCellDef="let element">{{ round(element.items.length / 2) }}</td>
              </ng-container>

              <ng-container matColumnDef="cumulatedChance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cumulated likelihood</th>
                <td mat-cell *matCellDef="let element">{{element?.cumulatedChance?.toExponential(3)}}</td>
              </ng-container>

              <ng-container matColumnDef="cumulatedDamage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cumulated damage</th>
                <td mat-cell *matCellDef="let element">{{element?.cumulatedDamage?.toExponential(3) }}</td>
              </ng-container>

              <ng-container matColumnDef="cumulatedRisk">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cumulated risk</th>
                <td mat-cell *matCellDef="let element">{{element?.cumulatedRisk?.toExponential(3) }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                (click)="onRowOpen(null, row.id)">
              </tr>
            </table>

            <mat-paginator [length]="tableDataLength" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons>
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section" id="criticEventsList" [ngClass]="{'d-none': !analyzes.length}">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-4">
          <h2 class="h2 title">Critic events' list</h2>
        </div> <!-- /.col -->
        <div class="col-12">
          <div class="table-responsive mat-elevation-z8">
            <table class="mat-table">
              <thead>
                <tr class="mat-header-row">
                  <th class="mat-header-cell">#</th>
                  <th class="mat-header-cell">Question</th>
                  <th class="mat-header-cell">Answer</th>
                  <th class="mat-header-cell">Effect</th>
                  <th class="mat-header-cell">Risk</th>
                  <th class="mat-header-cell">Impact %</th>
                  <th class="mat-header-cell">Cumulated impact %</th>
                </tr>
              </thead>
              <tbody *ngIf="analyzes.length">
                <tr *ngFor="let ev of analyzes[0].criticEvents; let i = index;" class="mat-row">
                  <td class="mat-cell">{{ (i + 1) }}</td>
                  <td class="mat-cell">{{ ev?.questionText }}</td>
                  <td class="mat-cell">{{ ev?.text }}</td>
                  <td class="mat-cell">{{ ev?.effect }}</td>
                  <td class="mat-cell">{{ ev?.risk?.toExponential(3) }}</td>
                  <td class="mat-cell">{{ ev?.impact?.toExponential(3) }}</td>
                  <td class="mat-cell">{{ ev?.cumulatedImpact?.toExponential(3) }}</td>
                </tr>
                <tr class="mat-header-row" *ngIf="analyzes[0].criticEvents.length < 1">
                  <td colspan="7" class="mat-cell text-center">
                    No critical event found
                  </td>
                </tr>
              </tbody>
            </table>
          </div> <!-- /.table-responsive -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>


  <section class="section" id="summariesList" [ngClass]="{'d-none': !analyzes.length}">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center mb-4">
          <h2 class="h2 title">Summaries' list</h2>
        </div> <!-- /.col -->
        <div class="col-12">
          <div class="table-responsive mat-elevation-z8">
            <table class="mat-table">
              <thead>
                <tr class="mat-header-row">
                  <th class="mat-header-cell">#</th>
                  <th class="mat-header-cell">End leaf</th>
                  <th class="mat-header-cell">Max Likelyhood</th>
                  <th class="mat-header-cell">Max Damage</th>
                  <th class="mat-header-cell">Max Risk</th>
                  <th class="mat-header-cell">Cumulated</th>
                </tr>
              </thead>
              <tbody *ngIf="analyzes.length">
                <tr *ngFor="let summary of analyzes[0].leavesSummaries; let i = index;" class="mat-row">
                  <td class="mat-cell">{{ (i + 1) }}</td>
                  <td class="mat-cell" style="max-width: 220px">{{ summary?.leaf?.text }}</td>
                  <td class="mat-cell">
                    <span *ngIf="summary?.scenarioMaxChance?.id">
                      <strong>Scenario:</strong>
                      {{ summary?.scenarioMaxChance?.id }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxChance?.cumulatedChance">
                      <strong class="red">Likelihood:</strong>
                      {{ summary?.scenarioMaxChance?.cumulatedChance?.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxChance?.cumulatedDamage">
                      <strong>Damage:</strong>
                      {{ summary?.scenarioMaxChance?.cumulatedDamage?.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxChance?.cumulatedRisk">
                      <strong>Risk:</strong>
                      {{ summary?.scenarioMaxChance?.cumulatedRisk?.toExponential(3) }}
                    </span>
                  </td>
                  <td class="mat-cell">
                    <span *ngIf="summary?.scenarioMaxDamage?.id">
                      <strong>Scenario:</strong>
                      {{ summary?.scenarioMaxDamage?.id }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxDamage?.cumulatedChance">
                      <strong>Likelihood:</strong>
                      {{ summary?.scenarioMaxDamage?.cumulatedChance?.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxDamage?.cumulatedDamage">
                      <strong class="red">Damage:</strong>
                      {{ summary?.scenarioMaxDamage?.cumulatedDamage?.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxDamage?.cumulatedRisk">
                      <strong>Risk:</strong>
                      {{ summary?.scenarioMaxDamage?.cumulatedRisk?.toExponential(3) }}
                    </span>
                  </td>
                  <td class="mat-cell">
                    <span *ngIf="summary?.scenarioMaxRisk?.id">
                      <strong>Scenario:</strong>
                      {{ summary?.scenarioMaxRisk?.id }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxRisk?.cumulatedChance">
                      <strong>Likelihood:</strong>
                      {{ summary?.scenarioMaxRisk?.cumulatedChance?.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxRisk?.cumulatedDamage">
                      <strong>Damage:</strong>
                      {{ summary?.scenarioMaxRisk?.cumulatedDamage?.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.scenarioMaxRisk?.cumulatedRisk">
                      <strong class="red">Risk:</strong>
                      {{ summary?.scenarioMaxRisk?.cumulatedRisk?.toExponential(3) }}
                    </span>
                  </td>
                  <td class="mat-cell">
                    <span></span><br>
                    <span *ngIf="summary?.cumulatedChance">
                      <strong>Likelihood:</strong>
                      {{ summary?.cumulatedChance.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.avarageDamage">
                      <strong>Damage:</strong>
                      {{ summary?.avarageDamage.toExponential(3) }}
                    </span><br>
                    <span *ngIf="summary?.cumulatedChance && summary?.avarageDamage">
                      <strong>Risk:</strong>
                      {{ (summary.cumulatedChance * summary.cumulatedChance).toExponential(3) }}
                    </span>
                  </td>
                </tr>
                <tr class="mat-header-row" *ngIf="analyzes[0].leavesSummaries.length < 1">
                  <td colspan="7" class="mat-cell text-center">
                    No summaries to display
                  </td>
                </tr>
              </tbody>
            </table>
          </div> <!-- /.table-responsive -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>

<div class="data-is-loading" *ngIf="dataIsLoading">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <mat-spinner strokeWidth="3" [diameter]="90" mode="indeterminate" class="m-auto"></mat-spinner>
        <p class="lead mt-4">
          We are calculating all scenarios... <br>
          <small class="text-danger fs1">The operation may take serveral minutes depending on the scenarios
            amount</small> <br>
          <small>Found scenarios: {{ foundScenarios | number }}</small>
        </p>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->
</div> <!-- /.data-is-loading -->
