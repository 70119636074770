// Core packages
import { Component, OnInit, Input, Inject } from '@angular/core';

// Third party packages
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Custom packages

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {}

  /**
   * Handle click on Denial button
   *
   * @since 1.0.0
   */
  onDenial(): void {
    this.dialogRef.close(false);
  }

  /**
   * Handle click on confirmation button
   *
   * @since 1.0.0
   */
  onConfirmation(): void {
    this.dialogRef.close(true);
  }
}
