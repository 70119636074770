/* eslint-disable import/prefer-default-export */
// Core packages
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  ViewContainerRef,
  Inject,
  OnDestroy,
  ComponentRef,
} from "@angular/core";

// Third party packages
import panzoom, { PanZoom } from "panzoom";
import { ToastrService } from "ngx-toastr";
import { Subscription, Observable, Subscriber } from "rxjs";
import { MatProgressButtonOptions } from "mat-progress-buttons";
import moment from "moment";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

// Custom packages
import { ConfirmDialogService } from "app/services/confirm-dialog.service";
import { NodeHelperService } from "app/services/node-helper.service";
import { ThemeService } from "app/services/theme.service";
import { AnswerNodeInterface } from "app/interfaces/answer-node-interface";
import { QuestionNodeInterface } from "app/interfaces/question-node-interface";
import { User } from "app/models/user.model";
import { AuthService } from "app/services/auth.service";
import { ExportFileInterface } from "app/interfaces/export-file-interface";
import { HelperService } from "app/services/helper.service";
import JSZip from "jszip";

@Component({
  selector: "app-app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  loggedUser: User;
  chartVersion = "1.1.0";
  chartInstance: PanZoom;
  @ViewChild("chartContainer") chartContainer: ElementRef<HTMLElement>;
  @ViewChild("chart") chart: ElementRef<HTMLElement>;
  @ViewChild("chartInner", { read: ViewContainerRef }) chartInner: ViewContainerRef;
  @ViewChild("loadFromFileButton") loadFromFileButton: ElementRef;
  @ViewChild("file") fileInput: ElementRef;
  scale = 1; // Initial scale
  scaleFactor = 1.25; // Scale factor (zoom factor)
  nodesCounter = 0;
  nodes: {
    node: any;
    ref: ComponentRef<QuestionNodeInterface | AnswerNodeInterface>;
  }[] = [];
  tempNodes: any[] = [];

  loadFromFileBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: "Load from File",
    spinnerSize: 19,
    raised: true,
    stroked: false,
    flat: false,
    fab: false,
    // buttonColor: '',
    spinnerColor: "primary",
    fullWidth: false,
    disabled: false,
    mode: "indeterminate",
  };
  chartTitle = "";
  chartDescription = "";
  chartCategory = "";
  chartCategoriesOptions: string[] = [];
  hideChart = true;
  chartStartButtonIsHidden = false;
  autoSaveTime = 5 * 60 * 1000; // first number rapresents minutes
  eventsList: any[] = [];
  linkModeisActive = false;
  autoSaveInterval: any;

  constructor(
    @Inject(NodeHelperService) private nodeHelperService,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    private themeService: ThemeService,
    private authService: AuthService,
    private helperService: HelperService
  ) {}

  /**
   * Handle component init
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Subscribe to nodesCounter$
    const nodesSubscription = this.nodeHelperService.nodes$.subscribe((val: any) => {
      this.nodes = val;
    });
    const tempNodeSubscription = this.nodeHelperService.tempNodes$.subscribe((val: any) => {
      this.tempNodes = val;
    });
    this.subscriptions.push(nodesSubscription);
    this.subscriptions.push(tempNodeSubscription);

    // Set page title
    this.themeService.setPageTitle("SimuRisk");

    this.subscriptions.push(
      this.authService.loggedUser$.subscribe((user: any) => {
        if (user) {
          this.loggedUser = user;
          this.chartCategoriesOptions = user.categories;
        }
      })
    );
  }

  /**
   * Handle component init after render
   *
   * @since 1.0.0
   */
  ngAfterViewInit() {
    this.nodeHelperService.setRootViewContainerRef(this.chartInner);
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());

    // Unsubscribe nodeHelperService from all subscriptions
    this.nodeHelperService.destroyAllSubscriptions();

    // Destroy listeners over chart panzoom
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }

    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
  }

  /**
   * Check if user can deactivate this route
   *
   * @since 1.0.0
   */
  CanDeactivate(): Observable<boolean> | boolean {
    if (this.tempNodes.length > 0) {
      const title = "Warning!";
      const message = "Are you sure? Proceeding you will lost all chart data";
      return new Observable((obs: Subscriber<boolean>) => {
        const confirmDialogSubscription = this.confirmDialogService.confirm(title, message).subscribe((res: any) => {
          if (res) {
            return obs.next(true);
          }
          return obs.next(false);
        });
        this.subscriptions.push(confirmDialogSubscription);
      });
    }
    return true;
  }

  /**
   * Init chart and make it visible
   *
   * @since 1.0.0
   */
  onChartStart(): void {
    this.chartStartButtonIsHidden = true;
    this.hideChart = false;
    this.onChartResetData();
    this.initChart();
    setTimeout(() => {
      window.scroll({
        top: 600,
        behavior: "smooth",
      });
    }, 300);
  }

  /**
   * Handle click on "Add new question" chart button
   *
   * @since 1.0.0
   */
  onChartButtonAddQuestionNode(): void {
    this.nodeHelperService.createQuestionNode();
    this.onChartResetZoom();
  }

  /**
   * Init chart
   *
   * @since 1.0.0
   */
  initChart(): void {
    // Init panzoom function in chart
    if (!this.chart) {
      const title = "Error!";
      const message = "Unable to load the chart. Please, reload the page";
      this.toastrService.error(message, title);
      return;
    }
    this.chartInstance = panzoom(this.chart.nativeElement, {
      zoomDoubleClickSpeed: 1,
      beforeWheel: (e: any) => {
        // allow wheel-zoom only if altKey is down. Otherwise - ignore
        const shouldIgnore = !e.altKey;
        return shouldIgnore;
      },
      filterKey: () => true,
    });

    // this.chartInstance.on("pan", (e: unknown) => {
    //   console.log("e", e);
    // });

    // @todo dovrebbe servire per impedire di spostarsi a sx (con una x negativa)
    // @see task #21 on gitHub
    // this.chartInstance.on('pan', (e: any) => {
    //   const deltaCoordinates = e.getTransform();
    //   if (deltaCoordinates.x > 0) {
    //     console.log('NO', e);
    //     return false;
    //   }
    // })

    // Subscribe to linkToActiveStatus
    this.subscriptions.push(
      this.nodeHelperService.linkModeIsActive$.subscribe((res: any) => {
        // console.log('res', res);
        if (res.from && !res.to) {
          this.linkModeisActive = true;
        } else {
          this.linkModeisActive = false;
        }
      })
    );

    setTimeout(() => {
      this.checkForPreviousAutoSave();
      this.initAutoSave();
    }, 1500);
  }

  /**
   * Reset chart zoom and pan
   *
   * @since 1.0.0
   */
  onChartResetZoom(): void {
    this.scale = 1;
    this.chartInstance.zoomAbs(0, 0, 1);
    this.chartInstance.moveTo(0, 0);
  }

  /**
   * Apply the scaleFactor to current zoom level (zoom more)
   *
   * @since 1.0.0
   */
  onChartZoomMore(): void {
    // Prevent zoom while pan/zoom is paused
    if (this.chartInstance.isPaused()) {
      const title = "Warning!";
      const message = "Unable to zoom while link mode is active";
      this.toastrService.warning(message, title);
      return;
    }

    const transform = this.chartInstance.getTransform();
    const currentScale = transform.scale;
    const newScale = currentScale * this.scaleFactor;

    if (newScale > 3.5) {
      return;
    }
    this.scale = newScale;

    const deltaX = transform.x;
    const deltaY = transform.y;
    const offsetX = deltaX + this.scale;
    const offsetY = deltaY + this.scale;

    // console.log('offsetX', offsetX);
    // console.log('offsetY', offsetY);
    this.chartInstance.smoothZoomAbs(offsetX, offsetY, this.scale);
  }

  /**
   * Divide current zoom level of the scaleFactor (zoom less)
   *
   * @since 1.0.0
   */
  onChartZoomLess(): void {
    // Prevent unzoom while pan/zoom is paused
    if (this.chartInstance.isPaused()) {
      const title = "Warning!";
      const message = "Unable to unzoom while link mode is active";
      this.toastrService.warning(message, title);
      return;
    }

    const transform = this.chartInstance.getTransform();
    const currentScale = transform.scale;
    const newScale = currentScale / this.scaleFactor;

    if (newScale < 0.05) {
      return;
    }

    this.scale = newScale;

    const deltaX = transform.x;
    const deltaY = transform.y;
    const offsetX = deltaX + this.scale;
    const offsetY = deltaY + this.scale;

    this.chartInstance.smoothZoomAbs(offsetX, offsetY, this.scale);
  }

  /**
   * Check if thare is an auto-saved chart-data in the localStorage data
   * if so, just ask user if he want to restore it
   *
   * @since 1.0.0
   */
  checkForPreviousAutoSave(): void {
    if (this.hideChart) {
      return;
    }
    const chartBackupTime = localStorage.getItem("chartBackupTime");

    if (chartBackupTime) {
      const chartBackup = localStorage.getItem("chartBackup");
      const date = moment.unix(parseInt(chartBackupTime, 10) / 1000).format("MMMM Do YYYY, h:mm:ss a");
      const title = "There is AutoSaved data";
      const message = `You have an AutoSaved version of the data of ${date}. Do you want to restore it?`;

      this.subscriptions.push(
        this.confirmDialogService.confirm(title, message).subscribe((res: any) => {
          if (res) {
            this.onChartResetData();
            this.loadFromFileBtnOptions.active = true;
            this.subscriptions.push(
              this.importData(JSON.parse(chartBackup)).subscribe(() => {
                const backup = JSON.parse(chartBackup);
                const backupMaxIndexe = Math.max.apply(
                  Math,
                  backup.data.map((obj) => obj.id)
                );
                this.nodeHelperService.setNodeIndex(backupMaxIndexe + 1000);

                this.loadFromFileBtnOptions.active = false;
              })
            );
          }
        })
      );
    }
  }

  /**
   * Run a timer that saves chart every X seconds
   * in localStorage
   *
   * @since 1.0.0
   */
  initAutoSave(): void {
    const title = "AutoSave";
    const message = "Chart data has been automatically saved for safety";
    this.autoSaveInterval = setInterval(() => {
      if (this.tempNodes.length > 0) {
        const jsData: ExportFileInterface = {
          title: this.chartTitle,
          description: this.chartDescription,
          category: this.chartCategory,
          author: this.loggedUser ? this.loggedUser.id : undefined,
          createdAt: new Date(),
          data: this.nodes.map((obj: any) => obj.node),
        };
        const jsonContent = JSON.stringify(jsData);
        const now = new Date().valueOf();
        localStorage.setItem("chartBackup", jsonContent);
        localStorage.setItem("chartBackupTime", now.toString());
        this.toastrService.info(message, title);
      }
    }, this.autoSaveTime);
  }

  /**
   * Build a json file from the nodes
   * and force the file download
   *
   * @since 1.0.0
   */
  onDownload(): void {
    // Prevent empty export
    if (!this.nodes.length) {
      const title = "Warning!";
      const text = "Unable to export the chart: no data is present";
      this.toastrService.warning(text, title);
      return;
    }

    // Check if process category, process title e process description are not empty
    if (this.chartTitle.trim() === "" || this.chartDescription.trim() === "") {
      const title = "Warning!";
      const fields = [];
      if (this.chartTitle.trim() === "") {
        fields.push("chart title");
      }
      if (this.chartDescription.trim() === "") {
        fields.push("chart description");
      }
      const message = `Please fill following fields before saving: ${fields.join(", ")}`;
      this.toastrService.warning(message, title);
      return;
    }

    // Convert the chartTree to a JSON and download it
    // console.log("this.nodes", this.nodes);
    const jsData: ExportFileInterface = {
      title: this.chartTitle,
      description: this.chartDescription,
      category: this.chartCategory,
      author: this.loggedUser ? this.loggedUser.id : undefined,
      createdAt: new Date(),
      data: this.nodes.map(
        (obj: { node: any; ref: ComponentRef<QuestionNodeInterface | AnswerNodeInterface> }) => obj.node
      ),
    };
    const jsonContent = JSON.stringify(jsData);

    // BEGIN - Get the appropriate file name
    const today = moment(new Date()).format("YYYY-MM-DD");
    // const today = new Date();
    // const day = today.getDay() > 9 ? today.getDay() : `0${today.getDay()}`;
    // const month = today.getMonth() > 9 ? today.getMonth() : `0${today.getMonth()}`;
    let fileName = `${today} - SimuRisk`;
    if (this.chartCategory && this.chartCategory !== "") {
      fileName += ` - ${this.chartCategory.replace(" ", "-").toLowerCase()}`;
    }
    if (this.chartTitle && this.chartTitle.trim() !== "") {
      fileName += ` - ${this.chartTitle.replace(" ", "-").toLowerCase()}`;
    }
    fileName += ".SIM";
    // END - Get the appropriate file name

    // Start - ZIP file content
    const zip: JSZip = new JSZip();
    zip.file(fileName, jsonContent);
    zip
      .generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9,
        },
        mimeType: "application/sim",
      })
      .then((content) => {
        saveAs(content, fileName);
      });
    // End -  ZIP file content
  }

  /**
   * Handle click on "Load from File" button
   * and:
   * 1) Reset chart
   * 2) Read content from given file
   * 3) Build the DOM for read content
   */
  onLoadFromFile(): any {
    if (!this.helperService.isGoogleChrome()) {
      this.triggerFileUpload();
      return;
    }

    const title = "Warning!";
    const message = `Are you sure? Proceeding you will insert all elements inside
    the current chart <strong>and you will delete all current elements</strong>.
    The operation could take some time depending on the amount of
    the elements you are importing.`;

    this.subscriptions.push(
      this.confirmDialogService.confirm(title, message).subscribe((res: any) => {
        if (res) {
          this.triggerFileUpload();
        }
      })
    );
  }

  /**
   * Handle a file upload loading all data inside the chart
   *
   * @since 1.0.0
   */
  async handleFileUpload(fileList: FileList): Promise<void> {
    if (fileList.length !== 1) {
      return;
    }

    try {
      const file = fileList[0];
      console.log("file", file);
      const { name } = file;
      const fileExt = name.split(".").pop();

      // It's a JSON file
      // Let's use v1.0.0 file reader
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = async () => {
        const content = fileReader.result.toString();
        this.onChartResetData();
        this.loadFromFileBtnOptions.active = true;
        let parsedContent: ExportFileInterface;
        if (fileExt.toLowerCase() === "sim") {
          // It's a 'sim' file, let's de-zip it and then read it
          const zip = new JSZip();
          const value = await zip.loadAsync(file);
          const decodedFile = value.files[Object.keys(value.files)[0]];
          const content = await decodedFile.async("string");
          parsedContent = JSON.parse(content);
        } else {
          // It's a old JSON file
          parsedContent = JSON.parse(content);
        }
        console.log("parsedContent", parsedContent);
        this.subscriptions.push(
          this.importData(parsedContent).subscribe(() => {
            this.fileInput.nativeElement.value = null;
            this.loadFromFileBtnOptions.active = false;
          })
        );
      };

      fileReader.onerror = (error: any) => {
        const title = "Error!";
        const message = "There was an unexpected error while reading your file";
        this.toastrService.error(message, title);
      };
    } catch (error) {}
  }

  /**
   * Trigger a file-upload modal that allows user to pick a file from his device
   *
   * @since 1.0.0
   */
  triggerFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * Import given data in the chart
   *
   * @since 1.0.0
   */
  importData(jsonData: ExportFileInterface): Observable<boolean> {
    // console.log('jsonData', jsonData);
    this.chartTitle = jsonData.title;
    this.chartDescription = jsonData.description;
    this.chartCategory = jsonData.category;
    return new Observable((obs: Subscriber<boolean>) => {
      for (const node of jsonData.data) {
        let parentNode;
        if (node.parentId) {
          parentNode = jsonData.data.find((obj) => obj.id === node.parentId);
        }
        if (node.type === "question") {
          this.nodeHelperService.createQuestionNode(parentNode, node);
        } else {
          const formattedNode: any = {
            ...node,
            chance: this.getExponentialChance((node as AnswerNodeInterface).chance),
          };
          this.nodeHelperService.importAnswerNode(parentNode, formattedNode);
        }
      }
      // Update next node index in node helper service
      const backupMaxIndexe = Math.max.apply(
        Math,
        jsonData.data.map((obj) => obj.id)
      );
      this.nodeHelperService.setNodeIndex(backupMaxIndexe + 1000);

      return obs.next(true);
    });
  }

  getExponentialChance(chance: number | string | undefined): string {
    if (typeof chance === "undefined") {
      return "";
    }

    try {
      return parseFloat(chance as string).toExponential();
    } catch (error) {
      return chance.toString();
    }
  }

  /**
   * Reset all chart data and clean memory
   *
   * @since 1.0.0
   */
  onChartResetData(askConfirm: boolean = false): void {
    if (!askConfirm) {
      this.nodeHelperService.destroyAllNodes();
      this.chartCategory = "";
      this.chartTitle = "";
      this.chartDescription = "";
      return;
    }
    const title = "Warning!";
    const message = `Are you sure? Proceeding you will reset all current chart data.`;

    this.subscriptions.push(
      this.confirmDialogService.confirm(title, message).subscribe((res: any) => {
        if (res) {
          this.nodeHelperService.destroyAllNodes();
          this.chartCategory = "";
          this.chartTitle = "";
          this.chartDescription = "";
        }
      })
    );
  }

  /**
   * Recursive function that is used to get all the anchestors
   * of the given node inside given nodes array
   *
   * @since 1.0.0
   */
  async getAnchestors(node: any, nodes: any[], anchestors: any[]) {
    const parent = this.getParent(node, nodes);
    if (parent) {
      anchestors.push(parent);
      if (parent.parentId) {
        anchestors = await this.getAnchestors(parent, nodes, anchestors);
      }
    }
    return anchestors;
  }

  /**
   * Get the parent node of the given node
   * inside given nodes
   *
   * @since 1.0.0
   */
  getParent(node: QuestionNodeInterface | AnswerNodeInterface, nodes: any[]) {
    if (!node.parentId) {
      return false;
    }
    return nodes.find((obj) => obj.id === node.parentId);
  }

  /**
   * Get the ending nodes inside given 'nodes' list
   *
   * @since 1.0.0
   */
  getEndingNodes(nodes: any[]): Promise<AnswerNodeInterface[]> {
    return new Promise((resolve) => {
      const items = [];
      for (const node of nodes) {
        if (!nodes.find((node2) => node2.parentId === node.id)) {
          items.push(node);
        }
      }
      return resolve(items);
    });
  }

  /**
   * After a confirmation, deleted all autoSaved data
   *
   * @since 1.0.0
   */
  onResetAutoSave(): void {
    const title = "Are you sure?";
    const message = "Proceeding all autoSaved data will be deleted";
    this.subscriptions.push(
      this.confirmDialogService.confirm(title, message).subscribe((res: any) => {
        if (res) {
          localStorage.removeItem("chartBackup");
          localStorage.removeItem("chartBackupTime");
          this.toastrService.success("All autosaved data has been deleted", "Success");
        }
      })
    );
  }

  /**
   * Handle "downlaod as image" button
   * This will generate an image of chart and will download it
   * in the user device
   *
   * @since 1.1.0
   */
  async downloadChartAsImage(): Promise<void> {
    try {
      this.chartContainer.nativeElement.style.overflow = "visible";
      const dataUrl = await domtoimage.toPng(this.chartContainer.nativeElement);
      console.log("dataUrl", dataUrl);
      // const canvas = await html2canvas(this.chart.nativeElement);
      // const dataUrl = canvas.toDataURL('image/png');
      const element = document.createElement("a");
      element.setAttribute("href", dataUrl);
      element.setAttribute("download", "eMAP.png");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click(); // simulate click
      document.body.removeChild(element);
      this.chartContainer.nativeElement.style.overflow = "hidden";
    } catch (error) {
      console.warn("error", error);
      const title = "Error!";
      const message = "There was an unexpected error while generating the image";
      this.toastrService.error(message, title);
    }
  }
}
