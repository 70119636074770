<main>
  <section class="section">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-5">
          <h1>SimuRisk Mapper</h1>
          <p class="lead">
            Start now using our professional tool and map your case.<br>
            A few things to keep in mind:
          </p>
          <ol>
            <li>Click on the following button to start a new session</li>
            <li>Once chart is ready, you will be able to import data</li>
            <li>Chart will AutoSave every {{ autoSaveTime/60000 }} minutes</li>
          </ol>
          <button mat-raised-button color="primary" (click)="onChartStart()" class="mt-5"
            [ngClass]="{ 'd-none': chartStartButtonIsHidden }">
            Start now!
            <mat-icon fontSet="fal" fontIcon="fa-chevron-right"></mat-icon>
          </button>
        </div> <!-- /.col -->
        <div class="col-12 col-md-5 d-none d-md-block">
          <img src="../../../../../assets/img/chart-page-image.svg" alt="Simurisk chart" class="img-fluid">
        </div>
      </div> <!-- /.row -->
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <div class="alert alert-info mt-4" dimissable="true">
            <h3>Warning!</h3>
            <p>
              Due to performance, stability and security reasons we built this tool for <a
                href="https://www.google.com/intl/it/chrome/" target="_blank">Google Chrome</a>.
              <br>
              We <strong>strongly</strong> recommend to use this browser while using SimuRisk.
            </p>
          </div> <!-- /.alert -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section" [ngClass]="{ 'd-none': hideChart }">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <mat-form-field>
            <mat-label>Process category</mat-label>
            <mat-select matInput [(ngModel)]="chartCategory">
              <mat-option value="">--- Empty ---</mat-option>
              <mat-option *ngFor="let category of chartCategoriesOptions" [value]="category">{{ category }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="pl-4">
            <mat-label>Process title</mat-label>
            <input type="string" matInput required [(ngModel)]="chartTitle" placeholder="Insert chart title">
          </mat-form-field>
          <mat-form-field class="pl-4" style="min-width: 500px">
            <mat-label>Process description</mat-label>
            <textarea matInput required [(ngModel)]="chartDescription" placeholder="Insert chart description"
              rows="1"></textarea>
          </mat-form-field>
        </div> <!-- /.col-12 -->
        <div class="col-12 col-md-3">
          <button mat-raised-button color="primary" (click)="onResetAutoSave()" title="Delete autosaved data"
            class="mb-4">
            <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
            Reset AutoSave
          </button>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">

          <div id="chart-container" #chartContainer class="mat-elevation-z3">
            <div class="chart-options">
              <button mat-raised-button (click)="onDownload()" title="Save and download">
                <mat-icon fontSet="fal" fontIcon="fa-download"></mat-icon>
                Save & Download
              </button>
              <button mat-raised-button (click)="onChartButtonAddQuestionNode()" title="Add a new question"
                [disabled]="tempNodes && tempNodes.length > 0">
                <mat-icon fontSet="fal" fontIcon="fa-plus-circle"></mat-icon>
                Add question
              </button>
              <input #file type="file" accept=".json,application/json,.sim" (change)="handleFileUpload(file.files)"
                class="input-file">
              <mat-spinner-button (btnClick)="onLoadFromFile()" [options]="loadFromFileBtnOptions"
                title="Load data from file"></mat-spinner-button>
              <button mat-raised-button (click)="onChartResetData(true)" title="Reset data">
                <mat-icon fontSet="fal" fontIcon="fa-trash-alt"></mat-icon>
                Reset Data
              </button>
              <button mat-raised-button (click)="onChartResetZoom()" title="Reset zoom">
                <mat-icon fontSet="fal" fontIcon="fa-vector-square"></mat-icon>
              </button>
              <button mat-raised-button (click)="onChartZoomMore()" title="Zoom">
                <mat-icon fontSet="fal" fontIcon="fa-search-plus"></mat-icon>
              </button>
              <button mat-raised-button (click)="onChartZoomLess()" class="btn-sm" title="Unzoom">
                <mat-icon fontSet="fal" fontIcon="fa-search-minus"></mat-icon>
              </button>
              <button mat-raised-button (click)="downloadChartAsImage()" class="btn-sm" title="Save chart as image">
                Save as image
              </button>
            </div> <!-- /.chart-options -->
            <div id="chart" #chart [ngClass]="{'link-to-active': linkModeisActive}">
              <div class="chart-inner" #chartInner></div>
            </div> <!-- /#chart -->
            <div class="chart-info">
              <span><strong>V.</strong>{{ chartVersion }}</span>
              <span><strong>Valid elements:</strong>{{ nodes.length }} / {{ tempNodes.length }}</span>
            </div> <!-- /.chart-info -->
          </div> <!-- /#chart-container -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>
