// Core packages
import { Injectable, Renderer2, ElementRef, RendererFactory2 } from '@angular/core';

// Third party packages

// Custom packages
import { CONFIG } from '../config/config';
import { QuestionNodeInterface } from '../interfaces/question-node-interface';
import { AnswerNodeInterface } from '../interfaces/answer-node-interface';

/**
 * This service is used to draw an SVG lined between given nodes
 * @see https://www.beyondjava.net/how-to-connect-html-elements-with-an-arrow-using-svg
 */

@Injectable({
  providedIn: 'root',
})
export class ConnectionLineService {
  private renderer2: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  /**
   * Create an empty SVG canvas which will be later used to contains
   * some svg
   *
   * @since 1.0.0
   */
  createSVG(
    nodeFromRef: ElementRef,
    nodeFrom: QuestionNodeInterface | AnswerNodeInterface,
    nodeTo: QuestionNodeInterface | AnswerNodeInterface,
    extraClasses?: string
  ): any {
    const svg = this.renderer2.createElement('svg', 'http://www.w3.org/2000/svg');
    this.renderer2.addClass(svg, 'svg-connection-line');
    if (extraClasses) {
      this.renderer2.addClass(svg, extraClasses);
    }
    this.renderer2.setAttribute(svg, 'id', 'svg-canvas');
    this.renderer2.setAttribute(svg, 'data-node-from', nodeFrom.id.toString());
    this.renderer2.setAttribute(svg, 'data-node-to', nodeTo.id.toString());
    this.renderer2.setAttribute(svg, 'width', '152000');
    this.renderer2.setAttribute(svg, 'height', '40000');
    // console.log('nodeFromRef', nodeFromRef);
    nodeFromRef.nativeElement.appendChild(svg);
    return svg;
  }

  /**
   * Draw a SVG circle with given data
   *
   * @since 1.0.0
   */
  drawCircle(
    nodeFromRef: ElementRef,
    leftNode: QuestionNodeInterface | AnswerNodeInterface,
    rightNode: QuestionNodeInterface | AnswerNodeInterface,
    x: number,
    y: number,
    radius: number,
    color: string
  ) {
    const svg = this.createSVG(nodeFromRef, leftNode, rightNode);
    const shape = this.renderer2.createElement('circle', 'http://www.w3.org/2000/svg');
    this.renderer2.setAttribute(shape, 'cx', x.toString());
    this.renderer2.setAttribute(shape, 'cy', y.toString());
    this.renderer2.setAttribute(shape, 'r', radius.toString());
    this.renderer2.setAttribute(shape, 'fill', color);
    this.renderer2.appendChild(svg, shape);
  }

  /**
   * Connevt given nodes with a nice curved line
   *
   * @since 1.0.0
   */
  connectDivs(
    nodeFromRef: ElementRef,
    leftNode: QuestionNodeInterface | AnswerNodeInterface,
    rightNode: QuestionNodeInterface | AnswerNodeInterface,
    color: string,
    tension: number,
    extraSvgClasses?: string
  ) {
    let x1 = leftNode.x + 350 + 13;
    let y1 = 2000 + leftNode.y + 60; // Increment y1 and y2 of 2000 since the svg has a negative x offset of 2000
    let x2 = rightNode.x + 5;
    let y2 = 2000 + rightNode.y + 60; // Increment y1 and y2 of 2000 since the svg has a negative x offset of 2000

    // @warning if remove this than please uncomment the following drawCircleline
    x1 -= 5;
    y1 -= 0;
    x2 += 5;
    y2 += 2;

    // @warning if re-enable this than please REMOVE the previous increment of 5px of x2 and y5
    // this.drawCircle(nodeFromRef, leftNode, rightNode, x1, y1, 3, color);
    // this.drawCircle(nodeFromRef, leftNode, rightNode, x2, y2, 3, color);
    this.drawCurvedLine(nodeFromRef, leftNode, rightNode, x1, y1, x2, y2, color, tension, extraSvgClasses);
  }

  /**
   * Draw a curved svg lines with given data
   *
   * @since 1.0.0
   */
  drawCurvedLine(
    nodeFromRef: ElementRef,
    leftNode: QuestionNodeInterface | AnswerNodeInterface,
    rightNode: QuestionNodeInterface | AnswerNodeInterface,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    color: string,
    tension: number,
    extraSvgClasses?: string
  ) {
    const svg = this.createSVG(nodeFromRef, leftNode, rightNode, extraSvgClasses);
    const shape = this.renderer2.createElement('path', 'http://www.w3.org/2000/svg');

    // if (tension < 0) {
    //   var delta = (y2 - y1) * tension;
    //   var hx1 = x1;
    //   var hy1 = y1 - delta;
    //   var hx2 = x2;
    //   var hy2 = y2 + delta;
    // } else {
    //   var delta = (x2 - x1) * tension;
    //   var hx1 = x1 + delta;
    //   var hy1 = y1;
    //   var hx2 = x2 - delta;
    //   var hy2 = y2;
    // }

    const delta = (x2 - x1) * tension;
    const hx1 = x1 + delta;
    const hy1 = y1;
    const hx2 = x2 - delta;
    const hy2 = y2;
    const path = `M ${x1} ${y1} C ${hx1} ${hy1} ${hx2} ${hy2} ${x2} ${y2}`;

    this.renderer2.setAttribute(shape, 'd', path);
    this.renderer2.setAttribute(shape, 'fill', 'none');
    this.renderer2.setAttribute(shape, 'stroke', color);
    this.renderer2.appendChild(svg, shape);
  }
}
