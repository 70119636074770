// Core packages
import { Component, OnInit, Renderer2, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject } from "@angular/core";
import { CdkDragEnd, CdkDragMove } from "@angular/cdk/drag-drop";

// Thrid party packages
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import BigNumber from "bignumber.js";

// Custom packages
import { CONFIG } from "app/config/config";
import { ConfirmDialogService } from "app/services/confirm-dialog.service";
import { AnswerNodeInterface } from "app/interfaces/answer-node-interface";
import { ConnectionLineService } from "app/services/connection-line.service";
import { QuestionNodeInterface } from "app/interfaces/question-node-interface";
import { AuthService } from "app/services/auth.service";
import { NodeHelperService } from "app/services/node-helper.service";

@Component({
  selector: "[app-answer-node]",
  templateUrl: "./answer-node.component.html",
  styleUrls: ["./answer-node.component.scss"],
})
export class AnswerNodeComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @ViewChild("node") node: ElementRef;
  isInvalid = true; // By default it's invalid!
  config: any;
  parentNode: QuestionNodeInterface;
  type = "answer";
  id: number;
  text = "";
  effect = "P";
  chance = 0;
  damage = 0;
  x = 0;
  y = 0;
  linkTo: number;
  creation: any;
  updates = [];
  removeNode$: BehaviorSubject<AnswerNodeInterface> = new BehaviorSubject(null);
  createChildQuestionNode$: BehaviorSubject<AnswerNodeInterface> = new BehaviorSubject(null);
  data$: BehaviorSubject<AnswerNodeInterface>;
  addChild$: BehaviorSubject<any> = new BehaviorSubject(null);
  moved$: BehaviorSubject<any> = new BehaviorSubject(null);
  linkTo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  linkToRemover$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
    @Inject("config") config,
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    public connectionLineService: ConnectionLineService,
    private authService: AuthService,
    private nodeHelperService: NodeHelperService
  ) {
    this.config = config;
    // console.log('configAnswer', config);
    this.parentNode = this.config.parentNode;
    this.id = this.config.id;

    if (this.config) {
      // if (this.config.parentNode) {
      //   this.parentNode = this.config.parentNode;
      // }
      // if (this.config.id) {
      //   this.id = this.config.id;
      // }
      if (this.config.text) {
        this.text = this.config.text;
      }
      if (this.config.effect) {
        this.effect = this.config.effect;
      }
      if (this.config.chance) {
        this.chance = this.config.chance;
      }
      if (this.config.damage) {
        this.damage = this.config.damage;
      }
      if (this.config.x) {
        this.x = this.config.x;
      }
      if (this.config.y) {
        this.y = this.config.y;
      }
      if (this.config.linkTo) {
        this.linkTo = this.config.linkTo;
        // console.log(`Node ${this.id} is linked to node ${this.linkTo}`);
      }
      if (this.config.creation) {
        this.creation = this.config.creation;
      } else {
        this.creation = {
          authorFirstName: this.authService.loggedUser$.value.firstName,
          authorLastName: this.authService.loggedUser$.value.lastName,
          authorEmail: this.authService.loggedUser$.value.email,
          date: new Date(),
        };
      }
      if (this.config.updates) {
        this.updates = this.config.updates;
      }
    } else {
      console.error("config", this.config);
    }

    this.data$ = new BehaviorSubject({
      type: this.type,
      id: this.id,
      parentId: this.parentNode.id,
      text: this.text,
      effect: this.effect,
      chance: this.chance,
      damage: this.damage,
      x: this.x,
      y: this.y,
      linkTo: this.linkTo,
      creation: {
        authorFirstName: this.authService.loggedUser$.value.firstName,
        authorLastName: this.authService.loggedUser$.value.lastName,
        authorEmail: this.authService.loggedUser$.value.email,
        date: new Date(),
      },
    });
  }

  /**
   * Init component
   */
  ngOnInit(): void {}

  /**
   * Build a connection line between current node and the
   * node with id like the one in "linkTo" and the given data
   *
   * @since 1.0.0
   */
  createLinkToLine(linkToObj: any): void {
    this.destroyLinkToLinesSvg();
    this.connectionLineService.connectDivs(this.elementRef, this.getData(), linkToObj.node, "black", 0.7, "linkTo");
  }

  /**
   * Handle component after render
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    // If parentNodeId is set, then create a connection line with parent node
    if (this.parentNode && this.parentNode.id) {
      const leftNode = this.parentNode;
      const rightNode = this.getData();
      this.connectionLineService.connectDivs(
        this.elementRef,
        leftNode,
        rightNode,
        CONFIG.chart.connectionLineAnswerToQuestionColor,
        CONFIG.chart.connectionLineTension
      );
    } else {
      // console.log('noParentNode given', this.parentNode);
    }
  }

  /**
   * Handle component destroy
   *
   * @returns undefined
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Set initial input value as "data-old-value" attribute
   * This will be used later if new value is not valid due to valudation rules
   * an we need to restore the old value
   *
   * @since 1.0.0
   */
  onChanceFocus(event: Event): void {
    this.renderer2.setAttribute(event.target, "data-old-value", this.chance.toString());
  }

  /**
   * Set initial input value as "data-old-value" attribute
   * This will be used later if new value is not valid due to valudation rules
   * an we need to restore the old value
   *
   * @since 1.0.0
   */
  onDamageFocus(event: Event): void {
    if (this.damage) {
      this.renderer2.setAttribute(this.elementRef.nativeElement, "data-old-value", this.damage.toString());
    }
  }

  /**
   * Handle change event on all inputs input validating
   * provided data and checking if we have to create some new nodes
   *
   * @since 1.0.0
   */
  onInputChange(): void {
    if (!this.nodeIsValid(true)) {
      return;
    }

    this.updates.push({
      authorFirstName: this.authService.loggedUser$.value.firstName,
      authorLastName: this.authService.loggedUser$.value.lastName,
      authorEmail: this.authService.loggedUser$.value.email,
      date: new Date(),
    });

    const data = this.getData();

    // Emit new data
    this.data$.next(data);

    // Emit the needs of creating a new question-node as a child of this node
    this.createChildQuestionNode$.next(data);
  }

  /**
   * Check if insered value respects the validation rules
   * if not, just restore the previous value
   *
   * @since 1.0.0
   */
  validateChanceChange(): boolean {
    if (this.chance <= 0 || this.chance > 1 || this.chance.toString().trim() === "") {
      const chanceInput = this.elementRef.nativeElement.children[0].children[1].children[2];
      if (chanceInput.attributes) {
        Object.keys(chanceInput.attributes).forEach((key) => {
          const attribute = chanceInput.attributes[key];
          if (attribute.name === "data-old-value") {
            this.chance = attribute.value;
          }
        });
      }
      return false;
    }
    return true;
  }

  /**
   * Check if current node is valid or not and update this.isInvalid
   *
   * @since 1.0.0
   */
  nodeIsValid(notifyErrors: boolean = false): boolean {
    // Validate chance value
    if (!this.validateChanceChange()) {
      if (notifyErrors) {
        const title = "Warning!";
        const message = "Chance value must be between 0 and 1 (zero not allowed)";
        this.toastrService.warning(message, title);
      }
      this.isInvalid = true;
      return false;
    }

    // Validate text value
    if (this.text.length < 1) {
      if (notifyErrors) {
        const title = "Warning!";
        const message = "Insert some text before proceeding";
        this.toastrService.warning(message, title);
      }
      this.isInvalid = true;
      return false;
    }

    this.isInvalid = false;
    return true;
  }

  /**
   * Get all data in the appropriate format and returns it
   *
   * @since 1.0.0
   */
  getData(): AnswerNodeInterface {
    const data = {
      type: this.type,
      id: this.id,
      parentId: this.parentNode.id,
      text: this.text,
      effect: this.effect,
      chance: this.chance,
      damage: this.damage,
      x: this.x,
      y: this.y,
      linkTo: this.linkTo,
      creation: this.creation,
      updates: this.updates,
    };
    return data;
  }

  /**
   * Remove current component and it's data
   *
   * @since 1.0.0
   */
  onRemove(): void {
    const title = "Warning!";
    const message = "Are you sure? Proceeding you will delete this element and all the underlying elements";
    const confirmDialogSubscription = this.confirmDialogService.confirm(title, message).subscribe((res) => {
      if (res) {
        const data = this.getData();
        this.removeNode$.next(data);
      }
    });
    this.subscriptions.push(confirmDialogSubscription);
  }

  /**
   * Handle click on "+" button adding a child question-node to current node
   *
   * @since 1.0.0
   */
  onAddChild(): void {
    // If "link to " function is active, then deactivate it
    this.linkTo = null;
    this.destroyLinkToLinesSvg();
    this.linkTo$.next(false);

    // Prevent the creation of a child if current node is invalid
    if (!this.nodeIsValid(true)) {
      return;
    }

    this.addChild$.next(this.getData());
  }

  /**
   * Set given coordinates to this component
   *
   * @since 1.0.0
   */
  setCoordinates(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  /**
   * Edit current coordinates with given deltas
   *
   * @since 1.0.0
   */
  editCoordinates(x: number, y: number): void {
    this.x += x;
    this.y += y;
    const svgs = this.elementRef.nativeElement.querySelectorAll("svg");
    Object.keys(svgs).forEach((key) => {
      const svg = svgs[key];
      const element = svg.children[0];
      if (element.nodeName === "circle") {
        this.renderer2.setAttribute(element, "cx", element.cx.baseVal.value + x);
        this.renderer2.setAttribute(element, "cy", element.cy.baseVal.value + y);
      }
      if (element.nodeName === "path") {
        const d = element.attributes[0].value;
        const dAry = d.split(" ");
        const x1 = parseInt(dAry[1], 10) + x;
        const y1 = parseInt(dAry[2], 10) + y;
        const x2 = parseInt(dAry[8], 10) + x;
        const y2 = parseInt(dAry[9], 10) + y;
        const delta = (x2 - x1) * CONFIG.chart.connectionLineTension;
        const hx1 = x1 + delta;
        const hy1 = y1;
        const hx2 = x2 - delta;
        const hy2 = y2;
        const path = `M ${x1} ${y1} C ${hx1} ${hy1} ${hx2} ${hy2} ${x2} ${y2}`;
        element.attributes[0].value = path;
      }
    });

    // Refresh data inside nodeHelperService
    this.data$.next(this.getData());
  }

  /**
   * Set valid status to the component
   *
   * @since 1.0.0
   */
  setValidStatus(): void {
    this.isInvalid = false;
  }

  /**
   * On element drag ended
   *
   * @since 1.0.0
   */
  onDragEnded(event: CdkDragEnd): void {
    // console.log("event", event);
    this.moved$.next(event.distance);
    event.source._dragRef.reset();

    // Add distance to current X and Y and remove the translate3d style
    this.x = this.x + event.distance.x;
    this.y = this.y + event.distance.y;
    this.renderer2.removeStyle(this.node.nativeElement, "transform");

    // Send new data to other services
    this.data$.next(this.getData());
  }

  /**
   * On element drag
   *
   * @since 1.0.0
   */
  onDragMove(event: CdkDragMove): void {
    // console.log("event", event);
  }

  /**
   * Update svg coordinates and path after this node has been moved.
   * This is used to ensure that iven if distance between current node
   * and parent node is changed the connection line is well rendered
   *
   * @since 1.0.0
   */
  setSVGsNewCoordinates(x2?: number, y2?: number, path?: string, extraSelectorClasses?: string): void {
    let selectorString = "svg";
    if (extraSelectorClasses) {
      selectorString += `${extraSelectorClasses}`;
    }
    const svgs = this.elementRef.nativeElement.querySelectorAll(selectorString); // Find all svg
    Object.keys(svgs).forEach((key) => {
      const svg = svgs[key];
      const element = svg.children[0];
      // Update right dot with new coordinates
      if (x2 && y2 && element.nodeName === "circle" && key === "1") {
        this.renderer2.setAttribute(element, "cx", x2.toString());
        this.renderer2.setAttribute(element, "cy", y2.toString());
      }

      // Update curved line
      if (path && element.nodeName === "path") {
        element.attributes[0].value = path;
      }
    });
  }

  /**
   * Activate linkTo mode
   *
   * @since 1.0.0
   */
  onCreateLink(): void {
    if (this.linkTo) {
      console.log("this.linkTo", this.linkTo);
      this.linkToRemover$.next(this.linkTo);
      this.linkTo = null;
      this.linkTo$.next(false);
      this.destroyLinkToLinesSvg();
    }
    this.linkTo$.next(true);
  }

  /**
   * Destroy all existing linkTo lines of this component
   *
   * @since 1.0.0
   */
  destroyLinkToLinesSvg(): void {
    // console.log('destroyLinkToLinesSvg()', this.getData());
    // @todo remove this node from the array "linkedToIds" of all
    // questionNodes (must use a custom event handled in the node-helper.service)
    Array.from(this.elementRef.nativeElement.children).forEach((child: any) => {
      const classList = child.classList as Array<string>;
      for (const theClass of classList) {
        if (theClass === "linkTo") {
          this.renderer2.removeChild(this.elementRef.nativeElement, child);
        }
      }
    });
  }

  /**
   * Recalculate change of clicked item as per following
   * this.chanche = 1 - Σ(sibling.change)
   *
   * @since 1.1.0
   */
  async onRecalculateSiblingsChanges(): Promise<void> {
    const siblings = this.nodeHelperService.getSiblings(this.getData(), this.nodeHelperService.nodes$.value);
    let siblingsCumulatedChance = new BigNumber(0);
    siblings.forEach((el) => {
      siblingsCumulatedChance = siblingsCumulatedChance.plus(el.node.chance);
    });
    const newChance = new BigNumber(1).minus(siblingsCumulatedChance).toExponential();
    (this.chance as any) = newChance;

    this.onInputChange();
  }

  /**
   * This remove an SVG with given attribute and value from the DOM
   *
   * @since 1.1.0
   */
  removeSvgByNodeId(nodeId: number, attribute: string = "data-node-to"): void {
    const selector = `svg[${attribute}="${nodeId}"]`;
    const svgs = this.elementRef.nativeElement.querySelectorAll(selector); // Find all svg
    for (const svg of svgs) {
      svg.remove();
    }
  }
}
