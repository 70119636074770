// Core packages
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Third party packages

// Custom packages
import { Error404Component } from "app/components/errors/error404/error404.component";
import { ChartComponent } from "app/components/pages/app/chart/chart.component";
import { CanDeactivateChartGuard } from "app/guards/can-deactivate-chart.guard";
import { HomeComponent } from "app/components/pages/home/home.component";
import { LoginComponent } from "app/components/pages/auth/login/login.component";
import { RecoverPasswordComponent } from "./components/pages/auth/recover-password/recover-password.component";
import { AuthGuard } from "./guards/auth.guard";
import { RoleGuard } from "./guards/role.guard";
import { UsersListComponent } from "./components/admin/pages/users/users-list/users-list.component";
import { UserDetailComponent } from "./components/admin/pages/users/user-detail/user-detail.component";
import { AddUserComponent } from "./components/admin/pages/users/add-user/add-user.component";
import { ResetPasswordComponent } from "./components/pages/auth/reset-password/reset-password.component";
import { AnalyzeComponent } from "./components/pages/app/analyze/analyze.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { UserProfileComponent } from "./components/pages/user-profile/user-profile.component";
import { AuthService } from "./services/auth.service";
import { MarketplaceComponent } from "./components/pages/app/marketplace/marketplace.component";

// Define routes
const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: "full" },
  {
    path: "auth",
    children: [
      { path: "login", component: LoginComponent },
      { path: "recover-password", component: RecoverPasswordComponent },
      { path: "reset-password", component: ResetPasswordComponent },
    ],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "app",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ChartComponent,
        pathMatch: "full",
        canDeactivate: [CanDeactivateChartGuard],
      },
      { path: "analyze", component: AnalyzeComponent },
    ],
  },
  {
    path: "app/marketplace",
    component: MarketplaceComponent,
  },
  {
    path: "profile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin",
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: ["admin"] },
    children: [
      {
        path: "users",
        children: [
          { path: "list", component: UsersListComponent },
          { path: "detail/:userId", component: UserDetailComponent },
          { path: "add", component: AddUserComponent },
        ],
      },
    ],
  },
  { path: "**", redirectTo: "errors/404" },
  {
    path: "errors",
    children: [{ path: "404", component: Error404Component }],
  },
  { path: "", pathMatch: "full", redirectTo: "auth/accedi" },
  { path: "**", redirectTo: "errors/404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
