<main>
  <section class="section py-3">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5">
          <h1>Add a new user</h1>
          <p class="lead">
            Fill requested fields and click on "Save" button to add a new user
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
      <div class="row">
        <div class="col-12 col-md-7">
          <app-dynamic-form *ngIf="formSettings" [options]="formSettings.options" [formControls]="formSettings.controls"
            (formSubmitted)="onSubmit($event)" [stopLoading]="stopFormLoading" [resetForm]="resetForm">
          </app-dynamic-form>
        </div> <!-- /.col-12 -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>
