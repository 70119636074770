<main>
  <section class="section py-3">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5">
          <h1>User profile</h1>
          <p class="lead">
            View and edit your data
          </p>
          <p>
            Your account will expires on {{ loggedUser.expiresAt | date:'short' }}
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
      <div class="row">
        <div class="col-12 col-md-7">
          <app-dynamic-form *ngIf="formSettings" [options]="formSettings.options" [formControls]="formSettings.controls"
            (formSubmitted)="onFormSubmit($event)" [stopLoading]="stopFormLoading" [resetForm]="resetForm">
          </app-dynamic-form>
        </div> <!-- /.col-12 -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>
