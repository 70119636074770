// Core packages
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// Third party packages
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatProgressButtonOptions } from 'mat-progress-buttons';

// Custom packages
import { ThemeService } from 'app/services/theme.service';
import { AuthService } from 'app/services/auth.service';
import { HelperService } from 'app/services/helper.service';
import { UsersService } from 'app/services/users.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(320)]],
  });
  formSubmitBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Recover password',
    spinnerSize: 19,
    raised: true,
    stroked: true,
    flat: false,
    fab: false,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    fullWidth: false,
    disabled: false,
    mode: 'indeterminate',
  };

  constructor(
    private themeService: ThemeService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthService,
    private router: Router,
    public helperService: HelperService,
    private usersService: UsersService,
  ) { }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Set page title
    this.themeService.setPageTitle('Recover password');
  }

  /**
   * Handle component init after HTML render
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    this.initBalls();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscription
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Convenience getter for easy access to form fields
   *
   * @since 1.0.0
   */
  get f(): any {
    return this.form.controls;
  }

  /**
   * Handle form submit and send data to the back-end
   *
   * @since 1.0.0
   */
  onFormSubmit(): void {
    if (this.form.invalid) {
      const title = 'Warning!';
      const message = 'Form is invalid, please check and try again';
      this.toastrService.error(message, title);
      return;
    }

    // Set active status to the button (start loading)
    this.formSubmitBtnOptions.active = true;

    // Get form values
    const email = this.form.get('email').value;

    const recoverPasswordSubscription = this.usersService.recoverPassword(email)
      .pipe(
        finalize(() => this.formSubmitBtnOptions.active = false)
      )
      .subscribe(
        res => {
          if (res.status) {
            this.form.reset();
            this.form.markAsPristine();
            const title = 'Success!';
            const message = 'We have sent you an email with all the instructions';
            this.toastrService.success(message, title);
          }
        },
        err => {
          // console.log('err', err);
          // Choose one of the following error handling
          // method. The first one show a message right
          // under the form fields (if the form is properly
          // setted), the second one show toastr
          // notifications for each error
          this.helperService.handleFormError(this.form, err);
          // this.helperService.handleError(err);
        }
      );
    this.subscriptions.push(recoverPasswordSubscription);

    // // Check credentials against back-end
    // const loginSubscription = this.authService.login(email,)
    //   .pipe(
    //     finalize(() => this.formSubmitBtnOptions.active = false)
    //   )
    //   .subscribe(
    //     res => {
    //       console.log('res', res);
    //       if (res.status) {
    //         this.router.navigate(['app']);
    //         return;
    //       }

    //       // Check if back-end is up and is running
    //       const message = 'Unexpected server error. Please reload the page and try again in a few';
    //       const title = 'Warning!';
    //       this.toastrService.error(message, title);
    //       return;
    //     },
    //     err => {
    //       console.log('err', err);
    //       // Choose one of the following error handling
    //       // method. The first one show a message right
    //       // under the form fields (if the form is properly
    //       // setted), the second one show toastr
    //       // notifications for each error
    //       this.helperService.handleFormError(this.form, err);
    //       // this.helperService.handleError(err);
    //     }
    //   );
    // this.subscriptions.push(loginSubscription);
  }

  /**
   * Generate random-colored moving balls and add them to the DOM
   *
   * @see https://www.cssscript.com/animated-particles-background-pure-javascript/   *
   * NB: would be nice to make a service for this (delegate componentRef)
   * and user renderer2
   *
   * @since 1.0.0
   */
  initBalls(): void {
    const colors = ['#3CC157', '#2AA7FF', '#1B1B1B', '#dd0031', '#F85F36'];
    const numBalls = 50;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      const ball = document.createElement('div');
      ball.classList.add('ball');
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;
      ball.style.position = 'absolute';
      ball.style.borderRadius = '100%';
      ball.style.opacity = '0.2';
      ball.style.zIndex = '0';

      balls.push(ball);
      document.getElementsByClassName('hero-section')[0].append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      const to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      el.animate(
        [
          { transform: 'translate(0, 0)' },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 5000, // random duration
          direction: 'alternate',
          fill: 'both',
          iterations: Infinity,
          easing: 'ease-in-out'
        }
      );
    });
  }
}
