// Core packages
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Third party packages
import moment from 'moment';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import { ApiService } from './api.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedUser$ = new BehaviorSubject<User>(null);
  sessionExpiresAt$ = new BehaviorSubject<number>(0);

  /**
   * Class constructor
   */
  constructor(private apiService: ApiService, private toastrService: ToastrService, private router: Router) {}

  /**
   * Log user in through apiService
   *
   * @since 1.0.0
   */
  login(email: string, password: string): Observable<any> {
    return this.apiService.apiCall('POST', 'auth/login', { email, password }).pipe(
      map((result: any) => {
        if (result.status) {
          if (!this.setSession(result)) {
            return false;
          }
          const expiresAt = moment().add(result.expiresIn, 'second').utc();
          this.loggedUser$.next(result.user);
          this.sessionExpiresAt$.next(expiresAt.valueOf());
          return result;
        }
        return result;
      })
    );
  }

  /**
   * Check if current user is logged in
   *
   * @since 1.0.0
   */
  checkLogin(): Observable<any> {
    return this.apiService.apiCall('GET', 'auth/check').pipe(
      tap((result: any) => {
        if (result.status) {
          const expiresAt = moment().add(result.expiresIn, 'second');
          this.loggedUser$.next(result.user);
          this.sessionExpiresAt$.next(expiresAt.valueOf());
        }
      })
    );
  }

  /**
   * Log user out
   *
   * @since 1.0.0
   *
   * @param redirect True if after logout there should be a redirect
   * to login page. Default false.
   * @returns boolean true if user was logged out, false otherwhise
   */
  logout(redirect?: boolean): boolean {
    // console.log('logout()', redirect);
    const withNavigate = redirect ? redirect : false;
    if (!this.checkLocalStorage()) {
      return false;
    }
    localStorage.removeItem('s.id');
    localStorage.removeItem('s.expiresAt');
    localStorage.removeItem('s.refreshToken');
    localStorage.removeItem('s.refreshTokenExpiresAt');
    this.loggedUser$.next(null);
    this.sessionExpiresAt$.next(0);
    if (withNavigate) {
      this.router.navigate(['/', 'auth', 'login']);
    }
    return true;
  }

  /**
   * Refresh current token (s.id) using refresh token
   *
   * @since 1.0.0
   */
  refreshToken(redirectIfLogout = true): Observable<boolean> {
    const refreshTokenExp = +localStorage.getItem('s.refreshTokenExpiresAt');
    const refreshToken = localStorage.getItem('s.refreshToken');

    if (
      typeof localStorage.getItem('s.refreshTokenExpiresAt') === undefined ||
      (typeof localStorage.getItem('refreshToken') === undefined &&
        moment.unix(moment.now()) > moment.unix(refreshTokenExp))
    ) {
      this.logout(redirectIfLogout);
      return of(false);
    }

    if (!this.loggedUser$.value || !this.loggedUser$.value.id) {
      this.logout(redirectIfLogout);
      return;
    }

    return this.apiService
      .apiCall('POST', 'auth/refreshtoken', {}, { 'X-Refresh-Token': `Bearer ${refreshToken}` })
      .pipe(
        tap((result: any) => {
          this.setSession(result);
          const expiresAt = moment().add(result.expiresIn, 'second');
          this.sessionExpiresAt$.next(expiresAt.valueOf());
          return of(true);
        }),
        catchError((err) => {
          this.logout(redirectIfLogout);
          return of(false);
        })
      );
  }

  /**
   * Update current logged user data
   *
   * @since 1.0.0
   */
  updateCurrentLoggedUser(user: User): void {
    this.loggedUser$.next(user);
  }

  /**
   * Set given login result data as current session
   *
   * @since 1.0.0
   *
   * @param data Session data coming from back-end
   * @returns boolean True if session was setted, false otherwhise
   */
  private setSession(data: any): boolean {
    const expiresAt = moment().add(data.expiresIn, 'second');

    if (!this.checkLocalStorage()) {
      const title = 'LocalStorage not available, please update your browser';
      const message = 'Warning';
      this.toastrService.error(message, title);
      console.error('LOCAL STORAGE IS NOT AVAILABLE');
      return false;
    }

    localStorage.setItem('s.id', data.token);
    localStorage.setItem('s.expiresAt', JSON.stringify(expiresAt.valueOf()));
    if (data.refreshToken) {
      localStorage.setItem('s.refreshToken', data.refreshToken);
    }
    if (data.refreshExpiresIn) {
      const refreshExpiresAt = moment().add(data.refreshExpiresIn, 'second');
      localStorage.setItem('s.refreshTokenExpiresAt', JSON.stringify(refreshExpiresAt.valueOf()));
    }

    return true;
  }

  /**
   * Check if client localStorage is available
   *
   * @since 1.0.0
   */
  private checkLocalStorage(): boolean {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }
}
