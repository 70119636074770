// Core packages
import { Injectable } from '@angular/core';

// Third party packages
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Custom packages
import { ApiService } from 'app/services/api.service';
import { TableQueryParamsModel } from 'app/models/table-query-params.model';
import { ListApiResponse } from 'app/interfaces/_base/list-api-response.interface';
import { HelperService } from 'app/services/helper.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  /**
   * Class constructor
   */
  constructor(private apiService: ApiService, private helperService: HelperService) {}

  /**
   * Create a new user
   *
   * @since 1.0.0
   *
   * @param user User data
   * @returns Observable<any>
   */
  create(user: any): Observable<any> {
    return this.apiService.apiCall('POST', 'users', user);
  }

  /**
   * Get users list for given query params
   *
   * @since 1.0.0
   *
   * @param [start] Query left offset
   * @param [limit] Results number
   * @param [sort] Sorting field name
   * @param [sortVersus] Sorting versus. Can be 'asc' or 'desc'
   * @param [search] Filtering search string
   * @param [role] Filtering user role
   * @returns Observable<ListApiResponse>
   */
  getList(
    start?: number,
    limit?: number,
    sort?: string,
    sortVersus?: string,
    search?: string,
    role?: string
  ): Observable<ListApiResponse> {
    const params = new TableQueryParamsModel(start, limit, sort, sortVersus, search, role);
    return this.apiService.apiCall('GET', 'users', params);
  }

  /**
   * Get given userId data from back-end
   *
   * @since 1.0.0
   *
   * @param userId ID of the user to get
   * @returns Observable<any>
   */
  get(userId: string): Observable<any> {
    return this.apiService.apiCall('GET', `users/${userId}`).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      })
    );
  }

  /**
   * Update user with given userId with given user data
   *
   * @since 1.0.0
   *
   * @param userId ID of user to update
   * @param data New user's data
   * @returns Observable<any>
   */
  update(userId: string, data: object): Observable<any> {
    return this.apiService.apiCall('PUT', `users/${userId}`, data);
  }

  /**
   * Update logged user profile with given data
   *
   * @since 1.0.0
   *
   * @param data New user's data
   * @returns Observable<any>
   */
  updateProfile(data: object): Observable<any> {
    return this.apiService.apiCall('PUT', 'users', data);
  }

  /**
   * Delete permanently an user
   *
   * @since 1.0.0
   *
   * @param userId ID of the user
   * @returns Observable<any>
   */
  delete(userId: string): Observable<any> {
    return this.apiService.apiCall('DELETE', `users/${userId}`);
  }

  /**
   * Send to user an email with an OTP tha he must use
   * within X seconds (see settings) to reset his email
   *
   * @since 1.0.0
   *
   * @param email email of user
   * @returns Observable<any>
   */
  recoverPassword(email: string): Observable<any> {
    return this.apiService.apiCall('POST', `users/recover-password`, { email }).pipe(
      catchError((err) => {
        this.helperService.handleError(err);
        return throwError(err);
      })
    );
  }

  /**
   * Reset the password of a user (verified with a token that he must use
   * within X seconds (see settings))
   *
   * @since 1.0.0
   *
   * @param password password of user
   * @returns Observable<any>
   */
  resetPassword(password: string, email: string, token: string): Observable<any> {
    return this.apiService.apiCall('POST', `users/reset-password`, { password, email, token });
    // .pipe(
    //   catchError(err => {
    //     this.helperService.handleError(err);
    //     return throwError(err);
    //   })
    // );
  }

  /**
   * Get a list of user with given role
   *
   * @since 1.0.0
   *
   * @param role Users role
   * @returns Observable<any>
   */
  getListOfRole(role: string): Observable<any> {
    return this.apiService.apiCall('GET', `users/role/${role}`);
  }
}
