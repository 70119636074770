// Core packages
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

// Third party packages
import { Subscription, merge, fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Custom packages
import { UsersDataSource } from 'app/utils/data-sources/users-data-source';
import { ThemeService } from 'app/services/theme.service';
import { UsersService } from 'app/services/users.service';
import { HelperService } from 'app/services/helper.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription[] = [];
  displayedColumns: string[] = [
    'lastName',
    'email',
    'role',
    'companyName',
    'expiresAt',
    'active',
    'createdAt',
    'actions',
  ];
  dataSource: UsersDataSource;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('input', { static: true }) input: ElementRef;

  constructor(
    private themeService: ThemeService,
    private usersService: UsersService,
    private helperService: HelperService
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Set page title
    this.themeService.setPageTitle('Users list');

    // Init datasource
    this.dataSource = new UsersDataSource(this.usersService, this.helperService);
  }

  /**
   * After view init logic
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    // Load first set of data
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const length = this.paginator.pageSize;
    this.dataSource.loadItems(start, length, this.sort.active, this.sort.direction, this.input.nativeElement.value);

    // Reset pagination after sorting
    const sortingSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortingSubscription);

    // Once user change sorting OR change page
    // let's reload the page data
    const paginatorSubscription = merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadPage()))
      .subscribe();
    this.subscriptions.push(paginatorSubscription);

    // Handle server-side filtering through search
    const searchSubsciption = fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(350), // max 1 query every 350 milliseconts
        distinctUntilChanged(), // Eliminate duplicate values
        tap(() => {
          this.paginator.pageIndex = 0; // Reset page
          this.loadPage();
        })
      )
      .subscribe();
    this.subscriptions.push(searchSubsciption);

    // Handle user's page change event
    // loading requested data
    const pageSubscription = this.paginator.page.pipe(tap(() => this.loadPage())).subscribe();
    this.subscriptions.push(pageSubscription);
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Load requested data page
   *
   * @since 1.0.0
   *
   * @returns undefined
   */
  loadPage(): void {
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const length = this.paginator.pageSize;
    this.dataSource.loadItems(start, length, this.sort.active, this.sort.direction, this.input.nativeElement.value);
  }
}
