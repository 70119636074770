// Core packages
import { Component, OnInit } from '@angular/core';

// Third party packages
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

// Custom packages
import { CONFIG } from 'app/config/config';
import { User } from 'app/models/user.model';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  loggedUser: User;
  isNavbarCollapsed = true;
  owner: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
    this.owner = CONFIG.owner;
  }

  /**
   * Handle component init
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Check is user is logged with authService
    this.subscriptions.push(
      this.authService.loggedUser$.subscribe((user) => {
        if (user) {
          this.loggedUser = user;
        } else {
          this.loggedUser = undefined;
        }
      })
    );
  }

  /**
   * Check if user is logged by localStorage data
   *
   * @since 1.0.0
   */
  checkLogin(): void {
    this.subscriptions.push(
      this.authService.checkLogin().subscribe(
        (res) => {
          if (res.status) {
            this.loggedUser = res.user;
          } else {
            this.loggedUser = undefined;
          }
        },
        (err) => {
          this.loggedUser = undefined;
        }
      )
    );
  }

  /**
   * Log user out deleting his JWT
   *
   * @returns undefined
   */
  onLogout(): void {
    this.authService.logout(true);
  }

  /**
   * Redirect to user detail page
   *
   * @returns undefined
   */
  onViewProfile(): void {
    this.router.navigate(['app', 'profile']);
  }
}
