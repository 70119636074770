<div class="form-wrapper" *ngIf="form"
  [ngClass]="(options.formWrapperClasses ? options.formWrapperClasses.join(' ') : '')">
  <div class="form-title">{{ options.title }}</div>
  <div class="form-description">{{ options.description }}</div>

  <form class="form py-4" [formGroup]="form" (submit)="onFormSubmit($event)">
    <div class="row">
      <ng-container *ngFor="let control of controls">
        <div class="{{ control.parentDivClasses ? control.parentDivClasses.join(' ') : '' }}">
          <mat-form-field [ngClass]="{'required': isRequired(f[control.name])}"
            *ngIf="control.type === 'input' || control.type === 'select'">
            <mat-label *ngIf="control.label">{{ control.label }}</mat-label>

            <input *ngIf="control.type === 'input'" [formControlName]="control.name" [placeholder]="control.placeholder"
              [type]="getAttribute(control, 'type')" [min]="getAttribute(control, 'min')"
              [max]="getAttribute(control, 'max')" [step]="getAttribute(control, 'step')" matInput>

            <mat-select *ngIf="control.type === 'select'" [formControlName]="control.name"
              [placeholder]="control.placeholder">
              <mat-option *ngFor="let options of control.options" [value]="options.value">{{ options.label }}
              </mat-option>
            </mat-select>

            <mat-error *ngFor="let key of f[control.name].errors | objToKeys"
              [innerHTML]="getTheErrorMessage(key, f[control.name].errors[key])"></mat-error>
            <!-- <mat-error *ngIf="controlHasErrors(f[control.name]) | async">
              <p *ngFor="let key of f[control.name].errors | objToKeys"
                [innerHTML]="getTheErrorMessage(key, f[control.name].errors[key])"></p>
            </mat-error> -->
          </mat-form-field>

          <mat-form-field [ngClass]="{'required': isRequired(f[control.name])}" *ngIf="control.type === 'datepicker'">
            <input type="string" matInput [formControlName]="control.name" [matDatepicker]="picker"
              [placeholder]="control.placeholder" [min]="getAttribute(control, 'min')"
              [max]="getAttribute(control, 'max')" (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [startAt]="getAttribute(control, 'startAt')"></mat-datepicker>

            <mat-error *ngFor="let key of f[control.name].errors | objToKeys"
              [innerHTML]="getTheErrorMessage(key, f[control.name].errors[key])"></mat-error>
          </mat-form-field>

          <mat-form-field [ngClass]="{'required': isRequired(f[control.name])}" *ngIf="control.type === 'list'">
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let item of control.value" [selectable]="true" [removable]="true"
                (removed)="removeFromList(item, control)">
                {{item.name}}
                <mat-icon matChipRemove fontSet="fal" fontIcon="fa-times" *ngIf="true"></mat-icon>
              </mat-chip>
              <input [placeholder]="control.placeholder" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addToList($event, control)"
                [matChipInputAddOnBlur]="true">
            </mat-chip-list>
          </mat-form-field>
        </div>
      </ng-container>
    </div> <!-- /.row -->
    <div class="row">
      <div class="col-12">
        <mat-spinner-button role="submit" [options]="formSubmitBtnOptions" class="btn-elevate">
        </mat-spinner-button>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </form> <!-- /.form -->
</div>
