// Core packages
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators } from "@angular/forms";

// Third party packages
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { finalize, take } from "rxjs/operators";

// Custom packages
import { User } from "app/models/user.model";
import { UsersService } from "app/services/users.service";
import { HelperService } from "app/services/helper.service";
import { ThemeService } from "app/services/theme.service";
import { AuthService } from "app/services/auth.service";
import { Country } from "app/models/country.model";
import countriesData from "assets/json/countries.json";
import { DynamicForm } from "app/models/dynamic-form.model";
import { ConfirmDialogService } from "app/services/confirm-dialog.service";

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  loggedUser: User;
  userId: string;
  user: User;
  formSettings: DynamicForm;
  stopFormLoading = false;
  resetForm = false;
  countries: Country[] = countriesData;

  constructor(
    private themeService: ThemeService,
    private authService: AuthService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    public helperService: HelperService,
    private confirmDialogService: ConfirmDialogService
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Set page title
    this.themeService.setPageTitle("User detail");

    // Get logged user's data
    this.loggedUser = this.authService.loggedUser$.value;

    // Get the id of the requested user from the URL
    // const userId = this.activatedRoute.snapshot.params.userId;
    this.activatedRoute.params.subscribe((params) => {
      // console.log('params', params);
      if (params.userId) {
        this.userId = params.userId;
        this.getUserData();
      }
    });
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Get user data from back-end and init form
   *
   * @since 1.0.0
   */
  getUserData(): void {
    const userDataSubscription = this.usersService.get(this.userId).subscribe((res) => {
      if (res.status) {
        this.user = res.user;
        // console.log('user', this.user);

        // Now that we know requested user data
        // we can init forms
        this.initForm();
        // this.initPasswordForm();
      } else {
        // Something bad happened
        this.router.navigate(["admin", "users", "list"]);
      }
    });
    this.subscriptions.push(userDataSubscription);
  }

  /**
   * Initialize form
   *
   * @since 1.0.0
   */
  initForm(): void {
    const countriesOptions = this.countries.map((country) => {
      return {
        value: country.code,
        label: country.name,
      };
    });

    this.formSettings = {
      options: {
        formSubmitBtnOptions: {
          active: false,
          text: "Save changes",
          spinnerSize: 19,
          raised: true,
          stroked: true,
          flat: false,
          fab: false,
          buttonColor: "primary",
          spinnerColor: "primary",
          fullWidth: false,
          disabled: false,
          mode: "indeterminate",
        },
        title: "",
        description: "",
        formWrapperClasses: ["p-0"],
      },
      controls: [
        {
          type: "select",
          inputAttributes: {},
          name: "role",
          options: [
            {
              value: "admin",
              label: "Admin",
            },
            {
              value: "user",
              label: "User",
            },
          ],
          value: this.user.role,
          placeholder: "Choose role",
          label: "Role",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "email",
          },
          name: "email",
          value: this.user.email,
          placeholder: "Insert email",
          label: "Email",
          validators: [Validators.required, Validators.email],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "password",
          value: null,
          placeholder: "Insert password",
          label: "Password",
          validators: [Validators.minLength(6), Validators.pattern(/\d/)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "firstName",
          value: this.user.firstName,
          placeholder: "Insert first name",
          label: "First name",
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "lastName",
          value: this.user.lastName,
          placeholder: "Insert last name",
          label: "Last name",
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "datepicker",
          inputAttributes: {
            // startAt: new Date().getTime(),
            min: new Date(),
          },
          name: "expiresAt",
          value: new Date(this.user.expiresAt),
          placeholder: "Expires at",
          label: "Expires at",
          validators: [Validators.required, Validators.min(Date.now())],
          order: 1,
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "active",
          options: [
            {
              value: true,
              label: "Yes",
            },
            {
              value: false,
              label: "No",
            },
          ],
          value: this.user.active,
          placeholder: "Active",
          label: "Active",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyName",
          value: this.user.companyName,
          placeholder: "Insert company name",
          label: "Company name",
          validators: [Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "companyLegalAddressCountryCodeTwo",
          options: countriesOptions,
          value: this.user.companyLegalAddressCountryCodeTwo,
          placeholder: "Company address country",
          label: "Company address country",
          validators: [Validators.minLength(2), Validators.maxLength(2)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyLegalAddressZip",
          value: this.user.companyLegalAddressZip,
          placeholder: "Insert company address zip",
          label: "Company address zip",
          validators: [Validators.minLength(2), Validators.maxLength(10)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyLegalAddressCity",
          value: this.user.companyLegalAddressCity,
          placeholder: "Insert company address city",
          label: "Company address city",
          validators: [Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyLegalAddressLine1",
          value: this.user.companyLegalAddressLine1,
          placeholder: "Insert company address street and number",
          label: "Company address street and number",
          validators: [Validators.minLength(2), Validators.maxLength(50)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyTaxCode",
          value: this.user.companyTaxCode,
          placeholder: "Insert company tax code",
          label: "Company tax code",
          validators: [Validators.minLength(10), Validators.maxLength(13)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companyVatNumber",
          value: this.user.companyVatNumber,
          placeholder: "Insert company VAT number",
          label: "Company VAT number",
          validators: [Validators.minLength(10), Validators.maxLength(13)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "email",
          },
          name: "companyPec",
          value: this.user.companyPec,
          placeholder: "Insert company PEC",
          label: "Company PEC",
          validators: [Validators.email],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "input",
          inputAttributes: {
            type: "string",
          },
          name: "companySdi",
          value: this.user.companySdi,
          placeholder: "Insert company SDI code",
          label: "Company SDI code",
          validators: [Validators.minLength(5), Validators.maxLength(10)],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "list",
          inputAttributes: {},
          name: "categories",
          value: this.user.categories.map((category) => {
            return {
              name: category,
            };
          }),
          placeholder: "Insert categories and press ENTER",
          label: "Process categories",
          validators: [],
          extraClasses: [],
          parentDivClasses: ["col-12"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "maxDamage",
          options: [
            {
              value: 10000000000,
              label: "10e9",
            },
            {
              value: 1000000000,
              label: "10e8",
            },
            {
              value: 100000000,
              label: "10e7",
            },
            {
              value: 10000000,
              label: "10e6",
            },
            {
              value: 1000000,
              label: "10e5",
            },
            {
              value: 100000,
              label: "10e4",
            },
            {
              value: 10000,
              label: "10e3",
            },
            {
              value: 1000,
              label: "10e2",
            },
          ],
          value: this.user.maxDamage,
          placeholder: "Insert max damage for risk matrix",
          label: "Max damage",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "maxChance",
          options: [
            {
              value: 1e-3,
              label: "1e-3",
            },
            {
              value: 1e-4,
              label: "1e-4",
            },
            {
              value: 1e-5,
              label: "1e-5",
            },
            {
              value: 1e-6,
              label: "1e-6",
            },
            {
              value: 1e-7,
              label: "1e-7",
            },
            {
              value: 1e-8,
              label: "1e-8",
            },
            {
              value: 1e-9,
              label: "1e-9",
            },
            {
              value: 1e-10,
              label: "1e-10",
            },
          ],
          value: this.user.maxChance,
          placeholder: "Insert max likelihood for risk matrix",
          label: "Max likelihood",
          validators: [],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
        {
          type: "select",
          inputAttributes: {},
          name: "chanceFilter",
          options: [
            {
              value: "",
              label: "None",
            },
            {
              value: 1e-3,
              label: "1e-3",
            },
            {
              value: 1e-4,
              label: "1e-4",
            },
            {
              value: 1e-5,
              label: "1e-5",
            },
            {
              value: 1e-6,
              label: "1e-6",
            },
            {
              value: 1e-7,
              label: "1e-7",
            },
            {
              value: 1e-8,
              label: "1e-8",
            },
            {
              value: 1e-9,
              label: "1e-9",
            },
            {
              value: 1e-10,
              label: "1e-10",
            },
            {
              value: 1e-11,
              label: "1e-11",
            },
            {
              value: 1e-12,
              label: "1e-12",
            },
          ],
          value: this.user.chanceFilter,
          placeholder: "Insert chance filter for analysis",
          label: "Default min chance",
          validators: [Validators.required],
          extraClasses: [],
          parentDivClasses: ["col-12", "col-md-6"],
          groupIndex: 0,
        },
      ],
    };
  }

  /**
   * Handle form submit and send data to the back-end
   *
   * @since 1.0.0
   */
  onFormSubmit(form: any): void {
    const data = form.value;

    const userUpdateSubscription = this.usersService
      .update(this.user.id, data)
      .pipe(finalize(() => (this.stopFormLoading = !this.stopFormLoading)))
      .subscribe(
        (res) => {
          if (res.status) {
            this.toastrService.success(res.message);

            // Get new user data to update authService
            if (this.loggedUser.id === this.user.id) {
              this.usersService
                .get(this.user.id)
                .pipe(take(1))
                .subscribe((res2) => {
                  if (res.status) {
                    this.authService.updateCurrentLoggedUser(res2.user);
                  }
                });
              return;
            }
          }
        },
        (err) => {
          // Choose one of the following error handling
          // method. The first one show a message right
          // under the form fields (if the form is properly
          // setted), the second one show toastr
          // notifications for each error
          this.helperService.handleFormError(form, err);
          // this.helperService.handleError(err);
        }
      );
    this.subscriptions.push(userUpdateSubscription);
  }

  /**
   * Ask confirmation to delete selected user
   *
   * @since 1.0.0
   */
  onDelete(): void {
    const title = "Warning!";
    const message = "Are you sure? Proceeding you will permanently delete this user";
    const confirmDialogSubscription = this.confirmDialogService.confirm(title, message).subscribe((res) => {
      if (res) {
        this.deleteUser();
      }
    });
    this.subscriptions.push(confirmDialogSubscription);
  }

  /**
   * Delete current user from back-end
   *
   * @since 1.0.0
   */
  deleteUser(): void {
    this.subscriptions.push(
      this.usersService.delete(this.user.id).subscribe(
        (res) => {
          if (res.status) {
            this.toastrService.success("User deleted successfully! Redirecting...");
            setTimeout(() => {
              this.router.navigate(["admin", "users", "list"]);
            }, 500);
          }
        },
        (err) => {}
      )
    );
  }
}

/**
 * A custom validator to check if two fields match
 *
 * @since 1.0.0
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
