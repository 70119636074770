// Core packages
import {
  Injectable,
  ElementRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  Injector,
  ComponentRef,
  ComponentFactory,
} from "@angular/core";

// Third party packages
import { BehaviorSubject, Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";

// Custom packages
import { CONFIG } from "app/config/config";
import { QuestionNodeComponent } from "app/components/shared/chart-nodes/question-node/question-node.component";
import { AnswerNodeComponent } from "app/components/shared/chart-nodes/answer-node/answer-node.component";
import { QuestionNodeInterface } from "app/interfaces/question-node-interface";
import { AnswerNodeInterface } from "app/interfaces/answer-node-interface";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class NodeHelperService {
  private subscriptions: Subscription[] = [];
  rootViewContainer: ViewContainerRef;
  pageReference: ElementRef;
  chartReference: ElementRef;
  chartInstance$: BehaviorSubject<any> = new BehaviorSubject(null);
  nodes$: BehaviorSubject<any[]> = new BehaviorSubject([]); // Array of valid nodes
  tempNodes$: BehaviorSubject<any[]> = new BehaviorSubject([]); // Array of valid nodes plus invalid nodes;
  nodeWidth = 350; // Default node width in px
  nodeHeight = 53; // Default node height in px
  xGutter = 40; // in px
  yGutter = 20; // in px;
  linkModeIsActive$: BehaviorSubject<{
    from: number;
    to: number;
  }> = new BehaviorSubject({
    from: null,
    to: null,
  }); // Used to control if linkMode is active or not

  nodeIndex = 1; // First node will have 1 as ID

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {}

  /**
   * Set nodeIndex as given value
   *
   * @since 1.0.0
   */
  setNodeIndex(value: number): void {
    this.nodeIndex = value;
  }

  /**
   * Set root view container as given viewContainer reference
   *
   * @since 1.0.0
   */
  setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.rootViewContainer = viewContainerRef;
  }

  /**
   * Add question node
   *
   * @since 1.0.0
   */
  createQuestionNode(parentNodeData?: AnswerNodeInterface, nodeData?: QuestionNodeInterface): void {
    const factory: ComponentFactory<QuestionNodeComponent> = this.factoryResolver.resolveComponentFactory(
      QuestionNodeComponent
    );

    let newX = 0;
    let newY = 0;
    if (parentNodeData) {
      newX += parentNodeData.x + this.nodeWidth + this.xGutter;
      newY += parentNodeData.y;
    }

    if (!nodeData) {
      nodeData = {
        type: "question",
        id: this.nodeIndex,
        text: "",
        answersNumber: 1,
        x: newX,
        y: newY,
        creation: {
          authorFirstName: this.authService.loggedUser$.value.firstName,
          authorLastName: this.authService.loggedUser$.value.lastName,
          authorEmail: this.authService.loggedUser$.value.email,
          date: new Date(),
        },
        updates: [],
      };
    }

    const injector: Injector = Injector.create({
      providers: [
        {
          provide: "config",
          useValue: {
            parentNode: parentNodeData,
            ...nodeData, // Object with config data
          },
        },
      ],
    });

    // Create & Insert component inside DOM
    const componentReference: ComponentRef<QuestionNodeComponent> = this.rootViewContainer.createComponent(
      factory,
      0,
      injector
    );

    // Increment current nodeIndex
    this.nodeIndex = this.nodeIndex + 1000;

    // Subscribe to the event which is emitted once data is valid and every time data is updated
    const newQuestionNodeDataSubscription = componentReference.instance.data$.subscribe((res) => {
      // console.log('resQ', res);
      // Update temp nodes
      this.addOrUpdateTempNode(res, componentReference);

      // Update nodes only if data is valid
      if (this.isValidNode(res)) {
        componentReference.instance.setValidStatus();
        this.addOrUpdateNode(res, componentReference);
      }

      // Build connection lines for nodes linked to this one (anchestors')
      if (res.linkedToIds && res.linkedToIds.length) {
        // console.log(this.tempNodes$.value);
        res.linkedToIds.forEach((id) => {
          // console.log('id', id);
          // console.log('id', res.linkedToIds[id]);
          const linkedNode = this.tempNodes$.value.find((obj) => obj.node.id === id);
          // console.log('linkedNode', linkedNode);
          if (linkedNode) {
            linkedNode.ref.instance.createLinkToLine({ node: componentReference.instance.getData() });
          }
        });
      }
    });
    this.subscriptions.push(newQuestionNodeDataSubscription);

    // Subscribe to the needs of create a new answer-node which is child of given question-node
    const newAnswerNodeRequestedSubscription = componentReference.instance.createChildAnswerNode$.subscribe((res) => {
      if (res && res.id) {
        this.createAnswerNode(res);
      }
    });
    this.subscriptions.push(newAnswerNodeRequestedSubscription);

    // Subscribe to destroy event and destroy component
    const removeNodeSubscription = componentReference.instance.removeNode$.subscribe((res) => {
      if (res && res.id) {
        this.removeNode(res, componentReference);
      }
    });
    this.subscriptions.push(removeNodeSubscription);

    // Subscribe to destroy event and destroy component
    const nodeMovedSubscription = componentReference.instance.moved$.subscribe((res) => {
      if (res) {
        this.onNodeDragged(res, componentReference);
      }
    });
    this.subscriptions.push(nodeMovedSubscription);

    // Subscribe to "click" event on the node
    const nodeClickedSubscription = componentReference.instance.clicked$.subscribe(async (res) => {
      // If "from Node" is not defined, block script
      if (!this.linkModeIsActive$.value.from) {
        return;
      }

      // Set "fromNode"
      const fromNode = this.tempNodes$.value.find((obj) => obj.node.id === this.linkModeIsActive$.value.from);

      // Check if clicked node (node to) is not a parent node
      // of the "node from". If so, just block the script
      // because you cannot link a node to a parent
      if (!fromNode) {
        return;
      }

      const availableAnchestors = this.tempNodes$.value.map((obj) => obj.node);
      const anchestors = await this.getAnchestors(fromNode.node, availableAnchestors, [], []);
      let fromNodeInAnchestors = false;
      anchestors.forEach((anchestor) => {
        // console.log('anchestor.id', anchestor.id);
        // console.log('componentReference.instance.id', componentReference.instance.id);
        if (anchestor.id === componentReference.instance.id) {
          fromNodeInAnchestors = true;
        }
      });
      // const fromNodeInAnchestor = anchestors.some(node => node.id === fromNode.node.parentId);
      if (fromNodeInAnchestors) {
        this.toastrService.error("Cannot link to a parent node", "Error");
        return;
      }
      // console.log('componentReference.instance.id', componentReference.instance.id);
      // console.log('fromNode', fromNode.node);
      // console.log('availableAnchestors', availableAnchestors);
      // console.log('anchestors', anchestors);
      // console.log('fromNodeInAnchestors', fromNodeInAnchestors);

      // Emit a new value for "linkModeIsActive$"
      // With "node from" and "node to"
      this.linkModeIsActive$.next({
        from: this.linkModeIsActive$.value.from,
        to: componentReference.instance.id,
      });

      // Set "linkTo" in the "from" node
      fromNode.node.linkTo = componentReference.instance.id;
      fromNode.ref.instance.linkTo = componentReference.instance.id;

      // Add new id to "linkedToIds" in the "to" node
      if (componentReference.instance.linkedToIds) {
        let newLinkedToIds = [];
        if (componentReference.instance.linkedToIds.includes(fromNode.node.id)) {
          newLinkedToIds = componentReference.instance.linkedToIds.filter((id: number) => id !== fromNode.node.id);
        } else {
          newLinkedToIds = [...componentReference.instance.linkedToIds, fromNode.node.id];
        }
        componentReference.instance.linkedToIds = newLinkedToIds; // push(fromNode.node.id);
      } else {
        componentReference.instance.linkedToIds = [fromNode.node.id];
      }

      this.tempNodes$.value.forEach((obj) => {
        // Create linkTo connectionLine in the answer node
        // console.log('obj.node.id', obj.node.id);
        // console.log('this.linkModeIsActive$.value.to', this.linkModeIsActive$.value.to);
        if (obj.node.id === this.linkModeIsActive$.value.from) {
          // console.log('obj', obj);
          obj.ref.instance.linkTo = componentReference.instance.id;
          obj.node.linkTo = componentReference.instance.id;
          // const elRef = componentReference.instance.elementRef;
          const elRef = obj.ref.instance.elementRef;
          // console.log('elRef', elRef);
          componentReference.instance.connectionLineService.connectDivs(
            elRef,
            obj.node,
            componentReference.instance.getData(),
            "black",
            0.5,
            "linkTo"
          );
        }
        if (obj.node.id === this.linkModeIsActive$.value.to) {
          if (obj.ref.instance.linkedToIds.length && !obj.ref.instance.linkedToIds.includes(fromNode.id)) {
            const newLinkedToIds = [...obj.ref.instance.linkedToIds, fromNode.node.id].filter(
              (v, i, a) => a.indexOf(v) === i
            );
            obj.ref.instance.linkedToIds = newLinkedToIds; // .push(fromNode.node.id);
            obj.node.linkedToIds = newLinkedToIds; // .push(fromNode.node.id);
          } else {
            obj.ref.instance.linkedToIds = [fromNode.node.id];
            obj.node.linkedToIds = [fromNode.node.id];
          }
        }
      });

      // Reset $linkModeIsActive
      this.linkModeIsActive$.next({
        from: null,
        to: null,
      });
    });
    this.subscriptions.push(nodeClickedSubscription);
  }

  /**
   * Add answer node which is related to given parentNodeData and, if nodeData is
   * provided, than it's filled with given nodeData values
   *
   * NB: this method is able to create multiple answer node depending on given
   * parentNodeData answersNumber. If given answersNumber is greater than the existing
   * amount of parentNode's children, than we create as many answerNode as many are
   * needed to reach that amount.
   *
   * @since 1.0.0
   */
  createAnswerNode(parentNodeData: QuestionNodeInterface, nodeData?: AnswerNodeInterface): void {
    const factory: ComponentFactory<AnswerNodeComponent> = this.factoryResolver.resolveComponentFactory(
      AnswerNodeComponent
    );
    let newX = 0;
    let newY = 0;
    let effect = "P";
    const parentNodeChildren = this.tempNodes$.value.filter((obj) => obj.node.parentId === parentNodeData.id);
    let answersToCreate = parentNodeData.answersNumber;

    // If parent node has children and we are not importing a node
    // (so it's a node of which we still have to calculate the coordinates)
    // Than calculate the appropriate Y
    if (parentNodeChildren.length && !nodeData) {
      // Only the first answer should be positive by default
      effect = "N";

      // console.log('ci casco', parentNodeChildren);
      // Prevent numbers lower than the existing children
      // NB: would be nice to reset the previous value
      if (parentNodeData.answersNumber < parentNodeChildren.length) {
        const title = "Warning!";
        const message = "You cannot insert a number lower than existing children number";
        this.toastrService.warning(message, title);
        return;
      }

      // Reduce the amount of the nodes to create
      answersToCreate = parentNodeData.answersNumber - parentNodeChildren.length;

      // Get the max Y that a parentNode.child has, and set it as the next target Y starting point
      const lastChildY = Math.max.apply(
        Math,
        parentNodeChildren.map((obj) => obj.node.y)
      );
      newY += lastChildY;
    } else if (!parentNodeChildren.length && !nodeData) {
      newY = parentNodeData.y;
    } else {
      newY -= 40;
    }

    Array.from(Array(answersToCreate)).forEach((val, index) => {
      // Adjust created answerNode coordinates as parentNodeData coordinates
      // plus gutters (see xGutter & yGutter)
      if (index === 0) {
        newX += parentNodeData.x + this.nodeWidth + this.xGutter;
        newY += this.nodeHeight + this.yGutter;
      } else {
        newY += this.nodeHeight + this.yGutter;
      }

      // Create injecto for the component (used to pass config data)
      const injector: Injector = Injector.create({
        providers: [
          {
            provide: "config",
            useValue: {
              parentNode: parentNodeData,
              id: this.nodeIndex,
              x: newX,
              y: newY,
              effect,
            }, // Object with config data
          },
        ],
      });

      // Create & Insert component inside DOM
      const componentReference: ComponentRef<AnswerNodeComponent> = this.rootViewContainer.createComponent(
        factory,
        0,
        injector
      );

      // Increment nodeIndex
      this.nodeIndex += 1000;

      // Subscribe to events
      this.subscribeToAnswerNodeEvents(componentReference);
    });
  }

  /**
   * Import answer node
   *
   * @since 1.0.0
   */
  importAnswerNode(parentNodeData?: QuestionNodeInterface, nodeData?: AnswerNodeInterface): void {
    const factory: ComponentFactory<AnswerNodeComponent> = this.factoryResolver.resolveComponentFactory(
      AnswerNodeComponent
    );

    // Create injecto for the component (used to pass config data)
    const injector: Injector = Injector.create({
      providers: [
        {
          provide: "config",
          useValue: {
            parentNode: parentNodeData,
            ...nodeData, // Object with config data
          },
        },
      ],
    });

    // Create & Insert component inside DOM
    const componentReference: ComponentRef<AnswerNodeComponent> = this.rootViewContainer.createComponent(
      factory,
      0,
      injector
    );

    // Increment nodeIndex
    this.nodeIndex += 1000;

    // Subscribe to events
    this.subscribeToAnswerNodeEvents(componentReference);
  }

  /**
   * Subscribe to all events that the answer node can emits
   *
   * @since 1.0.0
   */
  subscribeToAnswerNodeEvents(componentReference: ComponentRef<AnswerNodeComponent>): void {
    // Subscribe to the event which is fired once data is valid and every time there is an update
    const newAnswerNodeDataSubscription = componentReference.instance.data$.subscribe((res) => {
      // console.log('res', res);
      const oldNodeValues = this.tempNodes$.value.find((obj) => obj.node.id === res.id);
      if (oldNodeValues) {
        // this will be false if we are importing some nodes
        if (res.chance !== oldNodeValues.node.chance) {
          // Check if given chance, summed with siblings nodes chances is not greater than 1
          const siblings = this.getSiblings(res, this.tempNodes$.value);
          const subilngsChances = siblings.map((obj) => obj.node.chance);

          // It it has no siblings, we don't need to check the chances sum
          if (subilngsChances.length) {
            const siblingsChancesSum = subilngsChances.reduce((a, b) => a + b);
            if (siblingsChancesSum + res.chance > 1) {
              const title = "Warning";
              const message = "Likelihoods' sum cannot be more than 1";
              this.toastrService.error(message, title);
              componentReference.instance.chance = 1 - siblingsChancesSum;
              return;
            }
          }
        }
      }

      // Update temp nodes
      this.addOrUpdateTempNode(res, componentReference);

      // Update nodes only if data is valid
      if (this.isValidNode(res)) {
        componentReference.instance.setValidStatus();
        this.addOrUpdateNode(res, componentReference);
      }

      // // If node has a linkTo, than create the line
      // if (res.linkTo) {

      //   const linkToObj = this.tempNodes$.value.find(obj => obj.node.id === res.linkTo);
      //   console.log('nodes', this.tempNodes$.value);
      //   componentReference.instance.createLinkToLine(linkToObj);
      // }
    });
    this.subscriptions.push(newAnswerNodeDataSubscription);

    // Subscribe to destroy event and destroy component
    const removeNodeSubscription = componentReference.instance.removeNode$.subscribe((res) => {
      if (res) {
        this.removeNode(res, componentReference);
      }
    });
    this.subscriptions.push(removeNodeSubscription);

    // Subscribe to add-child event
    const addChildSubscription = componentReference.instance.addChild$.subscribe((res) => {
      if (res && res.id) {
        const descendends = this.getDescendents(res.id, this.tempNodes$.value, []);
        if (descendends.length > 0) {
          const title = "Warning";
          const message = "You cannot add more than 1 child question";
          this.toastrService.error(message, title);
          return;
        }
        this.createQuestionNode(res);
      }
    });
    this.subscriptions.push(addChildSubscription);

    // Subscribe to drag ended event
    const dragEndedEventSubscription = componentReference.instance.moved$.subscribe((res) => {
      if (res) {
        this.onNodeDragged(res, componentReference);
      }
    });
    this.subscriptions.push(dragEndedEventSubscription);

    // Subscribe to linkMode init event
    const linkToSubscription = componentReference.instance.linkTo$.subscribe((res) => {
      console.log("RES", res);
      // console.log('this.linkModeIsActive$.value', this.linkModeIsActive$.value);
      if (res && this.linkModeIsActive$.value.from) {
        // console.log('LinkMode already active. Deactivate it');
        this.linkModeIsActive$.next({
          from: null,
          to: null,
        });
        return;
      } else if (res && !this.linkModeIsActive$.value.from) {
        // Prevent linkToMode activation if current node
        // has a child question
        const descendends = this.getDescendents(componentReference.instance.id, this.tempNodes$.value, []);
        if (descendends && descendends.length > 0) {
          // console.warn('Impossibile attivare linkTo su un nodo con figli');
          return;
        }

        this.linkModeIsActive$.next({
          from: componentReference.instance.id,
          to: null,
        });
        // console.log('Attivo linkTo from', componentReference.instance.id);
        return;
      } else if (!res && this.linkModeIsActive$.value.from) {
        // console.log('Disattivo linkTo from ', componentReference.instance.id);
        this.linkModeIsActive$.next({
          from: null,
          to: null,
        });
      }
    });
    this.subscriptions.push(linkToSubscription);

    // Subscribe to removal of linkTo
    // This is used to remove "linkTo" reference also in the other node (othernode.linkToIds)
    const linkToRemoverSubscription = componentReference.instance.linkToRemover$.subscribe((nodeId: number) => {
      console.log("nodeId", nodeId);
      if (!nodeId) {
        return;
      }

      const otherNode = this.nodes$.value.find((obj) => obj.node.id === nodeId);
      (otherNode.ref as ComponentRef<QuestionNodeComponent>).instance.linkedToIds = (otherNode.ref as ComponentRef<QuestionNodeComponent>).instance.linkedToIds.filter(
        (id: number) => id !== componentReference.instance.id
      );
      (otherNode.node as QuestionNodeInterface).linkedToIds = (otherNode.node as QuestionNodeInterface).linkedToIds.filter(
        (id: number) => id !== componentReference.instance.id
      );
      console.log("otherNode", otherNode);
    });
    this.subscriptions.push(linkToRemoverSubscription);

    // If current component has a linkTo, than subscribe to
    // each tempNodes$ changes until we found a node with the
    // id used as "linkTo". Than we build a connection line
    // between current node and that node.
    if (componentReference.instance.linkTo) {
      const tempNodesSubscribe = this.tempNodes$.pipe(take(1)).subscribe((res) => {
        // console.log('componentReference.instance.linkTo', componentReference.instance.linkTo);
        const linkedNodeData = this.tempNodes$.value.find((obj) => obj.node.id === componentReference.instance.linkTo);
        if (linkedNodeData) {
          componentReference.instance.createLinkToLine(linkedNodeData);
          // tempNodesSubscribe.unsubscribe();
        }
      });
      this.subscriptions.push(tempNodesSubscribe);
    }
  }

  /**
   * Once dragged event ended upon a node, apply the delta coordinates
   * to every children node
   *
   * @since 1.0.0
   */
  onNodeDragged(
    coordinatesDelta: any,
    componentReference: ComponentRef<QuestionNodeComponent> | ComponentRef<AnswerNodeComponent>
  ): void {
    const draggedNodeId = componentReference.instance.id;
    // console.log('tempNodes', this.tempNodes$.value);

    // Get all descendent nodes
    const descendents = this.getDescendents(draggedNodeId, this.tempNodes$.value, []);

    // Update their coordinates
    descendents.forEach((node) => {
      // console.log('descendent', node);
      node.ref.instance.editCoordinates(coordinatesDelta.x, coordinatesDelta.y);
    });

    // Update
    // If this node has a parentNode, than we must also update the connection
    // line between this two nodes. But since distances has been changed, we don't
    // just need to "move" that line, instead we must rebuild it
    if (!componentReference.instance.parentNode) {
      return;
    }

    // We have to take new values for parentNode since it can has been moved
    const parentNodeId = componentReference.instance.parentNode.id;
    const parentNodeObj = this.tempNodes$.value.find((obj) => obj.node.id === parentNodeId);
    if (!parentNodeObj) {
      return;
    }
    const parentNode = parentNodeObj.node;

    // Update curved line (rebuild his path)
    let x1 = parentNode.x + 350 + 13;
    let y1 = 2000 + parentNode.y + 60;
    let x2 = componentReference.instance.x + coordinatesDelta.x + 5;
    let y2 = 2000 + componentReference.instance.y + coordinatesDelta.y + 60;

    // @warning if remove this than please uncomment the following drawCircleline
    x1 -= 5;
    y1 -= 0;
    x2 += 5;
    y2 += 2;

    const delta = (Math.abs(x2) - x1) * CONFIG.chart.connectionLineTension;
    const hx1 = x1 + delta;
    const hy1 = y1;
    const hx2 = x2 - delta;
    const hy2 = y2;
    const path = `M ${x1} ${y1} C ${hx1} ${hy1} ${hx2} ${hy2} ${x2} ${y2}`;
    const svgs = componentReference.location.nativeElement.querySelectorAll("svg"); // Find all svg
    // console.log('svgs', svgs);
    Object.keys(svgs).forEach((key) => {
      const svg = svgs[key];
      const element = svg.children[0];
      // console.log(element)
      // Uncomment this if circles are enabled
      // // Update right dot with new coordinates
      // if (element.nodeName === 'circle' && key === '1') {
      //   componentReference.instance.setSVGsNewCoordinates(x2, y2);
      // }

      // Update curved line (used to link to direct parentNode)
      if (element.nodeName === "path" && !svg.className.baseVal.includes("linkTo")) {
        componentReference.instance.setSVGsNewCoordinates(null, null, path, ":not(.linkTo)");
      }

      // Update curved line (used to link to linkTo parentNode)
      if (
        element.nodeName === "path" &&
        !svg.className.baseVal.includes("linkTo") &&
        componentReference.instance.hasOwnProperty("linkTo")
      ) {
        // console.log('DOVREI AGGIORNARE', componentReference);

        // BEGIN - Build path for linkedTo line
        // tslint:disable:no-string-literal
        const linkToId = componentReference.instance["linkTo"];
        // tslint:enable:no-string-literal
        const linkToNode = this.tempNodes$.value.find((obj) => obj.node.id === linkToId);
        if (linkToNode) {
          let newX1 = componentReference.instance.x + 350 + 13 + coordinatesDelta.x;
          let newY1 = 2000 + componentReference.instance.y + 60 + coordinatesDelta.y;
          let newX2 = linkToNode.node.x + 5;
          let newY2 = 2000 + linkToNode.node.y + 60;

          // @warning if remove this than please uncomment the following drawCircleline
          newX1 -= 5;
          newY1 -= 0;
          newX2 += 5;
          newY2 += 2;

          const newDelta = (Math.abs(newX2) - newX1) * CONFIG.chart.connectionLineTension;
          const newHx1 = newX1 + newDelta;
          const newHy1 = newY1;
          const newHx2 = newX2 - newDelta;
          const newHy2 = newY2;
          const newPpath = `M ${newX1} ${newY1} C ${newHx1} ${newHy1} ${newHx2} ${newHy2} ${newX2} ${newY2}`;
          // END  - Build path for linkedTo line
          componentReference.instance.setSVGsNewCoordinates(null, null, newPpath, ".linkTo");
        }
      }
    });

    // If this node has one or more parent node linked with a connection line
    // Than we have to update that connection line's path
    const connectedParentNodes = this.tempNodes$.value.filter((obj) => obj.node.linkTo === draggedNodeId);
    // console.log('connectedParentNodes', connectedParentNodes);
    for (const connectedParentNode of connectedParentNodes) {
      // Update curved line (rebuild his path)
      let parentX1 = connectedParentNode.node.x + 350 + 13;
      let parentY1 = 2000 + connectedParentNode.node.y + 60;
      let parentX2 = componentReference.instance.x + coordinatesDelta.x + 5;
      let parentY2 = 2000 + componentReference.instance.y + coordinatesDelta.y + 60;

      // @warning if remove this than please uncomment the following drawCircleline
      parentX1 -= 5;
      parentY1 -= 0;
      parentX2 += 5;
      parentY2 += 2;

      const parentDelta = (Math.abs(parentX2) - parentX1) * CONFIG.chart.connectionLineTension;
      const parentHx1 = parentX1 + parentDelta;
      const parentHy1 = parentY1;
      const parentHx2 = parentX2 - parentDelta;
      const parentHy2 = parentY2;
      const parentPath = `M ${parentX1} ${parentY1} C ${parentHx1} ${parentHy1} ${parentHx2} ${parentHy2} ${parentX2} ${parentY2}`;
      const parentSvgs = connectedParentNode.ref.location.nativeElement.querySelectorAll("svg.linkTo"); // Find all svg
      Object.keys(parentSvgs).forEach((key) => {
        const svg = parentSvgs[key];
        // console.log('parent', connectedParentNode.node);
        // console.log('updateParentSvg', svg);
        const element = svg.children[0];
        // Uncomment this if circles are enabled
        // // Update right dot with new coordinates
        // if (element.nodeName === 'circle' && key === '1') {
        //   componentReference.instance.setSVGsNewCoordinates(x2, y2);
        // }

        // Update curved line
        if (element.nodeName === "path") {
          connectedParentNode.ref.instance.setSVGsNewCoordinates(null, null, parentPath, ".linkTo");
        }
      });
    }
  }

  /**
   * Get all descendents nodes of given node
   *
   * @since 1.0.0
   */
  getDescendents(nodeId: number, nodes: any[], descendends: any[]): any[] {
    const children = nodes.filter((obj) => obj.node.parentId === nodeId);
    // console.log('children', children);
    if (children.length) {
      descendends.push(...children);
      for (const child of children) {
        if (child.node.parentId) {
          descendends = this.getDescendents(child.node.id, nodes, descendends);
        }
      }
    }

    return descendends;
  }

  /**
   * Recursive function that is used to get all the anchestors
   * of the given node inside given nodes array
   *
   * @since 1.0.0
   */
  async getAnchestors(
    node: QuestionNodeInterface | AnswerNodeInterface,
    nodes: any[],
    anchestors: any[],
    children: any[]
  ) {
    const parents = this.getParents(node, nodes);
    // console.log('node', node);
    // console.log('parents', parents);
    if (parents) {
      for (const parent of parents) {
        // Prevent duplicate in anchestors
        if (!anchestors.find((anchestor) => anchestor.id === parent.node.id) && parent.type === "direct") {
          anchestors.push(parent.node);
        }
        if (parent.node.parentId && parent.type === "direct") {
          anchestors = await this.getAnchestors(parent.node, nodes, anchestors, [...children, node]);
        }
        if (parent.type === "linked") {
          // Find anchestors
          //                                                    IF REMOVED (anchestors)
          //                                                    AS 3th PARAMS THAN
          //                                                    USER WILL BE ABLE TO "LINK TO"
          //                                                    PARENT NODES!!!! (dont remove)
          anchestors = await this.getAnchestors(parent.node, nodes, anchestors, [...children, node]);
        }
      }
    }
    return anchestors;
  }

  /**
   * Get the parent node of the given node inside given nodes
   *
   * @since 1.0.0
   */
  getParents(node: QuestionNodeInterface | AnswerNodeInterface, nodes: any[]) {
    const parents = [];
    // Get direct parent node
    if (node.parentId) {
      const parentNode = nodes.find((obj) => obj.id === node.parentId);
      parents.push({
        type: "direct",
        node: parentNode,
      });
    }

    if (typeof (node as QuestionNodeInterface).linkedToIds !== "undefined") {
      for (const linkedToId of (node as QuestionNodeInterface).linkedToIds) {
        const linkedParentNode = nodes.find((obj) => obj.id === linkedToId);
        parents.push({
          type: "linked",
          node: linkedParentNode,
        });
      }
    }
    return parents;
  }

  /**
   * Chack if given node has all required data depending on node type
   *
   * @since 1.0.0
   */
  isValidNode(node: any): boolean {
    if (node.type === "question") {
      // Check has an ID
      if (!node.id) {
        return false;
      }

      // Check has a text
      if (node.text.length < 1) {
        return false;
      }

      // Check answersNumber
      if (
        node.answersNumber < 1 ||
        node.answersNumber > CONFIG.maxAnswers ||
        node.answersNumber.toString().trim() === ""
      ) {
        return false;
      }

      return true;
    } else if (node.type === "answer") {
      // Check has an ID
      if (!node.id) {
        return false;
      }

      // Check has a text
      if (node.text.length < 1) {
        return false;
      }

      // Chack chance
      if (node.change <= 0 || node.chance > 1) {
        return false;
      }

      return true;
    }
    return false;
  }

  /**
   * Unsubscribe from all active subscriptions from all nodes
   *
   * @since 1.0.0
   */
  destroyAllSubscriptions(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Add given node to nodes list. If given node already exists
   * in nodes list, than just update it
   *
   * @since 1.0.0
   */
  addOrUpdateNode(
    node: QuestionNodeInterface | AnswerNodeInterface,
    componentReference?: ComponentRef<QuestionNodeComponent> | ComponentRef<AnswerNodeComponent>
  ): void {
    if (!node.id) {
      return;
    }

    const nodeExists = this.nodes$.value.find((obj) => obj.node.id === node.id);
    let newNodesArray = [];

    if (!nodeExists) {
      newNodesArray = [
        ...this.nodes$.value,
        {
          node,
          ref: componentReference,
        },
      ];
    } else {
      newNodesArray = this.nodes$.value.map((obj) => {
        if (obj.node.id === node.id) {
          obj.node = node;
        }
        return obj;
      });
    }
    this.nodes$.next(newNodesArray);
    return;
  }

  /**
   * Add given node to temp nodes list. If given node already exists
   * in temp nodes list, than just update it
   *
   * @since 1.0.0
   */
  addOrUpdateTempNode(
    node: QuestionNodeInterface | AnswerNodeInterface,
    componentReference?: ComponentRef<QuestionNodeComponent> | ComponentRef<AnswerNodeComponent>
  ): void {
    if (!node.id) {
      return;
    }

    const nodeExists = this.tempNodes$.value.find((obj) => obj.node.id === node.id);

    let newTempNodesArray = [];
    if (!nodeExists) {
      newTempNodesArray = [
        ...this.tempNodes$.value,
        {
          node,
          ref: componentReference,
        },
      ];
    } else {
      newTempNodesArray = this.tempNodes$.value.map((obj) => {
        if (obj.node.id === node.id) {
          obj.node = node;
        }
        return obj;
      });
    }
    this.tempNodes$.next(newTempNodesArray);
    return;
  }

  /**
   * Remove given node from nodes array and decrement nodes counter
   *
   * @since 1.0.0
   */
  removeNode(
    node: QuestionNodeInterface | AnswerNodeInterface,
    componentReference: ComponentRef<QuestionNodeComponent> | ComponentRef<AnswerNodeComponent>
  ): void {
    // NB: would be nice to make all sibiling node realign after a sibiling has been removed
    console.log("rimuovo nodo", node);

    if (node.type === "question") {
      // Remove all reference (connections/linkToIds/...) of this node in other nodes
      this.nodes$.value.forEach((obj) => {
        (obj.ref as ComponentRef<QuestionNodeComponent | AnswerNodeComponent>).instance.removeSvgByNodeId(node.id);
        (obj.ref as ComponentRef<AnswerNodeComponent>).instance.linkTo = null;
        (obj.node as AnswerNodeInterface).linkTo = null;
      });
    }

    if (node.type === "answer") {
      // Prevent deletion of "the last child of a question node"
      const parentDescendends = this.getDescendents(node.parentId, this.tempNodes$.value, []);
      if (parentDescendends.length < 2) {
        const title = "Warning";
        const message = "You cannot remove the last child element of a question element";
        this.toastrService.error(message, title);
        return;
      }

      // If deleted child has descendents and the first child has a linked to
      // than move the parent-child relation to the "linkedTo" element
      // This is used to prevent deletion of all descendents
      const children = this.nodes$.value.filter((obj) => obj.node.parentId === node.id);
      console.log("children", children);
      if (children.length > 0) {
        const childQuestioNode = children[0];
        console.log("childQuestioNode", childQuestioNode);

        // Remove child "blue svg" line (the one that link with tha actual parent node)
        (childQuestioNode.ref as ComponentRef<QuestionNodeComponent>).instance.removeSvgByNodeId(
          node.id,
          "data-node-from"
        );

        // Remove "firstParentLinkedNode" black line
        if (childQuestioNode?.node?.linkedToIds?.length > 0) {
          const firstParentLinkedNodeId = childQuestioNode.node.linkedToIds[0];
          // Update parentNode id
          childQuestioNode.node.parentId = firstParentLinkedNodeId;
          childQuestioNode.node.linkedToIds = childQuestioNode.node.linkedToIds.filter(
            (id: number) => id !== firstParentLinkedNodeId
          );

          // Update firstParentLinkedNode
          const firstParentLinkedNode = this.nodes$.value.find((obj) => obj.node.id === firstParentLinkedNodeId);
          console.log("firstParentLinkedNode", firstParentLinkedNode);
          (firstParentLinkedNode.ref as ComponentRef<AnswerNodeComponent>).instance.linkTo = 0;
          (firstParentLinkedNode.node as AnswerNodeInterface).linkTo = null;
          (firstParentLinkedNode.ref as ComponentRef<AnswerNodeComponent>).instance.linkTo$.next(false);
          (firstParentLinkedNode.ref as ComponentRef<AnswerNodeComponent>).instance.removeSvgByNodeId(
            childQuestioNode.node.id
          );

          // Recreate SVG from ${firstParentLinkedNode} to ${childQuestionNode}
          (childQuestioNode.ref as ComponentRef<QuestionNodeComponent>).instance.createSVGLine(
            (firstParentLinkedNode.ref as ComponentRef<AnswerNodeComponent>).instance.getData(),
            (childQuestioNode.ref as ComponentRef<QuestionNodeComponent>).instance.getData()
          );
        }
      }

      // If removed node is an answer-node, than
      // remove all reference to this node from all questionNode
      this.tempNodes$.value
        .filter((obj) => obj.node.type === "question")
        .map((obj) => {
          console.log("obj", obj);

          if (obj.node.linkedToIds && obj.node.linkedToIds.includes(obj.id)) {
            // WOAH! We need to remove this node from the array of linkedToIds
            const newLinkedToIds = obj.node.linkedToIds.filter((el) => el !== node.id);
            obj.node.linkedToIds = newLinkedToIds;
            // (obj.ref as ComponentRef<QuestionNodeComponent>).instance.
          }
          // Remove 1 from answersNumber of parent node
          if (obj.node.id === node.parentId) {
            // obj.node.answersNumber = +obj.node.answersNumber - 1;
            // (obj.ref as ComponentRef<QuestionNodeComponent>).instance.answersNumber = +obj.node.answersNumber - 1;
          }
        });

      this.nodes$.value
        .filter((obj) => obj.node.type === "question")
        .map((obj) => {
          if (obj.node.linkedToIds && obj.node.linkedToIds.includes(node.id)) {
            // WOAH! We need to remove this node from the array of linkedToIds
            const newLinkedToIds = obj.node.linkedToIds.filter((el) => el !== node.id);
            obj.node.linkedToIds = newLinkedToIds;
          }
          // Remove 1 from answersNumber of parent node
          if (obj.node.id === node.parentId) {
            obj.node.answersNumber--;
            (obj.ref as ComponentRef<QuestionNodeComponent>).instance.answersNumber--;
          }
        });

      /**
       * Se il nodo ANSWER ha un nodo child (se ce l'ha ne può avere uno solo)
       * che ha una linea nera in ingresso (un linkedIds), allora
       * devo prendere quell
       */
    }

    componentReference.destroy();

    // Delete from tempNodes
    // Get all tempDescendents of given node
    const tempDescendents = this.getDescendents(node.id, this.tempNodes$.value, []);

    // Remove all tempDescendent from tempNodes
    const newTempNodesArray = this.tempNodes$.value.filter(
      (obj) => !tempDescendents.includes(obj) && obj.node.id !== node.id
    );
    this.tempNodes$.next(newTempNodesArray);

    // Delete all temp descendends
    tempDescendents.forEach((tempNode) => {
      tempNode.ref.destroy();
    });

    // Delete the node itself
    const nodeInTempNodes = this.tempNodes$.value.find((obj) => obj.node.id === node.id);
    if (nodeInTempNodes) {
      nodeInTempNodes.ref.destroy();
    }

    // Delete from nodes$
    // Get all descendents of given node
    const descendents = this.getDescendents(node.id, this.nodes$.value, []);

    // Remove all descendent from nodes
    const tempNodesArray = this.nodes$.value.filter((obj) => !descendents.includes(obj) && obj.node.id !== node.id);
    this.nodes$.next(tempNodesArray);

    // Delete all descendends
    descendents.forEach((obj) => {
      obj.ref.destroy();
    });

    // Delete the node itself
    const nodeInNodes = this.nodes$.value.find((obj) => obj.node.id === node.id);
    if (nodeInNodes) {
      nodeInNodes.ref.destroy();
    }

    // If tempNodes are empty, than reset nodeIndes
    if (this.tempNodes$.value.length === 0) {
      this.nodeIndex = 1;
    }
  }

  /**
   * Destroy all nodes and remove them from the DOM
   *
   * @since 1.0.0
   */
  destroyAllNodes(): void {
    this.nodeIndex = 1; // Reset nodeIndex
    this.destroyAllSubscriptions(); // Unlisten all subscription
    this.tempNodes$.value.forEach((obj) => {
      // Destroy all tempNodes
      obj.ref.destroy();
    });
    this.nodes$.value.forEach((obj) => {
      // If some node is still present (it should not) than destroy it
      obj.ref.destroy();
    });

    this.tempNodes$.next([]); // Emit new empty tempNodes array
    this.nodes$.next([]); // Emit new empty nodes array
  }

  /**
   * Get all the siblings nodes of the given node
   *
   * @since 1.0.0
   */
  getSiblings(node: QuestionNodeInterface | AnswerNodeInterface, nodes: any[]): any[] {
    return nodes.filter((obj) => obj.node.parentId === node.parentId && obj.node.id !== node.id);
  }
}
