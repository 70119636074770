// Core packages
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';

// Third party packages
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

// Custom packages

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  /**
   * Class constructor
   */
  constructor(private toastrService: ToastrService) {}

  /**
   * Emit a toastr notification with given data or show the error message
   * right under the appropriate form field (if given)
   *
   * @since 1.0.0
   *
   * @param error Emitted error
   * @param ?type If we want to override the error data, here
   * we must provide a toastr notification type 'error' or 'success'
   * @param ?message Override error message
   * @param ?title  Override error title
   * @returns Observable<boolean>
   */
  handleError(error: any, type?: string, message?: string, title?: string): Observable<boolean> {
    if (typeof type !== 'undefined' && typeof message !== 'undefined') {
      if (type === 'error') {
        if (typeof title !== 'undefined') {
          this.toastrService.error(message, title);
          return of(false);
        }
        this.toastrService.error(message);
        return of(false);
      }
      if (type === 'success') {
        if (typeof title !== 'undefined') {
          this.toastrService.success(message, title);
          return of(false);
        }
        this.toastrService.success(message);
        return of(false);
      }
    }

    if (error.data) {
      Object.keys(error.data).forEach((key) => {
        this.toastrService.error(error.data[key].msg);
      });
      return of(false);
    }

    if (typeof error.error !== 'undefined' && error.error.data) {
      Object.keys(error.error.data).forEach((key) => {
        // this.toastrService.error(error.error.data[key].msg, `Error in ${error.error.data[key].param}`);
        this.toastrService.error(error.error.data[key].msg);
      });
      return of(false);
    }

    if (typeof error.error !== 'undefined' && error.error.message) {
      const msgTitle = 'Warning!';
      this.toastrService.error(error.error.message, msgTitle);
      return of(false);
    }

    if (error.message) {
      this.toastrService.error(error.message);
      return of(false);
    }

    // Generic error message
    const message2 = 'Unexpected server error. Please reload the page and try again in a few';
    const title2 = 'Warning!';
    this.toastrService.error(message2, title2);

    return of(false);
  }

  /**
   * Parse error coming from an httpRequest made
   * with { responseType: blob }
   *
   * @see https://stackoverflow.com/questions/48500822/how-to-handle-error-for-response-type-blob-in-httprequest
   *
   * @since 1.0.0
   *
   * @param err The error object
   * @returns Observable<any>
   */
  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  /**
   * Handle back-end errors showing them under the
   * appropriate form-control in given form instance
   *
   * @since 1.0.0
   *
   * @param form Form object
   * @param err Error object
   */
  handleFormError(form: FormGroup, err: any): void {
    // Check data is defined
    if (typeof err.error === 'undefined' || typeof err.error.data === 'undefined') {
      this.handleError(err); // Fall back to toastr
      return;
    }

    // Set custom back-end errors to the form
    Object.keys(err.error.data).forEach((key) => {
      if (form.controls[key]) {
        form.controls[key].setErrors({
          'back-end': err.error.data[key],
        });
      }
    });
  }

  /**
   * Handle invalid status of form controls setting
   * dirty and touched class to those form controls
   *
   * @since 1.0.0
   *
   * @param form form object
   * @returns undefined
   */
  handleFormInvalid(form: FormGroup): void {
    Object.keys(form.controls).forEach((controlKey) => {
      const control = form.controls[controlKey];
      if (control.status !== 'VALID') {
        form.controls[controlKey].markAsDirty();
        form.controls[controlKey].markAsTouched();
      }
    });
  }

  /**
   * Check if given value is in string type or not
   *
   * @since 1.0.0
   *
   * @param x String to check
   * @returns boolean
   */
  isString(x: any): boolean {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  /**
   * Check if given form control is required or not
   *
   * @since 1.0.0
   *
   * @param control form control to check
   * @returns boolean True if it's required, false otherwhise.
   */
  controlIsRequired(control: FormControl | AbstractControl): boolean {
    if (typeof control === 'undefined' || typeof control.validator !== 'function') {
      return false;
    }
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }

  /**
   * Generate an appropriate error message starting from given data
   *
   * @since 1.0.0
   */
  getErrorMessage(key: string, value: any): string {
    let message = '';
    switch (key) {
      case 'required':
        message = 'Field is required';
        break;
      case 'email':
        message = 'Invalid email address';
        break;
      case 'minlength':
        message = `String must have a minimum length of ${value.requiredLength}`;
        break;
      case 'maxlength':
        message = `String must have a maximum length of ${value.requiredLength}`;
        break;
      case 'back-end':
        message = value.msg;
        break;
      default:
        message = 'Check data and try again';
        break;
    }

    return message;
  }

  /**
   * Check if current Browser is Google Chrome
   *
   * @see https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
   *
   * @since 1.0.0
   */
  isGoogleChrome(): boolean {
    // please note,
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    // tslint:disable:no-string-literal
    const isChromium = window['chrome'];
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window['opr'] !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');
    // tslint:enable:no-string-literal

    if (isIOSChrome) {
      // is Google Chrome on IOS
      return true;
    } else if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      return true;
    }

    return false;
  }
}
