<main>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <h1>Privacy & Cookie Policy</h1>
        </div>
        <div class="col-12 col-md-8 offset-md-2">
          <h2>TITOLARE DEL TRATTAMENTO DEI DATI</h2>
          <p>Il titolare del trattamento dati è la società V Logic Srls, con PIVA 10930520969.</p>
          <p>Il titolare può essere contattato all'indirizzo email <a
              href="mailto:info.simurisk@vlogic.cloud">info.simurisk@vlogic.cloud</a></p>
          <h3>TIPOLOGIE DI DATI RACCOLTI</h3>
          <p>Fra i Dati Personali raccolti da questo Sito Web, in modo autonomo o tramite terze parti, ci sono:
            Cookie, Dati di utilizzo, nome, cognome, email, numero di telefono, varie tipologie di Dati e posizione
            geografica.</p>
          <p>Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate di questa
            privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei dati
            stessi.<br> I Dati Personali possono essere liberamente forniti dall’Utente o, nel caso di Dati di
            Utilizzo, raccolti automaticamente durante l’uso di questo Sito Web.<br> Se non diversamente
            specificato, tutti i Dati richiesti da questo Sito Web sono obbligatori. Se l’Utente rifiuta di
            comunicarli, potrebbe essere impossibile per questo Sito Web fornire il Servizio. Nei casi in cui questo
            Sito Web indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali
            Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua
            operatività.<br> Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati
            a contattare il Titolare.<br> L’eventuale utilizzo di Cookie – o di altri strumenti di tracciamento – da
            parte di questo Sito Web o dei titolari dei servizi terzi utilizzati da questo Sito Web, ove non
            diversamente precisato, ha la finalità di fornire il Servizio richiesto dall’Utente, oltre alle
            ulteriori finalità descritte nel presente documento e nella Cookie Policy, se disponibile.</p>
          <p>L’Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi
            mediante questo Sito Web e garantisce di avere il diritto di comunicarli o diffonderli, liberando il
            Titolare da qualsiasi responsabilità verso terzi.</p>

          <h3 id="place_of_processing">MODALITÀ E LUOGO DEL TRATTAMENTO DEI DATI RACCOLTI</h3>
          <h3>MODALITÀ DI TRATTAMENTO</h3>
          <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la
            modifica o la distruzione non autorizzate dei Dati Personali.<br> Il trattamento viene effettuato
            mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente
            correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati
            altri soggetti coinvolti nell’organizzazione di questo Sito Web (personale amministrativo, commerciale,
            marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici
            terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati
            anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei
            Responsabili potrà sempre essere richiesto al Titolare del Trattamento.</p>
          <h3>BASE GIURIDICA DEL TRATTAMENTO</h3>
          <p>Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:
          </p>
          <ul>
            <li>l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni ordinamenti il
              Titolare può essere autorizzato a trattare Dati Personali senza che debba sussistere il consenso
              dell’Utente o un’altra delle basi giuridiche specificate di seguito, fino a quando l’Utente non si
              opponga (“opt-out”) a tale trattamento. Ciò non è tuttavia applicabile qualora il trattamento di Dati
              Personali sia regolato dalla legislazione europea in materia di protezione dei Dati Personali;</li>
            <li>il trattamento è necessario all’esecuzione di un contratto con l’Utente e/o all’esecuzione di misure
              precontrattuali;</li>
            <li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</li>
            <li>il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o per l’esercizio
              di pubblici poteri di cui è investito il Titolare;</li>
            <li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.
            </li>
          </ul>
          <p>E’ comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun
            trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un
            contratto o necessario per concludere un contratto.</p>
          <h3>LUOGO</h3>
          <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti
            coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare.<br> I
            Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si
            trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla
            sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
          <p>L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento di Dati al
            di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto internazionale pubblico o
            costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate
            dal Titolare per proteggere i Dati.</p>
          <p>Qualora abbia luogo uno dei trasferimenti appena descritti, l’Utente può fare riferimento alle
            rispettive sezioni di questo documento o chiedere informazioni al Titolare contattandolo agli estremi
            riportati in apertura.</p>
          <h3>PERIODO DI CONSERVAZIONE</h3>
          <p>I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati
            raccolti.</p>
          <p>Pertanto:</p>
          <ul>
            <li>I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e
              l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</li>
            <li>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno
              trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in
              merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o
              contattando il Titolare.</li>
          </ul>
          <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali
            più a lungo sino a quando detto consenso non venga revocato. Inoltre il Titolare potrebbe essere
            obbligato a conservare i Dati Personali per un periodo più lungo in ottemperanza ad un obbligo di legge
            o per ordine di un’autorità.</p>
          <p>Al termine del periodo di conservazioni i Dati Personali saranno cancellati. Pertanto, allo spirare di
            tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei
            Dati non potranno più essere esercitati.</p>

          <h2 id="use_collected_data">FINALITÀ DEL TRATTAMENTO DEI DATI RACCOLTI</h2>
          <p>I Dati dell’Utente sono raccolti per consentire al Titolare di fornire i propri Servizi, così come per
            le seguenti finalità: Interazione con social network e piattaforme esterne, Contattare l’Utente,
            Gestione dei pagamenti, Funzionalità sociali, Interazioni basate sulla posizione, Protezione dallo SPAM
            e Visualizzazione di contenuti da piattaforme esterne.</p>
          <p>Per ottenere ulteriori informazioni dettagliate sulle finalità del trattamento e sui Dati Personali
            concretamente rilevanti per ciascuna finalità, l’Utente può fare riferimento alle relative sezioni di
            questo documento.</p>

          <h3 id="data_processing_detailed_info">DETTAGLI SUL TRATTAMENTO DEI DATI PERSONALI</h3>
          <p>I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>
          <ul class="for_boxes">
            <li>
              <h3 class="expand-click w_icon_24 policyicon_purpose_2690091">CONTATTARE L’UTENTE</h3>
              <h4>MAILING LIST O NEWSLETTER (QUESTO SITO WEB)</h4>
              <p>Con la registrazione alla mailing list o alla newsletter, l’indirizzo email dell’Utente viene
                automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi
                email contenenti informazioni, anche di natura commerciale e promozionale, relative a questo
                Sito Web. L’indirizzo email dell’Utente potrebbe anche essere aggiunto a questa lista come
                risultato della registrazione a questo Sito Web o dopo aver effettuato un acquisto.</p>
              <p>Dati Personali raccolti: cognome, email e nome.</p>
              <h4>CONTATTO VIA TELEFONO (QUESTO SITO WEB)</h4>
              <p>Gli Utenti che hanno fornito il proprio numero di telefono potrebbero essere contattati per
                finalità commerciali o promozionali collegate a questo Sito Web, così come per soddisfare
                richieste di supporto.</p>
              <p>Dati Personali raccolti: numero di telefono.</p>
              <h4>MODULO DI CONTATTO (QUESTO SITO WEB)</h4>
              <p>L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per
                rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura indicata
                dall’intestazione del modulo.</p>
              <p>Dati Personali raccolti: cognome, email, nome e numero di telefono.</p>
            </li>
            <li>

              <h3 class="expand-click w_icon_24 policyicon_purpose_2690198">FUNZIONALITÀ SOCIALI</h3>

              <h4>INVITARE E SUGGERIRE AMICI (QUESTO SITO WEB)</h4>

              <p>Questo Sito Web può utilizzare i Dati Personali forniti per permettere agli Utenti di
                invitare i loro amici – per esempio attraverso la rubrica, se è stato autorizzato l’accesso –
                e per suggerire amici o connessioni al suo interno.</p>

              <p>Dati Personali raccolti: varie tipologie di Dati.</p>

            </li>
            <li>

              <h3 class="expand-click w_icon_24 policyicon_purpose_2690193">GESTIONE DEI PAGAMENTI</h3>

              <p>I servizi di gestione dei pagamenti permettono a questo Sito Web di processare pagamenti
                tramite carta di credito, bonifico bancario o altri strumenti. I dati utilizzati per il
                pagamento vengono acquisiti direttamente dal gestore del servizio di pagamento richiesto senza
                essere in alcun modo trattati da questo Sito Web.<br> Alcuni di questi servizi potrebbero
                inoltre permettere l’invio programmato di messaggi all’Utente, come email contenenti fatture o
                notifiche riguardanti il pagamento.</p>
              <h4>PAYPAL (PAYPAL)</h4>

              <p>PayPal è un servizio di pagamento fornito da PayPal Inc., che consente all’Utente di
                effettuare pagamenti online.</p>

              <p>Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy
                policy del servizio.</p>
              <h4>AMAZON PAYMENTS (AMAZON)</h4>

              <p>Amazon Payments è un servizio di pagamento fornito da Amazon.com, Inc., che consente
                all’Utente di effettuare pagamenti online utilizzando le proprie credenziali Amazon.</p>

              <p>Dati Personali raccolti: varie tipologie di Dati secondo quanto specificato dalla privacy
                policy del servizio.</p>
              <p>Luogo del trattamento: Consulta la privacy policy di Amazon –&nbsp;<a
                  href="https://payments.amazon.com/help/Personal-Accounts/Privacy-Security/Privacy-Notice"
                  target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

            </li>
            <li>

              <h3 class="expand-click w_icon_24 policyicon_purpose_2592878">INTERAZIONE CON SOCIAL NETWORK E
                PIATTAFORME ESTERNE</h3>

              <p>Questo tipo di servizi permettone di effettuare interazioni con i social network, o con altre
                piattaforme esterne, direttamente dalle pagine di questo Sito Web.<br> Le interazioni e le
                informazioni acquisite da questo Sito Web sono in ogni caso soggette alle impostazioni privacy
                dell’Utente relative ad ogni social network.<br> Nel caso in cui sia installato un servizio di
                interazione con i social network, è possibile che, anche nel caso gli Utenti non utilizzino il
                servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.</p>
              <h4>PULSANTE MI PIACE E WIDGET SOCIALI DI FACEBOOK (FACEBOOK, INC.)</h4>

              <p>Il pulsante “Mi Piace” e i widget sociali di Facebook sono servizi di interazione con il
                social network Facebook, forniti da Facebook, Inc.</p>

              <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
              <p>Luogo del trattamento: USA –&nbsp;<a href="https://www.facebook.com/privacy/explanation"
                  target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
              <h4>PULSANTE TWEET E WIDGET SOCIALI DI TWITTER (TWITTER, INC.)</h4>

              <p>Il pulsante Tweet e i widget sociali di Twitter sono servizi di interazione con il social
                network Twitter, forniti da Twitter, Inc.</p>

              <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
              <p>Luogo del trattamento: USA –&nbsp;<a href="https://twitter.com/privacy" target="_blank"
                  rel="noopener noreferrer">Privacy Policy</a>.</p>

            </li>
            <li>

              <h3 class="expand-click w_icon_24 policyicon_purpose_4437296">INTERAZIONI BASATE SULLA POSIZIONE
              </h3>

              <h4>GEOLOCALIZZAZIONE (QUESTO SITO WEB)</h4>

              <p>Questo Sito Web può raccogliere, usare e condividere i Dati relativi alla posizione
                geografica dell’Utente, al fine di fornire servizi basati sulla posizione stessa.<br> La
                maggior parte dei browser e dei dispositivi fornisce in modo predefinito degli strumenti per
                negare il tracciamento geografico. Qualora l’Utente abbia espressamente autorizzato tale
                possibilità, questo Sito Web può ricevere informazioni sulla sua effettiva posizione
                geografica.</p>

              <p>Dati Personali raccolti: posizione geografica.</p>

            </li>
            <li>

              <h3 class="expand-click w_icon_24 policyicon_purpose_4437308">PROTEZIONE DALLO SPAM</h3>

              <p>Questo tipo di servizi analizza il traffico di questo Sito Web, potenzialmente contenente Dati
                Personali degli Utenti, al fine di filtrarlo da parti di traffico, messaggi e contenuti
                riconosciuti come SPAM.</p>
              <h4>GOOGLE RECAPTCHA (GOOGLE INC.)</h4>

              <p>Google reCAPTCHA è un servizio di protezione dallo SPAM fornito da Google Inc.<br> L’utilizzo
                del sistema reCAPTCHA è soggetto alla&nbsp;<a href="https://www.google.com/intl/it/policies/privacy/"
                  target="_blank" rel="noopener">privacy
                  policy</a>&nbsp;e ai&nbsp;<a href="https://www.google.com/intl/it/policies/terms/" target="_blank"
                  rel="noopener">termini
                  di utilizzo</a>&nbsp;di Google.</p>

              <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
              <p>Luogo del trattamento: USA –&nbsp;<a href="https://www.google.com/intl/it/policies/privacy/"
                  target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

            </li>
            <li>

              <h3 class="expand-click w_icon_24 policyicon_purpose_4437319">VISUALIZZAZIONE DI CONTENUTI DA
                PIATTAFORME ESTERNE</h3>

              <p>Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne
                direttamente dalle pagine di questo Sito Web e di interagire con essi.<br> Nel caso in cui sia
                installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino
                il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.</p>
              <h4>WIDGET VIDEO YOUTUBE (GOOGLE INC.)</h4>

              <p>YouTube è un servizio di visualizzazione di contenuti video gestito da Google Inc. che
                permette a questo Sito Web di integrare tali contenuti all’interno delle proprie pagine.</p>

              <p>Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
              <p>Luogo del trattamento: USA –&nbsp;<a href="http://www.google.it/intl/it/policies/privacy/"
                  target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>

            </li>
          </ul>

          <h3 id="further_data">ULTERIORI INFORMAZIONI SUI DATI PERSONALI</h3>
          <ul class="for_boxes">
            <li>

              <h3 class="expand-click w_icon_24 icon_general">VENDITA DI BENI E SERVIZI ONLINE</h3>

              <p>I Dati Personali raccolti sono utilizzati per l’erogazione di servizi all’Utente o per la
                vendita di prodotti, inclusi il pagamento e l’eventuale consegna. I Dati Personali raccolti
                per perfezionare il pagamento possono essere quelli relativi alla carta di credito, al conto
                corrente utilizzato per il bonifico o ad altri strumenti di pagamento previsti. I Dati di
                pagamento raccolti da questo Sito Web dipendono dal sistema di pagamento utilizzato.</p>

            </li>
          </ul>

          <h2>DIRITTI DELL’UTENTE</h2>
          <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
          <p>In particolare, l’Utente ha il diritto di:</p>
          <ul>
            <li><b>revocare il consenso in ogni momento.</b>&nbsp;L’Utente può revocare il consenso al trattamento
              dei propri Dati Personali precedentemente espresso.</li>
            <li><b>opporsi al trattamento dei propri Dati.</b>&nbsp;L’Utente può opporsi al trattamento dei propri
              Dati quando esso avviene su una base giuridica diversa dal consenso. Ulteriori dettagli sul diritto di
              opposizione sono indicati nella sezione sottostante.</li>
            <li><b>accedere ai propri Dati.</b>&nbsp;L’Utente ha diritto ad ottenere informazioni sui Dati trattati
              dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.</li>
            <li><b>verificare e chiedere la rettificazione.</b>&nbsp;L’Utente può verificare la correttezza dei
              propri Dati e richiederne l’aggiornamento o la correzione.</li>
            <li><b>ottenere la limitazione del trattamento.</b>&nbsp;Quando ricorrono determinate condizioni,
              l’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non
              tratterà i Dati per alcun altro scopo se non la loro conservazione.</li>
            <li><b>ottenere la cancellazione o rimozione dei propri Dati Personali.</b>&nbsp;Quando ricorrono
              determinate condizioni, l’Utente può richiedere la cancellazione dei propri Dati da parte del
              Titolare.</li>
            <li><b>ricevere i propri Dati o farli trasferire ad altro titolare.</b>&nbsp;L’Utente ha diritto di
              ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e,
              ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa
              disposizione è applicabile quando i Dati sono trattati con strumenti automatizzati ed il trattamento è
              basato sul consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad
              esso connesse.</li>
            <li><b>proporre reclamo.</b>&nbsp;L’Utente può proporre un reclamo all’autorità di controllo della
              protezione dei dati personali competente o agire in sede giudiziale.</li>
          </ul>
          <h3>DETTAGLI SUL DIRITTO DI OPPOSIZIONE</h3>
          <p>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui
            è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno
            diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</p>
          <p>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto,
            possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il Titolare tratti dati
            con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo
            documento.</p>
          <h3>COME ESERCITARE I DIRITTI</h3>
          <p>Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli estremi di
            contatto del Titolare indicati in questo documento. Le richieste sono depositate a titolo gratuito e
            evase dal Titolare nel più breve tempo possibile, in ogni caso entro un mese.</p>

          <h3 id="cookie_policy">COOKIE POLICY</h3>
          <p>Questo Sito Web fa utilizzo di Cookie. Per saperne di più e per prendere visione dell’informativa
            dettagliata, l’Utente può consultare la&nbsp;Cookie Policy.</p>

          <h3 id="further_data_processing_info">ULTERIORI INFORMAZIONI SUL TRATTAMENTO</h3>
          <h3>DIFESA IN GIUDIZIO</h3>
          <p>I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi
            preparatorie alla sua eventuale instaurazione per la difesa da abusi nell’utilizzo di questo Sito Web o
            dei Servizi connessi da parte dell’Utente.<br> L’Utente dichiara di essere consapevole che il Titolare
            potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.</p>
          <h3>INFORMATIVE SPECIFICHE</h3>
          <p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questo Sito
            Web potrebbe fornire all’Utente delle informative aggiuntive e contestuali riguardanti Servizi
            specifici, o la raccolta ed il trattamento di Dati Personali.</p>
          <h3>LOG DI SISTEMA E MANUTENZIONE</h3>
          <p>Per necessità legate al funzionamento ed alla manutenzione, questo Sito Web e gli eventuali servizi
            terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni
            e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.</p>
          <h3>INFORMAZIONI NON CONTENUTE IN QUESTA POLICY</h3>
          <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in
            qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>
          <h3>RISPOSTA ALLE RICHIESTE “DO NOT TRACK”</h3>
          <p>Questo Sito Web non supporta le richieste “Do Not Track”.<br> Per scoprire se gli eventuali servizi di
            terze parti utilizzati le supportino, l’Utente è invitato a consultare le rispettive privacy policy.</p>
          <h3>MODIFICHE A QUESTA PRIVACY POLICY</h3>
          <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy
            in qualunque momento dandone informazione agli Utenti su questa pagina e, se possibile, su questo Sito
            Web nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso
            uno degli estremi di contatto di cui è in possesso il Titolare . Si prega dunque di consultare
            regolarmente questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>
          <p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare
            provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>


          <h3 class="expand-click w_icon_24 icon_ribbon">DEFINIZIONI E RIFERIMENTI LEGALI</h3>

          <h4>DATI PERSONALI (O DATI)</h4>
          <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in
            collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale,
            renda identificata o identificabile una persona fisica.</p>
          <h4>DATI DI UTILIZZO</h4>
          <p>Sono le informazioni raccolte automaticamente attraverso questo Sito Web (anche da applicazioni di
            parti terze integrate in questo Sito Web), tra cui: gli indirizzi IP o i nomi a dominio dei computer
            utilizzati dall’Utente che si connette con questo Sito Web, gli indirizzi in notazione URI (Uniform
            Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al
            server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della
            risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del
            browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della
            visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario
            seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine
            consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>
          <h4>UTENTE</h4>
          <p>L’individuo che utilizza questo Sito Web che, salvo ove diversamente specificato, coincide con
            l’Interessato.</p>
          <h4>INTERESSATO</h4>
          <p>La persona fisica cui si riferiscono i Dati Personali.</p>
          <h4>RESPONSABILE DEL TRATTAMENTO (O RESPONSABILE)</h4>
          <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati
            personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
          <h4>TITOLARE DEL TRATTAMENTO (O TITOLARE)</h4>
          <p>La persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che,
            singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali
            e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla
            fruizione di questo Sito Web. Il Titolare del Trattamento, salvo quanto diversamente specificato, è
            il titolare di questo Sito Web.</p>
          <h4>QUESTO SITO WEB (O QUESTA APPLICAZIONE)</h4>
          <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli
            Utenti.</p>
          <h4>SERVIZIO</h4>
          <p>Il Servizio fornito da questo Sito Web così come definito nei relativi termini (se presenti) su
            questo sito/applicazione.</p>
          <h4>UNIONE EUROPEA (O UE)</h4>
          <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo
            documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio
            Economico Europeo.</p>
          <h4>COOKIE</h4>
          <p>Piccola porzione di dati conservata all’interno del dispositivo dell’Utente.</p>
          <hr>
          <h4>RIFERIMENTI LEGALI</h4>
          <p>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi
            gli art. 13 e 14 del Regolamento (UE) 2016/679.</p>
          <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo Sito
            Web.</p>
        </div>
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>
