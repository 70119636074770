// Core packages
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Third party packages
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import { DynamicForm } from 'app/models/dynamic-form.model';
import { UsersService } from 'app/services/users.service';
import { HelperService } from 'app/services/helper.service';
import { CONFIG } from 'app/config/config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  formSettings: DynamicForm;
  stopFormLoading = false;
  resetForm = false;
  ownerEmail = CONFIG.owner.email;
  token: string;

  constructor(
    private usersService: UsersService,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    this.initForm();
    this.token = this.activatedRoute.snapshot.queryParams.k;
    if (!this.token) {
      this.toastrService.error('Invalid LINK');
      setTimeout(() => {
        this.router.navigate(['auth', 'login']);
        return;
      }, 1500);
    }
  }

  /**
   * Handle component init after HTML render
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    this.initBalls();
  }

  /**
   * Handle component destroy
   *
   * @since 1.0.0
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscription
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Init form
   */
  initForm(): void {
    this.formSettings = {
      options: {
        formSubmitBtnOptions: {
          active: false,
          text: 'Reset password',
          spinnerSize: 19,
          raised: true,
          stroked: true,
          flat: false,
          fab: false,
          buttonColor: 'primary',
          spinnerColor: 'primary',
          fullWidth: false,
          disabled: false,
          mode: 'indeterminate',
        },
        title: 'Reset password',
        description: 'Insert here your password and click the button',
        formWrapperClasses: ['mat-elevation-z2'],
      },
      controls: [
        {
          type: 'input',
          inputAttributes: {
            type: 'email',
          },
          name: 'email',
          value: '',
          placeholder: 'Insert email',
          label: 'Email',
          validators: [Validators.required, Validators.email],
          extraClasses: [],
          parentDivClasses: ['col-12'],
          groupIndex: 0,
        },
        {
          type: 'input',
          inputAttributes: {
            type: 'string',
          },
          name: 'password',
          value: '',
          placeholder: 'Insert password',
          label: 'New password',
          validators: [Validators.required, Validators.minLength(6), Validators.pattern(/\d/)],
          extraClasses: [],
          parentDivClasses: ['col-12'],
          groupIndex: 0,
        },
      ],
    };
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onSubmit(form: any): void {
    const password = form.value.password;
    const email = form.value.email;

    this.subscriptions.push(
      this.usersService.resetPassword(password, email, this.token).pipe(
        finalize(() => this.stopFormLoading = !this.stopFormLoading)
      ).subscribe(
        res => {
          if (res.status) {
            this.toastrService.success(res.message);
            this.resetForm = !this.resetForm;

            setTimeout(() => {
              this.router.navigate(['auth', 'login']);
            }, 3000);
          }
        },
        err => {
          // Choose one of the following error handling
          // method. The first one show a message right
          // under the form fields (if the form is properly
          // setted), the second one show toastr
          // notifications for each error
          // console.log('errrrrr', err);
          this.helperService.handleFormError(form, err);
          // this.helperService.handleError(err);
        }
      )
    );
  }


  /**
   * Generate random-colored moving balls and add them to the DOM
   *
   * @see https://www.cssscript.com/animated-particles-background-pure-javascript/   *
   * NB: would be nice to make a service for this (delegate componentRef)
   * and user renderer2
   *
   * @since 1.0.0
   */
  initBalls(): void {
    const colors = ['#3CC157', '#2AA7FF', '#1B1B1B', '#dd0031', '#F85F36'];
    const numBalls = 50;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      const ball = document.createElement('div');
      ball.classList.add('ball');
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;
      ball.style.position = 'absolute';
      ball.style.borderRadius = '100%';
      ball.style.opacity = '0.2';
      ball.style.zIndex = '0';

      balls.push(ball);
      document.getElementsByClassName('hero-section')[0].append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      const to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      el.animate(
        [
          { transform: 'translate(0, 0)' },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 5000, // random duration
          direction: 'alternate',
          fill: 'both',
          iterations: Infinity,
          easing: 'ease-in-out'
        }
      );
    });
  }
}
