// Core packages
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

// Third party packages

// Custom packages
import { ConfirmDialogComponent } from '../components/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Open a nice confirmation dialog
   * and ask user to click on "confirm" or "denial" button
   *
   * @since 1.0.0
   */
  confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel'
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        message,
        btnOkText,
        btnCancelText,
      },
      width: '500px',
    });

    /**
     * Subscribe to dialog and wait for a "true"
     * of "false" response, depending on user's choice
     */
    return dialogRef.afterClosed();
  }
}
