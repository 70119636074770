// Core packages
import { Injectable } from "@angular/core";

// Third party packages

// Custom packages
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  /**
   * Class constructor
   */
  constructor() {}

  /**
   * Get the API URL for current environment
   *
   * @since 1.0.0
   */
  getApiUrl(): string {
    const url = `${environment.apiProtocol}${environment.apiHost}:${environment.apiPort}/api/${environment.apiVersion}`;
    return url;
  }
}
