// Core packages
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { MAT_DATE_FORMATS } from '@angular/material/core';

// Third party packages
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressButtonsModule } from 'mat-progress-buttons';
import { ChartsModule } from 'ng2-charts';

// Custom packages - Modules
import { MaterialModule } from 'app/material.module';
import { AppRoutingModule } from 'app/app-routing.module';

// Custom packages - Components
import { AppComponent } from 'app/app.component';
import { HeaderComponent } from 'app/components/shared/header/header.component';
import { Error404Component } from 'app/components/errors/error404/error404.component';
import { ChartComponent } from 'app/components/pages/app/chart/chart.component';
import { ConfirmDialogComponent } from 'app/components/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { EventDetailDialogComponent } from 'app/components/shared/dialogs/event-detail-dialog/event-detail-dialog.component';
import { QuestionNodeComponent } from 'app/components/shared/chart-nodes/question-node/question-node.component';
import { AnswerNodeComponent } from 'app/components/shared/chart-nodes/answer-node/answer-node.component';
import { HomeComponent } from 'app/components/pages/home/home.component';
import { LoginComponent } from 'app/components/pages/auth/login/login.component';
import { RecoverPasswordComponent } from 'app/components/pages/auth/recover-password/recover-password.component';
import { FooterComponent } from 'app/components/shared/footer/footer.component';
import { UsersListComponent } from 'app/components/admin/pages/users/users-list/users-list.component';
import { UserDetailComponent } from 'app/components/admin/pages/users/user-detail/user-detail.component';
import { UserProfileComponent } from 'app/components/pages/user-profile/user-profile.component';
import { DynamicFormComponent } from 'app/components/shared/dynamic-form/dynamic-form.component';
import { AddUserComponent } from 'app/components/admin/pages/users/add-user/add-user.component';
import { ResetPasswordComponent } from 'app/components/pages/auth/reset-password/reset-password.component';

// Custom packages - Services
import { ConfirmDialogService } from 'app/services/confirm-dialog.service';
import { NodeHelperService } from 'app/services/node-helper.service';

// Custom packages - Pipes
import { ObjToKeysPipe } from 'app/pipes/object-to-keys.pipe';

// Custom packages - Guards
import { AuthGuard } from 'app/guards/auth.guard';
import { RoleGuard } from 'app/guards/role.guard';
import { AnalyzeComponent } from './components/pages/app/analyze/analyze.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';

registerLocaleData(localeIt, 'it');

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ObjToKeysPipe,
    HeaderComponent,
    Error404Component,
    ChartComponent,
    ConfirmDialogComponent,
    EventDetailDialogComponent,
    QuestionNodeComponent,
    AnswerNodeComponent,
    HomeComponent,
    LoginComponent,
    RecoverPasswordComponent,
    FooterComponent,
    UsersListComponent,
    UserDetailComponent,
    UserProfileComponent,
    DynamicFormComponent,
    AddUserComponent,
    ResetPasswordComponent,
    AnalyzeComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatProgressButtonsModule.forRoot(),
    ChartsModule,
  ],
  entryComponents: [ConfirmDialogComponent, EventDetailDialogComponent, QuestionNodeComponent, AnswerNodeComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    ConfirmDialogService,
    NodeHelperService,
    AuthGuard,
    RoleGuard,
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
