// Core packages
import { Component, OnInit, Renderer2, AfterViewInit, ElementRef, ViewChild, OnDestroy, Inject } from "@angular/core";
import { CdkDragEnd } from "@angular/cdk/drag-drop";

// Thrid party packages
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";

// Custom packages
import { CONFIG } from "app/config/config";
import { ConfirmDialogService } from "app/services/confirm-dialog.service";
import { QuestionNodeInterface } from "app/interfaces/question-node-interface";
import { ConnectionLineService } from "app/services/connection-line.service";
import { AuthService } from "app/services/auth.service";
import { AnswerNodeInterface } from "app/interfaces/answer-node-interface";

@Component({
  selector: "[app-question-node]",
  templateUrl: "./question-node.component.html",
  styleUrls: ["./question-node.component.scss"],
})
export class QuestionNodeComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @ViewChild("node") node: ElementRef;
  isInvalid = true; // By default it's invalid!
  config: any;
  parentNode: any;
  type = "question";
  id: number;
  parentId: number;
  linkedToIds: number[];
  text = "";
  answersNumber = 1;
  x = 0;
  y = 0;
  creation: any;
  updates: any[] = [];
  removeNode$: BehaviorSubject<QuestionNodeInterface> = new BehaviorSubject(null);
  createChildAnswerNode$: BehaviorSubject<QuestionNodeInterface> = new BehaviorSubject(null);
  data$: BehaviorSubject<QuestionNodeInterface>;
  moved$: BehaviorSubject<any> = new BehaviorSubject(null);
  clicked$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    @Inject("config") config,
    private renderer2: Renderer2,
    private toastrService: ToastrService,
    private confirmDialogService: ConfirmDialogService,
    public connectionLineService: ConnectionLineService,
    public elementRef: ElementRef,
    private authService: AuthService
  ) {
    this.config = config;
    // console.log('configQuestion', config);
    if (this.config) {
      if (this.config.parentNode) {
        this.parentNode = this.config.parentNode;
        this.parentId = this.parentNode.id;
      }
      if (this.config.id) {
        this.id = this.config.id;
      }
      if (this.config.text) {
        this.text = this.config.text;
      }
      if (this.config.answersNumber) {
        this.answersNumber = this.config.answersNumber;
      }
      if (this.config.x) {
        this.x = this.config.x;
      }
      if (this.config.y) {
        this.y = this.config.y;
      }
      if (this.config.creation) {
        this.creation = this.config.creation;
      } else {
        this.creation = {
          authorFirstName: this.authService.loggedUser$.value.firstName,
          authorLastName: this.authService.loggedUser$.value.lastName,
          authorEmail: this.authService.loggedUser$.value.email,
          date: new Date(),
        };
      }
      if (this.config.updates) {
        this.updates = this.config.updates;
      }
      if (this.config.linkedToIds) {
        // Get values and make the unique
        this.linkedToIds = this.config.linkedToIds.filter((v, i, a) => a.indexOf(v) === i);
      }
    } else {
      console.error("config", this.config);
    }

    this.data$ = new BehaviorSubject({
      type: this.type,
      id: this.id,
      parentId: this.parentNode ? this.parentNode.id : null,
      linkedToIds: this.linkedToIds ? this.linkedToIds : [],
      text: this.text,
      answersNumber: this.answersNumber,
      x: this.x,
      y: this.y,
      creation: {
        authorFirstName: this.authService.loggedUser$.value.firstName,
        authorLastName: this.authService.loggedUser$.value.lastName,
        authorEmail: this.authService.loggedUser$.value.email,
        date: new Date(),
      },
    });
  }

  /**
   * Init component
   */
  ngOnInit(): void {}

  /**
   * Handle component after render
   *
   * @since 1.0.0
   */
  ngAfterViewInit(): void {
    // If parentNodeId is set, then create a connection line with parent node
    if (this.parentNode && this.parentNode.id) {
      const leftNode = this.parentNode;
      const rightNode = this.getData();
      this.createSVGLine(leftNode, rightNode);
    }
  }

  /**
   * Create an SVG line from left node to right node
   *
   * @since 1.1.0
   */
  createSVGLine(
    leftNode: QuestionNodeInterface | AnswerNodeInterface,
    rightNode: QuestionNodeInterface | AnswerNodeInterface
  ): void {
    this.connectionLineService.connectDivs(
      this.elementRef,
      leftNode,
      rightNode,
      CONFIG.chart.connectionLineQuestionToAnswerColor,
      CONFIG.chart.connectionLineTension
    );
  }

  /**
   * Handle component destroy
   *
   * @returns undefined
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Set initial input value as "data-old-value" attribute
   * This will be used later if new value is not valid due to valudation rules
   * an we need to restore the old value
   *
   * @since 1.0.0
   */
  onAnswersNumberFocus(event: Event): void {
    this.renderer2.setAttribute(event.target, "data-old-value", this.answersNumber.toString());
  }

  /**
   * Handle change event on all inputs input validating
   * provided data and checking if we have to create some new nodes
   *
   * @since 1.0.0
   */
  onInputChange(): void {
    // Validate answersNumber value
    if (!this.validateAnswersNumberChange()) {
      const title = "Warning!";
      const message = "Answers number must be between 1 and 20";
      this.toastrService.warning(message, title);
      this.isInvalid = true;
      return;
    }

    // Validate text value
    if (this.text.length < 1) {
      const title = "Warning!";
      const message = "Insert some text before proceeding";
      this.toastrService.warning(message, title);
      this.isInvalid = true;
      return;
    }

    this.isInvalid = false;

    this.updates.push({
      authorFirstName: this.authService.loggedUser$.value.firstName,
      authorLastName: this.authService.loggedUser$.value.lastName,
      authorEmail: this.authService.loggedUser$.value.email,
      date: new Date(),
    });

    const data = this.getData();

    // Emit new data
    this.data$.next(data);

    // Emit the needs of creating a new answer-node as a child of this node
    this.createChildAnswerNode$.next(data);
  }

  /**
   * Check if insered value respects the validation rules
   * if not, just restore the previous value
   *
   * @since 1.0.0
   */
  validateAnswersNumberChange(): boolean {
    if (
      this.answersNumber < 1 ||
      this.answersNumber > CONFIG.maxAnswers ||
      this.answersNumber.toString().trim() === ""
    ) {
      const answersNumberInput = this.elementRef.nativeElement.children[0].children[1].children[1];
      if (answersNumberInput.attributes) {
        Object.keys(answersNumberInput.attributes).forEach((key) => {
          const attribute = answersNumberInput.attributes[key];
          if (attribute.name === "data-old-value") {
            this.answersNumber = attribute.value;
          }
        });
      }
      return false;
    }

    return true;
  }

  /**
   * Get all data in the appropriate format and returns it
   *
   * @since 1.0.0
   */
  getData(): QuestionNodeInterface {
    const data = {
      type: this.type,
      id: this.id,
      parentId: this.parentId,
      linkedToIds: this.linkedToIds,
      text: this.text,
      answersNumber: this.answersNumber,
      x: this.x,
      y: this.y,
      creation: this.creation,
      updates: this.updates,
    };
    return data;
  }

  /**
   * Remove current component and it's data
   *
   * @since 1.0.0
   */
  onRemove(): void {
    const title = "Warning!";
    const message = "Are you sure? Proceeding you will delete this element and all the underlying elements";
    const confirmDialogSubscription = this.confirmDialogService.confirm(title, message).subscribe((res) => {
      if (res) {
        const data = this.getData();
        this.removeNode$.next(data);
      }
    });
    this.subscriptions.push(confirmDialogSubscription);
  }

  /**
   * Set given coordinates to this component
   *
   * @since 1.0.0
   */
  setCoordinates(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  /**
   * Edit current coordinates with given deltas
   *
   * @since 1.0.0
   */
  editCoordinates(x: number, y: number): void {
    this.x += x;
    this.y += y;
    const svgs = this.elementRef.nativeElement.querySelectorAll("svg");
    Object.keys(svgs).forEach((key) => {
      const svg = svgs[key];
      const element = svg.children[0];

      // NB: uncomment if circle are enabled!
      // if (element.nodeName === 'circle') {
      //   this.renderer2.setAttribute(element, 'cx', (element.cx.baseVal.value + x));
      //   this.renderer2.setAttribute(element, 'cy', (element.cy.baseVal.value + y));
      // }
      // if (element.nodeName === 'path') {
      const d = element.attributes[0].value;
      const dAry = d.split(" ");
      const x1 = parseInt(dAry[1], 10) + x;
      const y1 = parseInt(dAry[2], 10) + y;
      const x2 = parseInt(dAry[8], 10) + x;
      const y2 = parseInt(dAry[9], 10) + y;
      const delta = (x2 - x1) * CONFIG.chart.connectionLineTension;
      const hx1 = x1 + delta;
      const hy1 = y1;
      const hx2 = x2 - delta;
      const hy2 = y2;

      const path = `M ${x1} ${y1} C ${hx1} ${hy1} ${hx2} ${hy2} ${x2} ${y2}`;
      element.attributes[0].value = path;
      // }
    });

    // Refresh data inside nodeHelperService
    this.data$.next(this.getData());
  }

  /**
   * Set valid status to the component
   *
   * @since 1.0.0
   */
  setValidStatus(): void {
    this.isInvalid = false;
  }

  /**
   * On element drag ended
   *
   * @since 1.0.0
   */
  onDragEnded(event: CdkDragEnd): void {
    this.moved$.next(event.distance);
    event.source._dragRef.reset();

    // Add distance to current X and Y and remove the translate3d style
    this.x = this.x + event.distance.x;
    this.y = this.y + event.distance.y;
    this.renderer2.removeStyle(this.node.nativeElement, "transform");

    // Send new data to other services
    this.data$.next(this.getData());
  }

  /**
   * Update svg coordinates and path after this node has been moved.
   * This is used to ensure that even if distance between current node
   * and parent node is changed the connection line is well rendered
   *
   * @since 1.0.0
   */
  setSVGsNewCoordinates(x2?: number, y2?: number, path?: string): void {
    const svgs = this.elementRef.nativeElement.querySelectorAll("svg"); // Find all svg
    Object.keys(svgs).forEach((key) => {
      const svg = svgs[key];
      const element = svg.children[0];
      // NB: uncomment if circle are enabled
      // // Update right dot with new coordinates
      // if (x2 && y2 && element.nodeName === 'circle' && key === '1') {
      //   this.renderer2.setAttribute(element, 'cx', x2.toString());
      //   this.renderer2.setAttribute(element, 'cy', y2.toString());
      // }

      // Update curved line
      if (path && element.nodeName === "path") {
        element.attributes[0].value = path;
      }
    });
  }

  /**
   * This remove an SVG with given attribute and value from the DOM
   *
   * @since 1.1.0
   */
  removeSvgByNodeId(nodeId: number, attribute: string = "data-node-to"): void {
    const selector = `svg[${attribute}="${nodeId}"]`;
    const svgs = this.elementRef.nativeElement.querySelectorAll(selector); // Find all svg
    for (const svg of svgs) {
      svg.remove();
    }
  }

  /**
   * Handle click over the node
   * this is used to emit an event to the nodes service
   * which will use this info to undestrand if this "click" event
   * is the last part of a link-to process
   *
   * @since 1.0.0
   */
  onNodeClicked(): void {
    this.clicked$.next(true);
  }
}
