// Core packages
import { Component, OnInit } from '@angular/core';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages
import { AuthService } from './services/auth.service';

/**
 * Script start
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  cookieAccepted = false;
  cookieAcceptedCookieName = 'SimuRiskCookieAcceptance';
  cookieAcceptedSign = 'k)3n.KWh2ikn_l2b1ojm';

  constructor(private authService: AuthService) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Check if user is logged with localStorage data
    this.subscriptions.push(
      this.authService.checkLogin().subscribe(
        (res) => {
          if (res.status) {
            // User is logged!
          }
        },
        (err) => {}
      )
    );

    // Check if user has already accepted cookies
    const acceptedData = localStorage.getItem(this.cookieAcceptedCookieName);
    if (acceptedData && acceptedData === this.cookieAcceptedSign) {
      this.cookieAccepted = true;
    }
  }

  /**
   * Handle click on "Accept cookie" button and set a cookie
   * to prevent banner visualization in the future
   *
   * @since 1.0.0
   */
  onCookieAccept(): void {
    this.cookieAccepted = true;
    localStorage.setItem(this.cookieAcceptedCookieName, this.cookieAcceptedSign);
  }
}

// if (typeof Worker !== 'undefined') {
//   // Create a new
//   const worker = new Worker('./app.worker', { type: 'module' });
//   worker.onmessage = ({ data }) => {
//     console.log(`page got message: ${data}`);
//   };
//   worker.postMessage('hello');
// } else {
//   // Web Workers are not supported in this environment.
//   // You should add a fallback so that your program still executes correctly.
// }
