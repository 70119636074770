// Core packages
import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';

// Third party packages
import { Subscription } from 'rxjs';

// Custom packages
import { CONFIG } from 'app/config/config';
import { ThemeService } from 'app/services/theme.service';
import { AuthService } from 'app/services/auth.service';
import { User } from 'app/models/user.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription[] = [];
  loggedUser: User;
  title: string;

  constructor(
    private themeService: ThemeService,
    private authService: AuthService,
  ) { }

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit(): void {
    // Set page title
    this.themeService.setPageTitle('SimuRisk | Risk Analysis Tool for Risk Management');

    this.subscriptions.push(
      this.authService.loggedUser$.subscribe(
        user => {
          if (user) {
            this.loggedUser = user;
          }
        }
      )
    );
  }

  ngAfterViewInit(): void {
    this.initBalls();
  }

  /**
   * Generate random-colored moving balls and add them to the DOM
   *
   * @see https://www.cssscript.com/animated-particles-background-pure-javascript/
   * NB: would be nice to make a service for this (delegate componentRef)
   * and user renderer2
   *
   * @since 1.0.0
   */
  initBalls(): void {
    const colors = ['#3CC157', '#2AA7FF', '#1B1B1B', '#dd0031', '#F85F36'];
    const numBalls = 50;
    const balls = [];

    for (let i = 0; i < numBalls; i++) {
      const ball = document.createElement('div');
      ball.classList.add('ball');
      ball.style.background = colors[Math.floor(Math.random() * colors.length)];
      ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
      ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
      ball.style.transform = `scale(${Math.random()})`;
      ball.style.width = `${Math.random()}em`;
      ball.style.height = ball.style.width;
      ball.style.position = 'absolute';
      ball.style.borderRadius = '100%';
      ball.style.opacity = '0.2';
      ball.style.zIndex = '0';

      balls.push(ball);
      document.getElementsByClassName('hero-section')[0].append(ball);
    }

    // Keyframes
    balls.forEach((el, i, ra) => {
      const to = {
        x: Math.random() * (i % 2 === 0 ? -11 : 11),
        y: Math.random() * 12
      };

      el.animate(
        [
          { transform: 'translate(0, 0)' },
          { transform: `translate(${to.x}rem, ${to.y}rem)` }
        ],
        {
          duration: (Math.random() + 1) * 5000, // random duration
          direction: 'alternate',
          fill: 'both',
          iterations: Infinity,
          easing: 'ease-in-out'
        }
      );
    });
  }

}
