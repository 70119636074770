// Core packages
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';

// Third party packages
import { Observable } from 'rxjs';

// Custom packages

export interface CanComponentDeactivate {
  CanDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateChartGuard implements CanDeactivate<CanComponentDeactivate> {
  /**
   * Call the method "CanDeactivate" from the component and returns
   * his result
   *
   * @since 1.0.0
   */
  canDeactivate(
    component: CanComponentDeactivate,
    urrentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.CanDeactivate();
  }
}
