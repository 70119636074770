<app-header></app-header>
<router-outlet class="app-wrapper"></router-outlet>
<div class="cookie-consent-wrapper" *ngIf="!cookieAccepted">
  <div class="cookie-consent-text">
    We use cookies to track site usage and for technical reasons. Using this site you agree with our <a
      routerLink="/privacy-policy">Cookies and Privacy Policy</a>
  </div> <!-- /.cookie-consent-text -->
  <div class="cookie-consent-options">
    <button mat-raised-button class="py-2 px-4" (click)="onCookieAccept()">
      Ok, I agree
      <mat-icon fontSet="fal" fontIcon="fa-thumbs-up"></mat-icon>
    </button>
  </div> <!-- /.cookie-consent-options -->
</div> <!-- /.cookie-consent-wrapper -->
<app-footer></app-footer>
