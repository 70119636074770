import { Component, OnInit } from "@angular/core";
import { ThemeService } from "app/services/theme.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    // Set page title
    this.themeService.setPageTitle("Privacy Policy");
  }
}
