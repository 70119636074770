<div class="node question-node" #node id="{{ id }}" [ngClass]="{'is-invalid': isInvalid}"
  [ngStyle]="{'left.px': x, 'top.px': y}" cdkDrag (cdkDragEnded)="onDragEnded($event)" (click)="onNodeClicked()">
  <div class="options">
    <button class="remove mat-raised-button mat-button-base mat-warn fal fa-trash" title="Click to remove element"
      (click)="onRemove()"></button>
    <button class="move cdk-drag-handle mat-raised-button mat-button-base mat-primary fal fa-arrows"
      title="Click to move" cdkDragHandle></button>
  </div> <!-- /.options -->
  <div class="input-group">
    <input type="text" [(ngModel)]="text" class="form-control node-value" placeholder="Question"
      (change)="onInputChange()" title="Question text">
    <input type="number" [(ngModel)]="answersNumber" class="form-control node-answer-number" min="1"
      max="{CONFIG.maxAnswers}" placeholder="Answers number" (focus)="onAnswersNumberFocus($event)"
      (change)="onInputChange()" title="Answers number">
  </div> <!-- /.input-group -->
</div> <!-- /.node -->
