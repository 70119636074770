<header class="app-header" #header>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <nav class="navbar navbar-expand-lg navbar-light">

          <a routerLink="/" class="navbar-brand">
            <img src="../../../../assets/img/logo/logo200.png" alt="{{ owner.niceName }}" height="35">
          </a>

          <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse bg-white animate slideIn" id="navbarSupportedContent"
            [ngbCollapse]="isNavbarCollapsed">
            <ul class="navbar-nav ml-auto">
              <!-- <li class="nav-item">
                <a class="nav-link" routerLink="/" routerLinkActive="active">Home</a>
              </li> -->
              <!-- <li class="nav-item dropdown d-none d-md-block" *ngIf="loggedUser">
                <div ngbDropdown class="d-inline-block">
                  <button class="nav-link btn btn-transparent" id="simuRiskDropdown" ngbDropdownToggle>
                    SIMURISK
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right animate slideIn"
                    aria-labelledby="simuRiskDropdown">
                    <a ngbDropdownItem routerLink="/app">MAP</a>
                    <a ngbDropdownItem routerLink="/app/analyze">ANALYZE</a>
                  </div>
                </div>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" routerLink="/app" routerLinkActive="active">Mapper</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/app/analyze" routerLinkActive="active">Analyzer</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/app/marketplace" routerLinkActive="active">Data marketplace</a>
              </li>
              <li class="nav-item" *ngIf="!loggedUser">
                <a class="mat-button mat-primary mat-raised-button" routerLink="/auth/login">
                  Login
                  <mat-icon fontSet="fal" fontIcon="fa-sign-in"></mat-icon>
                </a>
              </li>
              <li class="nav-item dropdown" *ngIf="loggedUser && loggedUser.role === 'admin'">
                <div ngbDropdown class="d-inline-block">
                  <button class="nav-link btn btn-transparent" id="adminDropdown" ngbDropdownToggle>
                    ADMIN
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right animate slideIn"
                    aria-labelledby="adminDropdown">
                    <a ngbDropdownItem routerLink="/admin/users/list">Users list</a>
                    <a ngbDropdownItem routerLink="/admin/users/add">Add user</a>
                  </div>
                </div>
              </li>
              <li class="nav-item dropdown" *ngIf="loggedUser">
                <div ngbDropdown class="d-inline-block">
                  <button class="nav-link btn btn-transparent text-uppercase" id="userDropdown" ngbDropdownToggle>
                    {{ loggedUser.firstName }}
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right animate slideIn"
                    aria-labelledby="userDropdown">
                    <a ngbDropdownItem routerLink="/profile">Profile</a>
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem (click)="onLogout()">Logout</button>
                  </div>
                </div>
              </li>

              <!-- <li class="nav-item dropdown" *ngIf="loggedUser && loggedUser.id">
                <div ngbDropdown class="d-inline-block">
                  <button class="nav-link btn btn-transparent" id="dropdownBasic1" ngbDropdownToggle>
                    {{ loggedUser.firstName }}
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right animate slideIn"
                    aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="onViewProfile()">Profile</button>
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem (click)="onLogout()">Logout</button>
                  </div>
                </div>
              </li> -->
            </ul> <!-- /.navbar-nav -->
          </div> <!-- /.navbar-collapse -->
        </nav>
      </div> <!-- /.col -->
    </div> <!-- /.row -->
  </div> <!-- /.container -->
</header>
