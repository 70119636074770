export const CONFIG = {
  // App owner data
  owner: {
    name: "V Logic Srls",
    niceName: "SimuRisk",
    vatNumber: "10930520969",
    legalAddressCountry: "IT",
    legalAddressZip: "20900",
    legalAddressCity: "Monza",
    legalAddressLine1: "Via Eraclito 22",
    email: "info.simurisk@vlogic.cloud",
    website: "https://simurisk.cloud",
    phone: "",
  },

  admin: {
    firstName: "Francesco",
    secondName: "Bancalari",
    phone: "3395413077",
    email: "info@sitisrl.it",
  },

  chart: {
    connectionLineTension: 0.5,
    connectionLineAnswerToQuestionColor: "#117911",
    connectionLineQuestionToAnswerColor: "#6097d2",
  },

  maxAnswers: 20,
};
