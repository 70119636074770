export class TableQueryParamsModel {
  start: number;
  limit: number;
  sort: string | undefined;
  sortVersus: string | undefined; // asc || desc
  search: string | undefined;
  role: string | undefined;

  /**
   * Class constructor
   *
   * @since 1.0.0
   *
   * @param [_start=0] Left offset for the query
   * @param [_limit=10] Number of wanted results
   * @param [_sort] Sort colum name
   * @param [_sortVersus] Sort versus. Can be 'asc' or 'desc'
   * @param [_search] Filtering search string
   * @param [_role] Filtering user role
   */
  constructor(
    Start: number = 0,
    Limit: number = 10,
    Sort?: string,
    SortVersus?: string,
    Search?: string,
    Role?: string
  ) {
    this.start = Start;
    this.limit = Limit;
    this.sort = Sort ? Sort : undefined;
    this.sortVersus = SortVersus ? SortVersus : undefined;
    this.search = Search ? Search : undefined;
    this.role = Role ? Role : undefined;
  }
}
