// Core packages
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

// Third party packages

// Custom packages
import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  private user: User;

  constructor(private router: Router, private authService: AuthService) {}

  /**
   * CanActivete method
   * check if user is logged in (in our authService) and if
   * he has an allowed role.
   * Allowed roles must be passed as array in the routing module
   * with `data: { roles ['admin', 'operator', 'adc', 'cdc', 'cdc'] }
   *
   * @see https://stackoverflow.com/questions/42719445/pass-parameter-into-route-guard
   *
   * @since 1.0.0
   *
   * @returns boolean true if user is allowed, false and redirect otherwhise
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.user = this.authService.loggedUser$.value;

    // Re-check is user is logged in our authService
    // If not redirect to login page
    if (!this.user || !this.user.id) {
      this.router.navigate(['auth', 'login']);
      return false;
    }

    // Get allowed roles
    const allowedRoles = route.data.roles as Array<string>;

    // Check is user's role is allowed
    if (allowedRoles.includes(this.user.role)) {
      return true;
    }

    // If we came here user is not allowed.
    // Redirect to 404 page an return false
    this.router.navigate(['errors', '404']);
    return;
  }
}
