<main>
  <section class="hero-section">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-6 py-4 py-md-0">
          <span class="h1 title">Reset password</span>
          <h2 class="lead">Any issue? Get in touch with support at <a href="mailto:{{ownerEmail}}">{{ownerEmail}}</a>
          </h2>
          <button mat-raised-button class="py-2 px-4 mt-5" routerLink="/auth/login">
            Login
            <mat-icon fontSet="fal" fontIcon="fa-sign-in"></mat-icon>
          </button>
        </div> <!-- /.col -->

        <div class="col-12 col-md-5 py-4 py-md-0">
          <app-dynamic-form *ngIf="formSettings" [options]="formSettings.options" [formControls]="formSettings.controls"
            (formSubmitted)="onSubmit($event)" [stopLoading]="stopFormLoading" [resetForm]="resetForm">
          </app-dynamic-form>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section> <!-- /.banner-section -->
</main>
