<h1 mat-dialog-title>{{ data.title }}</h1>

<mat-dialog-content [innerHTML]="data.message" class="mb-3"></mat-dialog-content> <!-- mat-dialog-content -->

<mat-dialog-actions align="end">
  <button mat-raised-button color="warn" (click)="onDenial()" class="close-btn">
    <mat-icon fontSet="fal" fontIcon="fa-times-circle"></mat-icon>
    {{ data.btnCancelText }}
  </button>
  <button mat-raised-button color="primary" (click)="onConfirmation()" class="close-btn">
    <mat-icon fontSet="fal" fontIcon="fa-check-circle"></mat-icon>
    {{ data.btnOkText }}
  </button>
</mat-dialog-actions> <!-- /.mat-dialog-actions -->
