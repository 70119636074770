// Core packages
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

// Third party packages
import { Observable } from 'rxjs';
import * as moment from 'moment';

// Custom packages
import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';
import { HelperService } from '../services/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private loggedUser: User;
  private sessionExpiresAt: number;

  constructor(private router: Router, private authService: AuthService, private helperService: HelperService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.loggedUser = this.authService.loggedUser$.value;
    this.sessionExpiresAt = +localStorage.getItem('s.expiresAt'); // this.authService.sessionExpiresAt$.value;

    // Check if token is expired
    if (this.loggedUser && this.loggedUser.id && moment.unix(moment.now()) > moment.unix(this.sessionExpiresAt)) {
      // Users seems to be logged, but his token is expired.
      // Log him out (remove data from localStorage) and return false
      this.authService.logout(false);
      return false;
    }

    // Check if user is already logged in authService
    if (this.loggedUser && this.loggedUser.id) {
      // User is logged and his token is not expired. Let's return true.
      return true;
    }

    // Check if users is logged with localStorage
    // (if we came here: (i) user is logged but requested
    // brand new page through page refresh, (ii) user is
    // not logged in)
    return new Observable((observer) => {
      this.authService.checkLogin().subscribe(
        (res) => {
          if (res instanceof Error || !res.status) {
            // If back-end is down we recive an error!
            this.helperService.handleError(res);
            // console.log('LOG USER OUT');
            // this.authService.logout();
            this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: state.url } });
            return observer.next(false);
          }
          // User logged,
          return observer.next(true);
        },
        (err) => {
          // User is not logged in, redirect him
          this.authService.logout();
          this.router.navigate(['auth', 'login'], { queryParams: { returnUrl: state.url } });
          return observer.next(false);
        }
      );
    });
  }
}
