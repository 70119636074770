// Core packages
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

// Third party packages
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';

// Custom packages
import { DynamicFormControl } from 'app/models/dynamic-form-control.model';
import { HelperService } from 'app/services/helper.service';
import { DynamicFormOptions } from 'app/models/dynamic-form-options.model';
import { MatChipInputEvent } from '@angular/material/chips';

/**
 * We're using a different change detection strategy here
 * to kno more, visit the following URL.
 * @see https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
 */

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Input() options: DynamicFormOptions;
  controls: DynamicFormControl[];
  @Input() set formControls(value: DynamicFormControl[]) {
    this.controls = value;
    this.cdr.detectChanges();
  }
  @Input() stopLoading: boolean;
  @Input() resetForm: boolean;
  @Output() formSubmitted: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  form: FormGroup;
  formSubmitBtnOptions: MatProgressButtonOptions;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * Init component
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Set submit button options
    this.formSubmitBtnOptions = this.options.formSubmitBtnOptions;

    // Init form
    this.initForm();
  }

  /**
   * Listen for changes of Input() values
   * It's used to:
   * - listen for the event of stopLoading
   *   which reset loading status
   * - reset form data
   *
   * @since 1.0.0
   */
  ngOnChanges(changes: SimpleChanges): void {
    // console.log('changes', changes);
    if (changes.stopLoading) {
      this.options.formSubmitBtnOptions.active = false;
      if (this.form) {
        this.form.markAsPristine();
      }
    }

    if (changes.resetForm && this.form) {
      this.form.reset();
      this.form.markAsPristine();
    }

    if (changes.formControls && this.form && this.form.controls) {
      changes.formControls.currentValue.forEach((element) => {
        this.form.controls[element.name].setValue(element.value);
      });
    }
  }

  /**
   * Init form taking all the data from given controls
   * and making it render in the DOM
   *
   * @since 1.0.0
   */
  initForm(): void {
    // Build a formGrupData like the one needed for the formBuilder.group() method
    const formGroupData: any = {};
    for (const control of this.controls) {
      if (control.disabled) {
        formGroupData[control.name] = [{ value: control.value, disabled: true }, [...control.validators]];
      } else {
        formGroupData[control.name] = [control.value, [...control.validators]];
      }
    }

    // Pass the data to the formBuilder and build the form
    this.form = this.formBuilder.group(formGroupData);
  }

  /**
   * Convenience getter for easy access to form fields
   *
   * @since 1.0.0
   */
  get f(): any {
    return this.form.controls;
  }

  /**
   * Check if given form control is required as per his validators
   *
   * @since 1.0.0
   */
  isRequired(control: FormControl): boolean {
    return this.helperService.controlIsRequired(control);
  }

  /**
   * Get the appropriate error message for vgiven error
   *
   * @since 1.0.0
   */
  getTheErrorMessage(key: string, value: any): string {
    return this.helperService.getErrorMessage(key, value);
  }

  /**
   * Get the requestef attribute value from the config object or
   * return null if it does not exists
   *
   * @since 1.0.0
   */
  getAttribute(control: DynamicFormControl, attributeKey: string): any {
    if (control.inputAttributes && control.inputAttributes[attributeKey]) {
      return control.inputAttributes[attributeKey];
    }
    return null;
  }

  /**
   * Handle form submit
   *
   * @since 1.0.0
   */
  onFormSubmit(event: Event): void {
    event.preventDefault();

    // Stop execution if form is invalid
    if (this.form.invalid) {
      console.warn(this.form);
      const title = 'Warning!';
      const message = 'Form is invalid, please check and try again';
      this.toastrService.error(message, title);
      return;
    }

    // Start loading button to spin
    this.options.formSubmitBtnOptions.active = true;

    // Emit form data
    return this.formSubmitted.next(this.form);
  }

  /**
   * Add a new value to the list controlled by given control
   *
   * @since 1.0.0
   */
  addToList(event: MatChipInputEvent, control: FormControl): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      control.value.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  /**
   * Remove given item from given control values
   *
   * @since 1.0.0
   */
  removeFromList(item: any, control: FormControl): void {
    const index = control.value.indexOf(item);
    if (index >= 0) {
      control.value.splice(index, 1);
    }
  }
}
