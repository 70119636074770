// Core packages
import { Component, OnInit, ViewChild, ElementRef, Renderer2, ViewChildren, QueryList } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSelect } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource, MatTable } from "@angular/material/table";

// Third party packages
import { ChartOptions, ChartDataSets } from "chart.js";
import "chartjs-plugin-zoom";
import { MatProgressButtonOptions } from "mat-progress-buttons";
import { Subscription, merge, fromEvent } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import moment from "moment";
import JSZip from "jszip";
import { BaseChartDirective } from "ng2-charts";

// Custom packages
import { ConfirmDialogService } from "app/services/confirm-dialog.service";
import { AnswerNodeInterface } from "app/interfaces/answer-node-interface";
import { QuestionNodeInterface } from "app/interfaces/question-node-interface";
import { ExportFileInterface } from "app/interfaces/export-file-interface";
import { EventDetailDialogComponent } from "app/components/shared/dialogs/event-detail-dialog/event-detail-dialog.component";
import { ThemeService } from "app/services/theme.service";
import { User } from "app/models/user.model";
import { AuthService } from "app/services/auth.service";
import { HelperService } from "app/services/helper.service";

export interface ScenarioInterface {
  id: number;
  items: any[];
  cumulatedChance: number;
  cumulatedDamage: number;
  cumulatedRisk: number;
}

interface AnalysisInterface {
  title: string;
  description: string;
  category: string;
  author: string;
  data: (QuestionNodeInterface | AnswerNodeInterface)[];
  data2: any[];
  scenarios: ScenarioInterface[];
  filteredScenarios: number;
  criticEvents: (QuestionNodeInterface | AnswerNodeInterface)[];
  maxDamage: number;
  maxChance: number;
  maxRisk: number;
  createdAt: Date;
}

@Component({
  selector: "app-analyze",
  templateUrl: "./analyze.component.html",
  styleUrls: ["./analyze.component.scss"],
})
export class AnalyzeComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  loggedUser: User;

  dataIsLoading = false;
  loadFromFileBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: "Load from File",
    spinnerSize: 19,
    raised: true,
    stroked: false,
    flat: false,
    fab: false,
    buttonColor: "primary",
    spinnerColor: "primary",
    fullWidth: false,
    disabled: false,
    mode: "indeterminate",
  };
  @ViewChild("loadFromFileButton") loadFromFileButton: ElementRef;
  @ViewChild("file", { static: true }) fileInput: ElementRef;
  availableColors: any[] = [
    {
      main: {
        // First serie color (red)
        backgroundColor: "rgba(255,0,0,0.3)",
        borderColor: "red",
        pointBackgroundColor: "rgba(148,159,177,1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
      },
      avg: {
        // First serie AVG color (red)
        backgroundColor: "rgba(255, 255, 255, 0)",
        borderColor: "red",
        pointBackgroundColor: "rgba(148,159,177,1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(148,159,177,0.8)",
      },
    },
    {
      main: {
        // First serie color (blu)
        backgroundColor: "rgba(11, 90, 250, 0.3)",
        borderColor: "rgba(11, 90, 250, 1)",
        pointBackgroundColor: "rgba(11, 90, 250, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(11, 90, 250, 0.8)",
      },
      avg: {
        // First serie AVG color (blu)
        backgroundColor: "rgba(11, 90, 250, 0)",
        borderColor: "rgba(11, 90, 250, 1)",
        pointBackgroundColor: "rgba(11, 90, 250, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(11, 90, 250, 0.8)",
      },
    },
    {
      main: {
        // First serie color (green)
        // backgroundColor: "rgba(4, 119, 76, 0.5)",
        // borderColor: "rgba(4, 119, 76, 1)",
        // pointBackgroundColor: "rgba(4, 119, 76, 1)",
        // pointBorderColor: "rgba(255, 255, 255, 0)",
        // pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        // pointHoverBorderColor: "rgba(4, 119, 76, 0.8)",
        backgroundColor: "rgba(60, 228, 91, 0.5)",
        borderColor: "rgba(60, 228, 91, 1)",
        pointBackgroundColor: "rgba(60, 228, 91, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(60, 228, 91, 0.8)",
      },
      avg: {
        // First serie AVG color (green)
        backgroundColor: "rgba(4, 119, 76, 0)",
        borderColor: "rgba(4, 119, 76, 1)",
        pointBackgroundColor: "rgba(4, 119, 76, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(4, 119, 76, 0.8)",
      },
    },
    {
      main: {
        // First serie color (yellow)
        backgroundColor: "rgba(255, 193, 59, 0.3)",
        borderColor: "rgba(255, 193, 59, 1)",
        pointBackgroundColor: "rgba(255, 193, 59, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(255, 193, 59, 0.8)",
      },
      avg: {
        // First serie AVG color (yellow)
        backgroundColor: "rgba(255, 193, 59, 0)",
        borderColor: "rgba(255, 193, 59, 1)",
        pointBackgroundColor: "rgba(255, 193, 59, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(255, 193, 59, 0.8)",
      },
    },
    {
      main: {
        // First serie color (acqua)
        backgroundColor: "rgba(50, 219, 200, 0.3)",
        borderColor: "rgba(50, 219, 200, 1)",
        pointBackgroundColor: "rgba(50, 219, 200, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(50, 219, 200, 0.8)",
      },
      avg: {
        // First serie AVG color (acqua)
        backgroundColor: "rgba(50, 219, 200, 0)",
        borderColor: "rgba(50, 219, 200, 1)",
        pointBackgroundColor: "rgba(50, 219, 200, 1)",
        pointBorderColor: "rgba(255, 255, 255, 0)",
        pointHoverBackgroundColor: "rgba(255, 255, 255, 0)",
        pointHoverBorderColor: "rgba(50, 219, 200, 0.8)",
      },
    },
  ];
  chanceFilter: number;

  // BEGIN: Analyzes data
  maxPermittedAnalyzedScenarios = 300000;
  analyzesIndex = 0;
  analyzes: AnalysisInterface[] = [];
  tempScenarios: ScenarioInterface[] = [];
  defaultTempScenariosId = 1;
  tempScenariosId = this.defaultTempScenariosId;
  foundScenarios = 0;
  // END: Analyzes data

  // BEGIN - Scenarios (events) list
  tableData: ScenarioInterface[] = [];
  tableDataLength: number;
  @ViewChild(MatTable) table: MatTable<any>;
  dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = ["id", "items.length", "cumulatedChance", "cumulatedDamage", "cumulatedRisk"];
  @ViewChild("sort1", { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild("fromInput", { static: true }) fromInput: ElementRef;
  @ViewChild("toInput", { static: true }) toInput: ElementRef;
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  @ViewChild("effectSelect") effectSelect: MatSelect;
  // END - Scenarios (events) list

  // BEGIN - Analysis data comparison chart
  analysisComparisonChartActive = false;
  analysisComparisonChartData: ChartDataSets[] = [];
  analysisComparisonChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    title: {
      display: true,
      text: "MAX DAMAGE PER ANALYSIS",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          // console.log("tooltipItem", tooltipItem);
          // const dataset = data.datasets[tooltipItem.datasetIndex];
          // const item = dataset.data[tooltipItem.index];
          return `Max damage : ${tooltipItem.value}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Analysis",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            // suggestedMax: 1,
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Max damage",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            // suggestedMax: 1,
            // stepSize: 0.1,
          },
        },
      ],
    },
  };
  analysisComparisonChartLegend = true;
  analysisComparisonChartType = "bar";
  analysisComparisonChartPlugins = []; // pluginAnnotations;
  @ViewChild("analysisComparisonChartContainer") analysisComparisonChartContainer: ElementRef;
  @ViewChild("analysisComparisonChart") analysisComparisonChart: ElementRef;
  // END - Analysis data comparison chart

  // BEGIN - Analysis data comparison chart2
  analysisComparisonChart2Active = false;
  analysisComparisonChart2Data: ChartDataSets[] = [];
  analysisComparisonChart2Options: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    title: {
      display: true,
      text: "MAX LIKELIHOOD PER ANALYSIS",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          // console.log("tooltipItem", tooltipItem);
          // const dataset = data.datasets[tooltipItem.datasetIndex];
          // const item = dataset.data[tooltipItem.index];
          return `Max likelihood : ${tooltipItem.value}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Analysis",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            // suggestedMax: 1,
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Max likelihood",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            // suggestedMax: 1,
            // stepSize: 0.1,
          },
        },
      ],
    },
  };
  analysisComparisonChart2Legend = true;
  analysisComparisonChart2Type = "bar";
  analysisComparisonChart2Plugins = []; // pluginAnnotations;
  @ViewChild("analysisComparisonChart2Container") analysisComparisonChart2Container: ElementRef;
  @ViewChild("analysisComparisonChart2") analysisComparisonChart2: ElementRef;
  // END - Analysis data comparison chart2

  // BEGIN - Analysis data comparison chart3
  analysisComparisonChart3Active = false;
  analysisComparisonChart3Data: ChartDataSets[] = [];
  analysisComparisonChart3Options: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    title: {
      display: true,
      text: "MAX RISK PER ANALYSIS",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          // console.log("tooltipItem", tooltipItem);
          // const dataset = data.datasets[tooltipItem.datasetIndex];
          // const item = dataset.data[tooltipItem.index];
          return `Max likelihood : ${tooltipItem.value}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: false,
            labelString: "Analysis",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            // suggestedMax: 1,
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Max risk",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            // suggestedMax: 1,
            // stepSize: 0.1,
          },
        },
      ],
    },
  };
  analysisComparisonChart3Legend = true;
  analysisComparisonChart3Type = "bar";
  analysisComparisonChart3Plugins = []; // pluginAnnotations;
  @ViewChild("analysisComparisonChart3Container") analysisComparisonChart3Container: ElementRef;
  @ViewChild("analysisComparisonChart3") analysisComparisonChart3: ElementRef;
  // END - Analysis data comparison chart3

  // BEGIN - Risk chart
  riskChartActive = false;
  riskChartData: ChartDataSets[] = [];
  riskChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    title: {
      display: true,
      text: "Risk matrix chart",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `Scenario #: ${item.id} | Cumulated damage: ${item.x.toExponential(
            2
          )} | Cumulated likelihood: ${item.y.toExponential(2)}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Damage",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 1,
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Likelihood",
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 1,
            stepSize: 0.1,
          },
        },
      ],
    },
  };
  riskChartLegend = true;
  riskChartType = "scatter";
  riskChartPlugins = []; // pluginAnnotations;
  @ViewChild("riskChartContainer") riskChartContainer: ElementRef;
  @ViewChild("riskChart") riskChart: ElementRef;
  @ViewChild(BaseChartDirective, { static: false }) riskChartBCD: BaseChartDirective;
  // END - Risk chart

  // BEGIN - LineChart2
  lineChart2Active = false;
  lineChart2Data: ChartDataSets[] = [];
  lineChart2Options: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: "Likelihood per scenarios chart",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `Scenario #: ${item.id} | Likelihood: ${item.y.toExponential(2)}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Scenarios",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Cumulated likelihood",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          mode: "xy",
        },
        zoom: {
          enabled: true,
          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: "xy",
          speed: 0.05,
        },
      },
    },
  };
  lineChart2Legend = true;
  lineChart2Type = "scatter";
  lineChart2Plugins = [];
  @ViewChild("lineChart2Container") lineChart2Container: ElementRef;
  @ViewChild("lineChart2") lineChart2: ElementRef;
  // @ViewChild(BaseChartDirective, { static: false }) lineChart2BCD: BaseChartDirective;
  @ViewChildren(BaseChartDirective)
  charts: QueryList<BaseChartDirective>;
  // END - lineChart2

  // BEGIN - LineChart3
  lineChart3Active = false;
  lineChart3Data: ChartDataSets[] = [];
  lineChart3Options: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: "Damage per scenarios chart",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `Scenario #: ${item.id} | Damage: ${item.y.toExponential(2)}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Scenarios",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Cumulated damage",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          mode: "xy",
        },
        zoom: {
          enabled: true,
          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: "xy",
        },
      },
    },
  };
  lineChart3Legend = true;
  lineChart3Type = "scatter";
  lineChart3Plugins = []; // pluginAnnotations;
  @ViewChild("lineChart3Container") lineChart3Container: ElementRef;
  @ViewChild("lineChart3") lineChart3: ElementRef;
  // @ViewChild(BaseChartDirective, { static: false }) lineChart3BCD: BaseChartDirective;
  // END - lineChart3

  // BEGIN - LineChart4
  lineChart4Active = false;
  lineChart4Data: ChartDataSets[] = [];
  lineChart4Options: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: "Cumulated risk ",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `Scenario #: ${item.id} | Cumulated risk: ${item.y.toExponential(2)}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Scenarios",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Cumulated risk",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          mode: "xy",
        },
        zoom: {
          enabled: true,
          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: "xy",
        },
      },
    },
  };
  lineChart4Legend = true;
  lineChart4Type = "scatter";
  lineChart4Plugins = []; // pluginAnnotations;
  @ViewChild("lineChart4Container") lineChart4Container: ElementRef;
  @ViewChild("lineChart4") lineChart4: ElementRef;
  // @ViewChild(BaseChartDirective, { static: false }) lineChart4BCD: BaseChartDirective;
  // END - lineChart4

  // BEGIN - LineChart5
  lineChart5Active = false;
  lineChart5Data: ChartDataSets[] = [];
  lineChart5Options: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: "Cumulated risk distribution",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data: any) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const item = dataset.data[tooltipItem.index];
          return `Scenario #: ${item.id} | Cumulated damage: ${item.x.toExponential(
            2
          )} | Cumulated risk: ${item.y.toExponential(2)}`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            reverse: false,
          },
          position: "bottom",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Cumulated damage",
          },
        },
      ],
      yAxes: [
        {
          position: "left",
          gridLines: {
            zeroLineColor: "rgba(0,255,0,1)",
          },
          scaleLabel: {
            display: true,
            labelString: "Cumulated risk",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          // Panning directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow panning in the y direction
          mode: "xy",
        },
        zoom: {
          enabled: true,
          // Zooming directions. Remove the appropriate direction to disable
          // Eg. 'y' would only allow zooming in the y direction
          mode: "xy",
        },
      },
    },
  };
  lineChart5Legend = true;
  lineChart5Type = "scatter";
  lineChart5Plugins = []; // pluginAnnotations;
  @ViewChild("lineChart5Container") lineChart5Container: ElementRef;
  @ViewChild("lineChart5") lineChart5: ElementRef;
  // @ViewChild(BaseChartDirective, { static: false }) lineChart5BCD: BaseChartDirective;
  // END - lineChart5

  constructor(
    private themeService: ThemeService,
    private confirmDialogService: ConfirmDialogService,
    private toastrService: ToastrService,
    private renderer2: Renderer2,
    private dialog: MatDialog,
    private authService: AuthService,
    private helperService: HelperService
  ) {}

  /**
   * Handle component init
   *
   * @since 1.0.0
   */
  ngOnInit() {
    // Set page title
    this.themeService.setPageTitle("Analyze data");

    this.loggedUser = this.authService.loggedUser$.value;
    this.chanceFilter = this.loggedUser.chanceFilter;
    this.subscriptions.push(
      this.authService.loggedUser$.subscribe((res) => {
        if (res) {
          this.loggedUser = res;
          this.chanceFilter = res.chanceFilter;
          // console.log('update maxDamage to', this.loggedUser.maxDamage);
          // console.log('update maxChance to', this.loggedUser.maxChance);
          this.riskChartOptions.scales.xAxes[0].ticks.suggestedMax = this.loggedUser.maxDamage;
          this.riskChartOptions.scales.yAxes[0].ticks.suggestedMax = this.loggedUser.maxChance;
        }
      })
    );

    // Listen for sortChange
    const sortSubscription = merge(this.sort.sortChange)
      .pipe(
        tap(() => {
          this.sortData();
        })
      )
      .subscribe();
    this.subscriptions.push(sortSubscription);

    // Listen for pagination change
    const paginatorSubscription = merge(this.paginator.page)
      .pipe(
        tap(() => {
          this.paginateData();
        })
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscription);

    // Set paginator for dataSource
    this.dataSource.paginator = this.paginator;

    // Handle filtering through search
    const fromInputSubsciption = fromEvent(this.fromInput.nativeElement, "keyup")
      .pipe(
        debounceTime(350), // max 1 query every 350 milliseconts
        distinctUntilChanged() // Eliminate duplicate values
      )
      .subscribe((res) => {
        this.filterTable();
      });
    this.subscriptions.push(fromInputSubsciption);

    const toInputSubsciption = fromEvent(this.toInput.nativeElement, "keyup")
      .pipe(
        debounceTime(350), // max 1 query every 350 milliseconts
        distinctUntilChanged() // Eliminate duplicate values
      )
      .subscribe((res) => {
        this.filterTable();
      });
    this.subscriptions.push(toInputSubsciption);

    const searchInputSubsciption = fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        debounceTime(350), // max 1 query every 350 milliseconts
        distinctUntilChanged() // Eliminate duplicate values
      )
      .subscribe((res) => {
        this.filterTable();
      });
    this.subscriptions.push(searchInputSubsciption);
  }

  /**
   * Handle click on "Load from File" button
   * and:
   * 1) Reset chart
   * 2) Read content from given file
   * 3) Build the DOM for read content
   */
  onLoadFromFile(): any {
    if (!this.helperService.isGoogleChrome()) {
      this.triggerFileUpload();
      return;
    }

    const title = "Are you ready?";
    const message = `You are about to analyze a SimuRisk file in the analyzer. You can load up to 5 files.`;

    this.subscriptions.push(
      this.confirmDialogService.confirm(title, message).subscribe((res) => {
        if (res) {
          if (this.riskChartData.length >= 5) {
            const ttl = "Error";
            const msg = "You cannot upload more than 5 files";
            this.toastrService.error(msg, ttl);
            return;
          }

          this.triggerFileUpload();
        }
      })
    );
  }

  /**
   * Trigger a file-upload modal that allows user to pick a file from his device
   *
   * @since 1.0.0
   */
  triggerFileUpload(): void {
    this.fileInput.nativeElement.click();
  }

  /**
   * Handle a file upload loading all data inside the chart
   *
   * @since 1.0.0
   */
  handleFileUpload(fileList: FileList): void {
    if (fileList.length !== 1) {
      console.warn("No fileList has been provided", fileList);
      return;
    }

    // Prevent analysis with more than 4 file
    if (this.analyzes.length >= 5) {
      const ttl = "Error";
      const msg = "You cannot upload more than 5 files";
      this.toastrService.error(msg, ttl);
      return;
    }

    // Enable page loader
    this.dataIsLoading = true;

    // Read the file
    const file = fileList[0];
    const { name } = file;
    const fileExt = name.split(".").pop();
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async () => {
      // If there is no result show a popup
      if (!fileReader.result) {
        console.warn("fileReader wrong result", fileReader);
        this.dataIsLoading = false;
        return;
      }

      const content = fileReader.result.toString();
      let parsedContent: ExportFileInterface;
      if (fileExt.toLowerCase() === "sim") {
        // It's a 'sim' file, let's de-zip it and then read it
        const zip = new JSZip();
        const value = await zip.loadAsync(file);
        const decodedFile = value.files[Object.keys(value.files)[0]];
        const content = await decodedFile.async("string");
        parsedContent = JSON.parse(content);
      } else {
        // It's a old JSON file
        parsedContent = JSON.parse(content);
      }

      // Activate "load from file" button spinner
      this.loadFromFileBtnOptions.active = true;

      // Check data is valid
      if (!parsedContent || !parsedContent.data) {
        const title = "Error!";
        const message = "Uploaded file is invalid or corrupded. Contact support for more info";
        this.toastrService.error(message, title);
        this.dataIsLoading = false;
        this.loadFromFileBtnOptions.active = false;
        console.warn("file", parsedContent);
        return;
      }

      // Clean parsed content from unuseful data
      parsedContent.data.forEach((node) => {
        delete node.x;
        delete node.y;
        delete node.creation;
        delete node.updates;
      });

      // Start data analysis
      this.analyzeScenario(parsedContent);

      // reset fileInput value
      this.fileInput.nativeElement.value = "";
    };

    fileReader.onerror = (error) => {
      this.dataIsLoading = false;
      const title = "Error!";
      const message = "There was an unexpected error while reading your file";
      this.toastrService.error(message, title);
    };
  }

  /**
   * Extract only some data from fiven node and returns it
   * This is used to preserve space during nodes analysis
   *
   * @since 1.0.0
   */
  getNodeUsefulData(node: QuestionNodeInterface | AnswerNodeInterface): any {
    // console.log('node', node);
    delete node.creation;
    delete node.updates;
    delete node.x;
    delete node.y;
    delete node.text;
    // tslint:disable:no-string-literal
    delete node["effect"];
    // tslint:enable:no-string-literal
    delete node.type;
    return node;
  }

  /**
   * Perform a calculation over current chart data
   *
   * @since 1.0.0
   */
  analyzeScenario(jsonData: ExportFileInterface): Promise<void> {
    console.log("analyzeScenario", this.chanceFilter);
    return new Promise(async (resolve, reject) => {
      // Is user is using a browser that does not suppoer Workers
      // stop him. C'mon, update your browser mr. User!
      if (typeof Worker === "undefined") {
        // Show error message
        this.toastrService.error(
          "Looks like your browser does not support advanced tools like SimuRisk. Update your browser before proceeding",
          "Warning!"
        );

        // Stop loaders
        this.loadFromFileBtnOptions.active = false;
        this.dataIsLoading = false;

        return resolve();
      }

      // Init worket
      const analysisWorker = new Worker("../../../../analysis.worker", { type: "module" });

      // Once worker has analyzed data we will recive it here
      analysisWorker.onmessage = async (message) => {
        // console.log(message.data);

        // If there was some error mesages
        // print messages and stop loading
        if (message.data.messages) {
          // Stop loaders
          this.loadFromFileBtnOptions.active = false;
          this.dataIsLoading = false;

          // Print messages
          message.data.messages.forEach((msg) => {
            this.toastrService.error(msg.message, msg.title, {
              disableTimeOut: true,
            });
          });

          // Destroy worker
          analysisWorker.terminate();

          // Reset found scenarios
          this.foundScenarios = 0;

          // Block execution
          return resolve();
        }

        // If analysis has been provided from worker
        if (message.data.analysis) {
          // console.log("summaries", message.data.analysis.leavesSummaries);
          // Get analysis result
          const analysis = message.data.analysis;

          // Push analysis in analyzes list
          this.analyzes.push(analysis);

          // If this is the first analysis populate page content
          await this.populatePageContent();

          // Increment analyzes index
          this.analyzesIndex++;

          // Reset found scenarios
          this.foundScenarios = 0;

          // Destroy worker
          analysisWorker.terminate();

          // Block execution
          return resolve();
        }

        if (message.data.update) {
          this.foundScenarios = message.data.update;
        }

        // const msg = 'We have encountered an unexpected error, please try again or contact sales if error persists';
        // const ttl = 'Error!';
        // this.toastrService.error(msg, ttl, {
        //   disableTimeOut: true,
        // });

        // // Destroy worker
        // analysisWorker.terminate();

        // // Block execution
        // return resolve();
      };

      // Sent json data to analysis worker
      analysisWorker.postMessage({
        jsonData,
        chanceFilter: this.chanceFilter,
      });
    });
  }

  /**
   * Populate page content (charts and datatables) with
   * current analyzes index
   *
   * @since 1.0.0
   */
  populatePageContent(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (this.analyzesIndex === 0) {
        await this.initFirstAnalysisCharts();
      } else {
        await this.initComparativeAnalysisCharts();
      }

      // Stop loading status
      this.dataIsLoading = false;
      this.loadFromFileBtnOptions.active = false;

      return resolve();
    });
  }

  /**
   * Init first analysis data and add related
   * series to charts and to tables
   *
   * @since 1.0.0
   */
  initFirstAnalysisCharts(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const scenarios = this.analyzes[0].scenarios;

      // Init scenarios datatable
      this.initScenariosDatatable(scenarios);

      // Init all charts data and populate charts
      await this.initScenariosCharts(scenarios);

      // BEGIN - Populate analysisComparisonChart
      const analysisComparisonChartDataSet: ChartDataSets = {
        label: this.analyzes[0].title,
        data: [this.analyzes[0].maxDamage],
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.analyzesIndex].main.backgroundColor,
        borderColor: this.availableColors[this.analyzesIndex].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.analyzesIndex].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.analyzesIndex].main.borderColor,
        pointBorderColor: this.availableColors[this.analyzesIndex].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.analyzesIndex].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.analyzesIndex].main.pointHoverBorderColor,
      };
      this.analysisComparisonChartData.push(analysisComparisonChartDataSet);
      this.analysisComparisonChartActive = false;
      // END - Populate analysisComparisonChart

      // BEGIN - Populate analysisComparisonChart2
      const analysisComparisonChart2DataSet: ChartDataSets = {
        label: this.analyzes[0].title,
        data: [this.analyzes[0].maxChance],
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.analyzesIndex].main.backgroundColor,
        borderColor: this.availableColors[this.analyzesIndex].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.analyzesIndex].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.analyzesIndex].main.borderColor,
        pointBorderColor: this.availableColors[this.analyzesIndex].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.analyzesIndex].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.analyzesIndex].main.pointHoverBorderColor,
      };
      this.analysisComparisonChart2Data.push(analysisComparisonChart2DataSet);
      this.analysisComparisonChart2Active = false;
      // END - Populate analysisComparisonChart2

      // BEGIN - Populate analysisComparisonChart3
      const analysisComparisonChart3DataSet: ChartDataSets = {
        label: this.analyzes[0].title,
        data: [this.analyzes[0].maxRisk],
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.analyzesIndex].main.backgroundColor,
        borderColor: this.availableColors[this.analyzesIndex].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.analyzesIndex].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.analyzesIndex].main.borderColor,
        pointBorderColor: this.availableColors[this.analyzesIndex].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.analyzesIndex].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.analyzesIndex].main.pointHoverBorderColor,
      };
      this.analysisComparisonChart3Data.push(analysisComparisonChart3DataSet);
      this.analysisComparisonChart3Active = false;
      // END - Populate analysisComparisonChart3

      return resolve();
    });
  }

  /**
   * Populate all charts with fiven scenarios' data
   *
   * @since 1.0.0
   */
  initScenariosCharts(scenarios: ScenarioInterface[]): Promise<void> {
    return new Promise((resolve, reject) => {
      // Init series data arrays and other vars
      const riskChartseriesData = []; // Risk matrix chart
      const lineChart2SeriesData = []; // Likelihood per scenarios chart
      const lineChart22SeriesData = [];
      const lineChart3SeriesData = []; // Damage per scenarios chart
      const lineChart32SeriesData = [];
      const lineChart4SeriesData = []; // Risk per scenarios chart
      const lineChart42SeriesData = [];
      const lineChart5SeriesData = []; // Cumulated risk distribution chart
      let sumOfCumulatedChance = 0;
      let sumOfCumulatedDamage = 0;
      let sumOfCumulatedRisk = 0;

      // Calculate the total chance, damage and risk for all scenarios
      // and than calculate the avarage value for each charts
      // this is used to show the avarage line in each chart
      scenarios.forEach((scenario) => {
        sumOfCumulatedChance += scenario.cumulatedChance;
        sumOfCumulatedDamage += scenario.cumulatedDamage;
        sumOfCumulatedRisk += scenario.cumulatedRisk;
      });
      const lineChart22SeriesAvg = sumOfCumulatedChance / scenarios.length;
      const lineChart32SeriesAvg = sumOfCumulatedDamage / scenarios.length;
      const lineChart42SeriesAvg = sumOfCumulatedRisk / scenarios.length;

      // Loop through all scenarios and add a point in every chart
      // depending on chat chart should show to the user
      let index = 1;
      for (const scenario of scenarios) {
        // riskChart Data
        if (scenario.cumulatedDamage > 0) {
          const point1 = {
            x: scenario.cumulatedDamage,
            y: scenario.cumulatedChance,
            index,
            id: scenario.id,
          };
          riskChartseriesData.push(point1);
        }

        // lineChart2 Data
        const point2 = {
          x: scenario.id,
          y: scenario.cumulatedChance,
          index,
          id: scenario.id,
        };
        lineChart2SeriesData.push(point2);
        lineChart22SeriesData.push({
          x: index,
          y: lineChart22SeriesAvg,
          index,
          id: scenario.id,
        });

        // lineChart3 Data
        const point3 = {
          x: scenario.id,
          y: scenario.cumulatedDamage,
          index,
          id: scenario.id,
        };
        lineChart3SeriesData.push(point3);
        lineChart32SeriesData.push({
          x: index,
          y: lineChart32SeriesAvg,
          index,
          id: scenario.id,
        });

        // lineChart4 Data
        const point4 = {
          x: scenario.id,
          y: scenario.cumulatedRisk,
          index,
          id: scenario.id,
        };
        lineChart4SeriesData.push(point4);
        lineChart42SeriesData.push({
          x: index,
          y: lineChart42SeriesAvg,
          index,
          id: scenario.id,
        });

        index++;
      }

      // BEGIN  - Build dataset for "cumulated risk distribution chart"
      const scenariosSortedPerDamageDesc = scenarios.sort((a, b) => {
        // return b.cumulatedDamage - a.cumulatedDamage;
        if (a.cumulatedDamage > b.cumulatedDamage) {
          return -1;
        }
        if (a.cumulatedDamage < b.cumulatedDamage) {
          return 1;
        }
        // If they are equal just order by risk desc
        if (a.cumulatedRisk > b.cumulatedRisk) {
          return -1;
        }
        if (a.cumulatedRisk < b.cumulatedRisk) {
          return 1;
        }
        return 0;
      });

      // Loop through all scenarios and group them by damage
      // In case of duplicate damages we have to keep only the scenario with
      // bigger risk
      const scenariosGroupedByDamage = [];
      scenariosSortedPerDamageDesc.forEach((scenario) => {
        // If a scenario with the same cumulatedDamage was already insered
        // in our array, than get only the higher risk of them
        const scenarioDamageExists = scenariosGroupedByDamage.findIndex(
          (scen) => scen.cumulatedDamage === scenario.cumulatedDamage
        );
        if (scenarioDamageExists !== -1) {
          if (scenariosGroupedByDamage[scenarioDamageExists].cumulatedRisk < scenario.cumulatedRisk) {
            scenariosGroupedByDamage[scenarioDamageExists].cumulatedRisk = scenario.cumulatedRisk;
          }
        } else {
          scenariosGroupedByDamage.push(scenario);
        }
      });

      let cumulatedCumulatedRisk = 0;
      scenariosGroupedByDamage.forEach((scenario, i) => {
        cumulatedCumulatedRisk += scenario.cumulatedRisk;
        const point5 = {
          x: scenario.cumulatedDamage,
          y: cumulatedCumulatedRisk,
          index: i + 1,
          id: scenario.id,
        };
        lineChart5SeriesData.push(point5);
      });

      // console.log('scenariosSortedPerDamageDesc', scenariosSortedPerDamageDesc);
      // scenariosSortedPerDamageDesc.forEach((scenario, i) => {
      //   const pointExists = lineChart5SeriesData
      //     .findIndex((val, index) => val.x === scenario.cumulatedDamage);
      //   if (pointExists !== -1) {
      //     if (lineChart5SeriesData[pointExists].cumulatedRisk < scenario.cumulatedRisk) {
      //       lineChart5SeriesData[pointExists].cumulatedDamage = scenario.cumulatedDamage;
      //     }
      //   } else {
      //     const point5 = {
      //       x: scenario.cumulatedDamage,
      //       y: scenario.cumulatedRisk,
      //       index: i + 1,
      //       id: scenario.id,
      //     };
      //     lineChart5SeriesData.push(point5);
      //   }
      // });
      // END  - Build dataset for "Cumulated risk distribution chart"

      // Sort seriesData by damage ascending
      riskChartseriesData.sort((a: any, b: any) => {
        if (a.x < b.x) {
          return 1;
        }
        if (a.x > b.x) {
          return -1;
        }
        return 0;
      });

      // Sort lineChart2SeriesData by scenario ID asc
      lineChart2SeriesData.sort((a: any, b: any) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
      // Sort lineChart3SeriesData by scenario ID asc
      lineChart3SeriesData.sort((a: any, b: any) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });
      // Sort lineChart4SeriesData by scenario ID asc
      lineChart4SeriesData.sort((a: any, b: any) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      });

      const serie: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1}`,
        data: riskChartseriesData,
        showLine: false,
        borderDash: [0, 0],
        pointRadius: 3,
        pointHoverRadius: 3,

        pointBackgroundColor: this.availableColors[this.riskChartData.length].main.borderColor,
        pointBorderColor: "rgba(0, 0, 0, .8)", // this.availableColors[this.riskChartData.length].main.borderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].main.borderColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].main.borderColor,
      };
      const serieLineChart2: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1}`,
        data: lineChart2SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.riskChartData.length].main.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.riskChartData.length].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].main.borderColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].main.pointHoverBorderColor,
      };
      const serieLineChart22: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1} AVG`,
        data: lineChart22SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: this.availableColors[this.riskChartData.length].avg.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].avg.borderColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].avg.pointBackgroundColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].avg.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].avg.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].avg.pointHoverBorderColor,
      };
      const serieLineChart3: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1}`,
        data: lineChart3SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.riskChartData.length].main.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.riskChartData.length].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].main.borderColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].main.pointHoverBorderColor,
      };
      const serieLineChart32: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1} AVG`,
        data: lineChart32SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: this.availableColors[this.riskChartData.length].avg.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].avg.borderColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].avg.pointBackgroundColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].avg.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].avg.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].avg.pointHoverBorderColor,
      };
      const serieLineChart4: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1}`,
        data: lineChart4SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.riskChartData.length].main.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.riskChartData.length].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].main.borderColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].main.pointHoverBorderColor,
      };
      const serieLineChart42: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1} AVG`,
        data: lineChart42SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: this.availableColors[this.riskChartData.length].avg.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].avg.borderColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].avg.pointBackgroundColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].avg.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].avg.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].avg.pointHoverBorderColor,
      };
      const serieLineChart5: ChartDataSets = {
        label: `Simulation #${this.riskChartData.length + 1}`,
        data: lineChart5SeriesData,
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.riskChartData.length].main.backgroundColor,
        borderColor: this.availableColors[this.riskChartData.length].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.riskChartData.length].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.riskChartData.length].main.borderColor,
        pointBorderColor: this.availableColors[this.riskChartData.length].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.riskChartData.length].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.riskChartData.length].main.pointHoverBorderColor,
      };

      // Add serie to chart
      this.riskChartData.push(serie);
      this.lineChart2Data.push(serieLineChart2);
      this.lineChart2Data.push(serieLineChart22);
      this.lineChart3Data.push(serieLineChart3);
      this.lineChart3Data.push(serieLineChart32);
      this.lineChart4Data.push(serieLineChart4);
      this.lineChart4Data.push(serieLineChart42);
      this.lineChart5Data.push(serieLineChart5);

      // Enable chart's sections
      if (!this.riskChartActive) {
        this.riskChartActive = true;
      }
      if (!this.lineChart2Active) {
        this.lineChart2Active = true;
      }
      if (!this.lineChart3Active) {
        this.lineChart3Active = true;
      }
      if (!this.lineChart4Active) {
        this.lineChart4Active = true;
      }
      if (!this.lineChart5Active) {
        this.lineChart5Active = true;
      }
      return resolve();
    });
  }

  /**
   * Init scenarios list table with given data
   *
   * @since 1.0.0
   */
  initScenariosDatatable(scenarios: ScenarioInterface[]): void {
    // Set data to dataTable
    this.tableDataLength = scenarios.length;
    this.tableData = scenarios.slice(this.paginator.pageSize * this.paginator.pageIndex, this.paginator.pageSize);
    this.dataSource.data = this.tableData;

    // Paginate data (and populate dataSource)
    this.dataSource.paginator = this.paginator;

    // Sort data
    this.dataSource.sort = this.sort;
  }

  /**
   * Handle comparative analysis data and add
   * related series to charts
   *
   * @since 1.0.0
   */
  initComparativeAnalysisCharts(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const scenarios = this.analyzes[this.analyzesIndex].scenarios;

      // Init all charts data and populate charts
      await this.initScenariosCharts(scenarios);

      // BEGIN - Populate analysisComparisonChart
      const analysisComparisonChartDataSet: ChartDataSets = {
        label: this.analyzes[this.analyzesIndex].title,
        data: [this.analyzes[this.analyzesIndex].maxDamage],
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.analyzesIndex].main.backgroundColor,
        borderColor: this.availableColors[this.analyzesIndex].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.analyzesIndex].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.analyzesIndex].main.borderColor,
        pointBorderColor: this.availableColors[this.analyzesIndex].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.analyzesIndex].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.analyzesIndex].main.pointHoverBorderColor,
      };
      this.analysisComparisonChartData.push(analysisComparisonChartDataSet);
      this.analysisComparisonChartActive = true;
      // END - Populate analysisComparisonChart

      // BEGIN - Populate analysisComparisonChart
      const analysisComparisonChart2DataSet: ChartDataSets = {
        label: this.analyzes[this.analyzesIndex].title,
        data: [this.analyzes[this.analyzesIndex].maxChance],
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.analyzesIndex].main.backgroundColor,
        borderColor: this.availableColors[this.analyzesIndex].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.analyzesIndex].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.analyzesIndex].main.borderColor,
        pointBorderColor: this.availableColors[this.analyzesIndex].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.analyzesIndex].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.analyzesIndex].main.pointHoverBorderColor,
      };
      this.analysisComparisonChart2Data.push(analysisComparisonChart2DataSet);
      this.analysisComparisonChart2Active = true;
      // END - Populate analysisComparisonChart

      // BEGIN - Populate analysisComparisonChart
      const analysisComparisonChart3DataSet: ChartDataSets = {
        label: this.analyzes[this.analyzesIndex].title,
        data: [this.analyzes[this.analyzesIndex].maxRisk],
        showLine: true,
        borderWidth: 0.7,
        borderDash: [0, 0],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: this.availableColors[this.analyzesIndex].main.backgroundColor,
        borderColor: this.availableColors[this.analyzesIndex].main.borderColor,
        // pointBackgroundColor: this.availableColors[this.analyzesIndex].main.pointBackgroundColor,
        pointBackgroundColor: this.availableColors[this.analyzesIndex].main.borderColor,
        pointBorderColor: this.availableColors[this.analyzesIndex].main.pointBorderColor,
        pointHoverBackgroundColor: this.availableColors[this.analyzesIndex].main.pointHoverBackgroundColor,
        pointHoverBorderColor: this.availableColors[this.analyzesIndex].main.pointHoverBorderColor,
      };
      this.analysisComparisonChart3Data.push(analysisComparisonChart3DataSet);
      this.analysisComparisonChart3Active = true;
      // END - Populate analysisComparisonChart

      return resolve();
    });
  }

  /**
   * Filter scenarios dataTable as per "fromInput" and "toInput" filters
   *
   * @since 1.0.0
   */
  filterTable(): void {
    this.paginator.pageIndex = 0;
    this.paginateData();
  }

  /**
   * Sort dataSource with current sorting col
   *
   * @since 1.0.0
   */
  sortData(returnData: boolean = false): ScenarioInterface[] {
    console.log("sortData()");
    let scenarios = [];
    if (this.effectSelect.value === "") {
      scenarios = this.analyzes[0].scenarios;
    } else {
      // Get all and only scenarios where all events' effect are
      // like the one selected in the effectSelect filter
      scenarios = this.analyzes[0].scenarios.filter((scenario) => {
        // Get all-and-only answer events
        const answerEvents = scenario.items
          .map((eventId) => {
            return this.analyzes[0].data2[eventId];
          })
          .filter((event) => event.type === "answer");

        // Check if all events of the scenario are equal
        // to the selected filter for "effect"
        const itemsAreOk = answerEvents.every((event: AnswerNodeInterface) => event.effect === this.effectSelect.value);

        // If so, just return the scenario
        if (itemsAreOk) {
          return scenario;
        }
      });
    }

    if (this.searchInput.nativeElement.value !== "") {
      // Get all and only scenarios where all events' effect are
      // like the one selected in the effectSelect filter
      scenarios = this.analyzes[0].scenarios.filter((scenario) => {
        // Get all-and-only answer events
        const answerEvents = scenario.items
          .map((eventId) => {
            return this.analyzes[0].data2[eventId];
          })
          .filter((event) => event.type === "answer");

        // Check if at least one event of the scenario
        // contains searched text
        const scenarioMatchSearch = answerEvents.some((event: AnswerNodeInterface) =>
          event.text.includes(this.searchInput.nativeElement.value)
        );
        console.log("scenarioMatchSearch", scenarioMatchSearch);

        // If so, just return the scenario
        if (scenarioMatchSearch) {
          return scenario;
        }
      });
    }

    const data = scenarios.filter((scenario) => {
      // Check if event ID is greater than active "From" filter
      if (this.fromInput.nativeElement.value && !this.toInput.nativeElement.value) {
        if (scenario.id >= parseInt(this.fromInput.nativeElement.value, 10)) {
          return scenario;
        }
      }

      // Check if event ID is lower than active "From" filter
      if (!this.fromInput.nativeElement.value && this.toInput.nativeElement.value) {
        if (scenario.id <= parseInt(this.toInput.nativeElement.value, 10)) {
          return scenario;
        }
      }

      // Check if event ID is between active "From" and active "To" filter
      if (this.fromInput.nativeElement.value && this.toInput.nativeElement.value) {
        if (
          scenario.id >= parseInt(this.fromInput.nativeElement.value, 10) &&
          scenario.id <= parseInt(this.toInput.nativeElement.value, 10)
        ) {
          return scenario;
        }
      }

      // If no filter is provided, just return values
      if (!this.fromInput.nativeElement.value && !this.toInput.nativeElement.value) {
        return scenario;
      }
    });

    if (!this.sort.active || this.sort.direction === "") {
      if (returnData) {
        return data;
      }
      this.dataSource.data = data.slice(0, this.paginator.pageSize);
      return;
    }

    const sortedData = data.sort((a, b) => {
      const isAsc = this.sort.direction === "asc";
      switch (this.sort.active) {
        case "id":
          return this.compare(a.id, b.id, isAsc);
        case "items.length":
          return this.compare(+a.items.length, +b.items.length, isAsc);
        case "cumulatedChance":
          return this.compare(+a.cumulatedChance, +b.cumulatedChance, isAsc);
        case "cumulatedDamage":
          return this.compare(+a.cumulatedDamage, +b.cumulatedDamage, isAsc);
        case "cumulatedRisk":
          return this.compare(+a.cumulatedRisk, +b.cumulatedRisk, isAsc);
        default:
          return 0;
      }
    });

    if (returnData) {
      return sortedData;
    }

    this.paginator.pageIndex = 0; // Reset paginator
    this.table.dataSource = sortedData.slice(0, this.paginator.pageSize);
    this.table.renderRows();
  }

  /**
   * Handle pagination event and change dataSource page
   *
   * @since 1.0.0
   */
  paginateData(): void {
    console.log("paginateData()");
    const orderedData = this.sortData(true);
    const start = this.paginator.pageIndex * this.paginator.pageSize;
    const newData = orderedData.slice(start, start + this.paginator.pageSize);
    this.table.dataSource = newData;
    this.table.renderRows();
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Get all children (first-level children) of given node inside given nodes
   *
   * @since 1.0.0
   */
  getChildren(
    node: QuestionNodeInterface | AnswerNodeInterface,
    nodes: any[]
  ): (QuestionNodeInterface | AnswerNodeInterface)[] {
    const children = nodes.filter((obj) => obj.parentId === node.id);
    return children;
  }

  /**
   * Export the chart in PNG and download it
   *
   * @since 1.0.0
   */
  onSaveChart(chartName: string): void {
    const availableName = ["risk", "lineChart2", "lineChart3", "lineChart4", "lineChart5"];
    if (!availableName.includes(chartName)) {
      return;
    }

    if (chartName === "risk") {
      const urlBase64 = this.riskChart.nativeElement.toDataURL();
      const element = document.createElement("a");
      element.setAttribute("download", "riskChart.png");
      element.setAttribute("href", `${urlBase64}`);
      document.body.append(element);
      element.click();
    }

    if (chartName === "lineChart2") {
      const urlBase64 = this.lineChart2.nativeElement.toDataURL();
      const element = document.createElement("a");
      element.setAttribute("download", "riskChart.png");
      element.setAttribute("href", `${urlBase64}`);
      document.body.append(element);
      element.click();
    }

    if (chartName === "lineChart3") {
      const urlBase64 = this.lineChart3.nativeElement.toDataURL();
      const element = document.createElement("a");
      element.setAttribute("download", "riskChart.png");
      element.setAttribute("href", `${urlBase64}`);
      document.body.append(element);
      element.click();
    }

    if (chartName === "lineChart4") {
      const urlBase64 = this.lineChart4.nativeElement.toDataURL();
      const element = document.createElement("a");
      element.setAttribute("download", "riskChart.png");
      element.setAttribute("href", `${urlBase64}`);
      document.body.append(element);
      element.click();
    }

    if (chartName === "lineChart5") {
      const urlBase64 = this.lineChart5.nativeElement.toDataURL();
      const element = document.createElement("a");
      element.setAttribute("download", "riskChart.png");
      element.setAttribute("href", `${urlBase64}`);
      document.body.append(element);
      element.click();
    }
  }

  /**
   * Handle click on "Make bigger" chart button making
   * the chart full page
   *
   * @since 1.0.0
   */
  onMakeBigger(chartName: string): void {
    if (chartName === "risk") {
      this.renderer2.addClass(this.riskChartContainer.nativeElement, "full-page");
    }
    if (chartName === "lineChart2") {
      this.renderer2.addClass(this.lineChart2Container.nativeElement, "full-page");
    }
    if (chartName === "lineChart3") {
      this.renderer2.addClass(this.lineChart3Container.nativeElement, "full-page");
    }
    if (chartName === "lineChart4") {
      this.renderer2.addClass(this.lineChart4Container.nativeElement, "full-page");
    }
    if (chartName === "lineChart5") {
      this.renderer2.addClass(this.lineChart5Container.nativeElement, "full-page");
    }
  }

  /**
   * Handle click on "Make smaller" button making chart
   * smaller (set his original size and remove "full-page"
   *
   * @since 1.0.0
   */
  onMakeSmaller(chartName: string): void {
    if (chartName === "risk") {
      this.renderer2.removeClass(this.riskChartContainer.nativeElement, "full-page");
    }
    if (chartName === "lineChart2") {
      this.renderer2.removeClass(this.lineChart2Container.nativeElement, "full-page");
    }
    if (chartName === "lineChart3") {
      this.renderer2.removeClass(this.lineChart3Container.nativeElement, "full-page");
    }
    if (chartName === "lineChart4") {
      this.renderer2.removeClass(this.lineChart4Container.nativeElement, "full-page");
    }
    if (chartName === "lineChart5") {
      this.renderer2.removeClass(this.lineChart5Container.nativeElement, "full-page");
    }
  }

  /**
   * Handle click on scenarios (events) table's row
   * and trigger the appropriate dialog opening to show
   * the child event.items list
   *
   * @since 1.0.0
   */
  onRowOpen(index?: number, id?: number): void {
    let row;
    if ((index || index === 0) && id === null) {
      row = this.dataSource.data[index];
    }
    if ((id || id === 0) && index === null) {
      row = this.analyzes[0].scenarios.find((obj) => obj.id === id);
    }
    if (!row) {
      return;
    }
    this.dialog.open(EventDetailDialogComponent, {
      data: {
        row,
        data: this.analyzes[0].data,
      },
      width: "100%",
    });
  }

  /**
   * Hancle click event on charts
   * and open the popup of related scenario
   *
   * @since 1.0.0
   */
  onChartClick(event: any, chartName: string): void {
    // console.log('event', event);
    // console.log('chartName', chartName);
    if (
      event.active &&
      event.active[0] &&
      event.active[0]._chart &&
      event.active[0]._chart.config &&
      event.active[0]._chart.config.data &&
      event.active[0]._chart.config.data.datasets &&
      event.active[0]._chart.config.data.datasets[event.active[0]._datasetIndex] &&
      event.active[0]._chart.config.data.datasets[event.active[0]._datasetIndex].data &&
      event.active[0]._chart.config.data.datasets[event.active[0]._datasetIndex].data[event.active[0]._index] &&
      event.active[0]._chart.config.data.datasets[event.active[0]._datasetIndex].data[event.active[0]._index].id
    ) {
      const scenarioID =
        event.active[0]._chart.config.data.datasets[event.active[0]._datasetIndex].data[event.active[0]._index].id;
      // console.log('scenarioID', scenarioID);
      this.onRowOpen(null, scenarioID);
    }
  }

  /**
   * Handle change value event on the effect select filter in the datatable toolbar
   * and reload the datatable showing only the scenarios with ALL events with
   * an effect like the one selected
   *
   * @since 1.0.0
   */
  onEffectSelectChange(event): void {
    // console.log('event', event);
    // console.log('val', this.effectSelect.value);
    this.sortData();
  }

  /**
   * Reset all current analyzed data and make page looks like if user
   * has just landed on it
   *
   * @since 1.0.0
   */
  onResetAll(): void {
    const title = "Are you sure?";
    const message = "If you continue all current analysis will be deleted. Click ok to reset all analysis";
    this.subscriptions.push(
      this.confirmDialogService.confirm(title, message).subscribe((res) => {
        if (res) {
          // Reset analysis
          this.analyzesIndex = 0;
          this.analyzes = [];
          this.tempScenarios = [];
          this.tempScenariosId = this.defaultTempScenariosId;

          // Reset events's datatable list
          this.dataSource.disconnect();

          // Reset charts' series
          this.analysisComparisonChartData = [];
          this.analysisComparisonChart2Data = [];
          this.analysisComparisonChart3Data = [];
          this.riskChartData = [];
          this.lineChart2Data = [];
          this.lineChart2Data = [];
          this.lineChart3Data = [];
          this.lineChart3Data = [];
          this.lineChart4Data = [];
          this.lineChart4Data = [];
          this.lineChart5Data = [];

          // Reset charts' active status
          this.analysisComparisonChartActive = false;
          this.analysisComparisonChart2Active = false;
          this.analysisComparisonChart3Active = false;
          this.riskChartActive = false;
          this.lineChart2Active = false;
          this.lineChart3Active = false;
          this.lineChart4Active = false;
          this.lineChart5Active = false;
        }
      })
    );
  }

  /**
   * Build a exportation file from the analyzes
   * and force the file download
   *
   * @since 1.0.0
   */
  onDownloadAnalyzes(): void {
    // Prevent empty export
    if (!this.analyzes.length) {
      const title = "Warning!";
      const text = "Unable to export the analyzes list: no data available";
      this.toastrService.warning(text, title);
      return;
    }

    // Convert the chartTree to a JSON and download it
    const content = this.analyzes.slice();
    content.forEach((analysis) => {
      delete analysis.data2;
    });
    const jsonContent = JSON.stringify(content);

    // BEGIN - Get the appropriate file name
    const today = moment(new Date()).format("YYYY-MM-DD");
    let fileName = `${today} - ANALYZES - SimuRisk`;
    fileName += ".SIM";
    // END - Get the appropriate file name

    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      const blob = new Blob([jsonContent], { type: "text/plain;charset=utf-8;" });
      navigator.msSaveBlob(blob, fileName);
      return;
    }

    const element = document.createElement("a");
    element.setAttribute("href", `data:text/json;charset=UTF-8,${encodeURIComponent(jsonContent)}`);
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);

    return;
  }

  /**
   * Round given number to the closes greater interger
   *
   * @since 1.0.0
   */
  round(num: number): number {
    return Math.round(num);
  }

  onResetZoom(chart: string): void {
    switch (chart) {
      case "lineChart2":
        ((this.charts as any)._results[0].chart as any)?.resetZoom();
        break;
      case "lineChart3":
        ((this.charts as any)._results[1].chart as any)?.resetZoom();
        break;
      case "lineChart4":
        ((this.charts as any)._results[2].chart as any)?.resetZoom();
        break;
      case "lineChart5":
        ((this.charts as any)._results[3].chart as any)?.resetZoom();
        break;
      case "riskChart":
        ((this.charts as any)._results[4].chart as any)?.resetZoom();
        break;
      default:
        break;
    }
  }
}
