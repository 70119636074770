<div class="node answer-node" #node id="{{ id }}" [attr.data-parent-id]="parentNode.id" [attr.data-linkTo-id]="linkTo"
  [ngClass]="{'is-invalid': isInvalid}" [ngStyle]="{'left.px': x, 'top.px': y}" cdkDrag
  (cdkDragEnded)="onDragEnded($event)" (CdkDragMove)="onDragMove($event)">
  <div class="options">
    <button class="remove mat-raised-button mat-button-base mat-warn fal fa-trash" title="Click to remove element"
      (click)="onRemove()"></button>

    <button class="add mat-raised-button mat-button-base mat-warn fal fa-plus" title="Click to add child question"
      (click)="onAddChild()" [disabled]="linkTo"></button>

    <button class="link mat-raised-button mat-button-base mat-warn fal fa-arrows-alt-h"
      title="Click to link to a question" (click)="onCreateLink()"></button>
    <!-- // *ngIf="parentNode.answersNumber > 1"  -->
    <button class="link mat-raised-button mat-button-base mat-warn fal fa-sync"
      title="Click to recalculate siblings' chances" (click)="onRecalculateSiblingsChanges()"></button>

    <button class="move cdk-drag-handle mat-raised-button mat-button-base mat-primary fal fa-arrows"
      title="Click to move" cdkDragHandle></button>
    <!-- <button mat-raised-button color="warn" class="remove" title="Click to remove element" (click)="onRemove()">
      <mat-icon fontSet="fal" fontIcon="fa-trash"></mat-icon>
    </button>
    <button mat-raised-button color="primary" class="add" title="Click to add child question" (click)="onAddChild()">
      <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
    </button>
    <button mat-raised-button color="primary" class="link" title="Click to create a link">
      <mat-icon fontSet="fal" fontIcon="fa-arrows-alt-h"></mat-icon>
    </button>
    <button mat-raised-button color="primary" class="move" title="Click to move" cdkDragHandle>
      <mat-icon fontSet="fal" fontIcon="fa-arrows"></mat-icon>
    </button> -->
  </div> <!-- /.options -->
  <div class="input-group">
    <input type="text" [(ngModel)]="text" class="form-control node-value" placeholder="Answer *"
      (change)="onInputChange()" title="Answer text">

    <select class="form-control node-effect" [(ngModel)]="effect" (change)="onInputChange()"
      title="Effect (Positive or Negative)">
      <option value="P">P</option>
      <option value="N">N</option>
    </select>

    <input type="number" [(ngModel)]="chance" class="form-control node-chance" min="0" max="1" step="0.01"
      placeholder="%" (focus)="onChanceFocus($event)" (change)="onInputChange()" title="Likelihood(%): {{chance}}">

    <input type="number" [(ngModel)]="damage" class="form-control node-damage" placeholder="$"
      (focus)="onDamageFocus($event)" (change)="onInputChange()" title="Damage($): {{damage}}">
  </div> <!-- /.input-group -->
</div> <!-- /.node -->
