<main>
  <section class="hero-section">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-6 py-4 py-md-0">
          <h1>Login to SimuRisk</h1>
          <span class="h1 title">A new era of risk analysis</span>
          <h2 class="lead">
            Not registered yet? <br>
            Feel free to contact our support team at <a href="mailto:{{ ownerEmail }}">{{ownerEmail}}</a>
          </h2>
          <a mat-raised-button class="py-2 px-4 mt-5" routerLink="/auth/recover-password">
            Recover password
            <mat-icon fontSet="fal" fontIcon="fa-chevron-right"></mat-icon>
          </a>
        </div> <!-- /.col -->

        <div class="col-12 col-md-5 py-4 py-md-0">
          <div class="form-wrapper mat-elevation-z2">
            <span class="form-title">
              Login
            </span>
            <span class="form-helper">
              Please, login to SimuRisk
            </span>
            <form class="form" [formGroup]="form" (submit)="onFormSubmit()">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" placeholder="Insert your email">
                <mat-error *ngFor="let key of f.email.errors | objToKeys">
                  {{ helperService.getErrorMessage(key, f.email.errors[key]) }}</mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Password</mat-label>
                <input type="password" matInput formControlName="password" placeholder="Insert your password">
                <mat-error *ngFor="let key of f.password.errors | objToKeys">
                  {{ helperService.getErrorMessage(key, f.password.errors[key]) }}</mat-error>
              </mat-form-field>

              <!-- <button role="submit" mat-raised-button color="primary" class="mt-3">
                Login
                <mat-icon fontSet="fal" fontIcon="fa-sign-in"></mat-icon>
              </button> -->
              <mat-spinner-button role="submit" [options]="formSubmitBtnOptions"
                    class="btn-elevate"></mat-spinner-button>
            </form>
          </div> <!-- /.form-wrapper -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section> <!-- /.banner-section -->
</main>
