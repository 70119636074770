<main>
  <section class="hero-section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-5">
          <h1>Risk analysis tool</h1>
          <span class="h1 title">Welcome on SimuRisk</span>
          <h2 class="lead">The most advanced risk analysis and decision aid tool on the market</h2>
          <p class="lead">
            Allow decisions through a <strong>documented and justified process</strong> which gives you black-on-white
            reasons
          </p>
          <button mat-raised-button class="py-2 px-4 mt-2 mt-md-5 mb-4 mb-md-0" color="primary" routerLink="auth/login"
            *ngIf="!loggedUser">
            Login
            <mat-icon fontSet="fal" fontIcon="fa-sign-in"></mat-icon>
          </button>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section> <!-- /.banner-section -->

  <div class="focus-section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <span class="h3 title">Assessment & Analysis</span>
          <p class="subtitle">Know your company</p>
          <small>Create the logic model</small>
        </div> <!-- /.col -->
        <div class="col-12 col-md-3">
          <span class="h3 title">Evaluation</span>
          <p class="subtitle">Estimate and understand</p>
          <small>The solutions's impact before implement</small>
        </div> <!-- /.col -->
        <div class="col-12 col-md-3">
          <span class="h3 title">Treatment & Response</span>
          <p class="subtitle">Act competenty</p>
          <small>Apply in the real world the best solution</small>
        </div> <!-- /.col -->
        <div class="col-12 col-md-3">
          <span class="h3 title">Risk Monitor</span>
          <p class="subtitle">Loop you effort</p>
          <small>Allow periodic measurement control and continue monitor</small>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.focus-section -->

  <section class="section pt-initial">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-7">
          <img src="/assets/img/risk-line.gif" alt="Risk analysis tool" class="img-fluid mb-4 mb-md-0">
          <!-- rounded mat-elevation-z3 -->
        </div> <!-- /.col -->
        <div class="col-12 col-md-5">
          <h3 class="title">We pride ourselves on making the best tool on the market</h3>
          <p>
            We did our best and spent tons of hours in fine-tuning our algorithms to let you handle
            hundreds of thousands of simulations in less than a coffee time.
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-5 order-1 order-md-0">
          <h3 class="title">We made everything by hand with the best technologies</h3>
          <p>
            We used some of the best-known math approach like the <strong>Bolean Algebra</strong> and
            <strong>Bayesian mathematics</strong> mixed with the top-level digital technologies nowdays available to
            build something extraordinary.
          </p>
        </div> <!-- /.col -->
        <div class="col-12 col-md-7 order-0 order-md-1 d-flex justify-content-end">
          <img src="/assets/img/area-chart.jpg" alt="Risk management tool" class="img-fluid mb-4 mb-md-0">
          <!--  rounded mat-elevation-z3 -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>

  <section class="section">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-7 text-center">
          <h3 class="title">Explore SimuRisk features</h3>
          <p>
            For everybody, small or big organization
          </p>
          <p>
            The following are just the tip of the iceberg, more feature are coming soon.
          </p>
        </div> <!-- /.col -->
      </div> <!-- /.row -->
      <div class="row mt-5">
        <div class="col-12 col-md-4">
          <div class="feature-box mat-elevation-z3">
            <div class="feature-content">
              <span class="feature-title">Privacy</span>
              <span class="feature-description">We value your data. That's why we dont keep it in our servers</span>
            </div> <!-- /.feature-content -->
          </div> <!-- /.feature-box-->
        </div> <!-- /.col -->

        <div class="col-12 col-md-4">
          <div class="feature-box mat-elevation-z3">
            <div class="feature-content">
              <span class="feature-title">Easy to use</span>
              <span class="feature-description">Our tool is based on a simple yet effective drag-and-drop
                interface</span>
            </div> <!-- /.feature-content -->
          </div> <!-- /.feature-box-->
        </div> <!-- /.col -->

        <div class="col-12 col-md-4">
          <div class="feature-box mat-elevation-z3">
            <div class="feature-content">
              <span class="feature-title">Precise</span>
              <span class="feature-description">Our engineerings did their best to tighten the screws</span>
            </div> <!-- /.feature-content -->
          </div> <!-- /.feature-box-->
        </div> <!-- /.col -->

        <div class="col-12 col-md-4">
          <div class="feature-box mat-elevation-z3">
            <div class="feature-content">
              <span class="feature-title">Updated</span>
              <span class="feature-description">Frequent real-scenario tests are used to keep our algo updated</span>
            </div> <!-- /.feature-content -->
          </div> <!-- /.feature-box-->
        </div> <!-- /.col -->

        <div class="col-12 col-md-4">
          <div class="feature-box mat-elevation-z3">
            <div class="feature-content">
              <span class="feature-title">Cool!</span>
              <span class="feature-description">C'mon, let's be serious. Our tool looks awesome</span>
            </div> <!-- /.feature-content -->
          </div> <!-- /.feature-box-->
        </div> <!-- /.col -->

        <div class="col-12 col-md-4">
          <div class="feature-box mat-elevation-z3">
            <div class="feature-content">
              <span class="feature-title">Reliable</span>
              <span class="feature-description">Our tool is based on the highest level technologies on the market</span>
            </div> <!-- /.feature-content -->
          </div> <!-- /.feature-box-->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section>
</main>
