// Core packages
import { Injectable, Renderer2, RendererFactory2, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';

// Third party packages

// Custom packages
import { CONFIG } from 'app/config/config';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;

  /**
   * Class constctor
   */
  constructor(rendererFactory: RendererFactory2, private titleService: Title) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Set given title as page title
   *
   * @since 1.0.0
   */
  setPageTitle(title: string, removePostfix: boolean = false): void {
    const titlePostfix = CONFIG.owner.niceName;
    let pageTitle;

    if (removePostfix) {
      pageTitle = title;
    } else {
      pageTitle = [title.trim(), titlePostfix].join(' | ');
    }

    this.titleService.setTitle(pageTitle);
  }

  /**
   * Set given class as body class
   *
   * @since 1.0.0
   */
  setClassToBody(className: string): void {
    this.renderer.addClass(document.body, className);
  }

  /**
   * Remove given class from body tag
   *
   * @since 1.0.0
   */
  removeClassToBody(className: string): void {
    this.renderer.removeClass(document.body, className);
  }

  /**
   * Set given class to given element
   *
   * @since 1.0.0
   */
  setClassToElement(element: ElementRef, className: string): void {
    this.renderer.addClass(element, className);
  }

  /**
   * Remove given class to given element
   *
   * @since 1.0.0
   */
  removeClassToElement(element: ElementRef, className: string): void {
    this.renderer.removeClass(element, className);
  }
}
