// Core packages
import { Component, OnInit } from '@angular/core';

// Third party packages

// Custom packages
import { CONFIG } from 'app/config/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  owner = CONFIG.owner;

  constructor() {}

  /**
   * Init component
   */
  ngOnInit(): void {}
}
