<main>
  <section class="hero-section">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md-6 py-4 py-md-0">
          <h1>Recover you password on SimuRisk</h1>
          <span class="h1 title">Recover password</span>
          <h2 class="lead">Lost credentials? No worries, just recover it now</h2>
          <button mat-raised-button class="py-2 px-4 mt-5" routerLink="/auth/login">
            Login
            <mat-icon fontSet="fal" fontIcon="fa-sign-in"></mat-icon>
          </button>
        </div> <!-- /.col -->

        <div class="col-12 col-md-5 py-4 py-md-0">
          <div class="form-wrapper mat-elevation-z2">
            <span class="form-title">
              Recover your password now
            </span>
            <span class="form-helper">
              Just fill this form. You will receive an email with all the needed informations.
            </span>
            <form class="form" [formGroup]="form" (submit)="onFormSubmit()">
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email" placeholder="Insert your email">
                <mat-error *ngFor="let key of f.email.errors | objToKeys">
                  {{ helperService.getErrorMessage(key, f.email.errors[key]) }}</mat-error>
              </mat-form-field>

              <mat-spinner-button role="submit" [options]="formSubmitBtnOptions" class="btn-elevate">
              </mat-spinner-button>
            </form>
          </div> <!-- /.form-wrapper -->
        </div> <!-- /.col -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </section> <!-- /.banner-section -->
</main>
